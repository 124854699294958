<template>
  <div>
    <v-list v-if="outtrips.length === 0">
      <v-alert type="info" outlined>
        Cet utilisateur n'a aucun Outtrip
      </v-alert>
    </v-list>
    <OuttripList :outtrips="outtrips" v-else/>
  </div>
</template>

<script>
import OuttripList from "@/components/Outtrip/OuttripList";

export default {
  name: "OuttripeurOuttripList",
  components: {OuttripList},
  props: {
    outtrips: {
      required: true
    }
  }
}
</script>

<style scoped lang="scss">

</style>
