<template>
  <div class="v-input__control">
    <file-pond ref="pond" name="gallery" class="filepond" :label-idle="labelIdle" accepted-file-types="image/jpeg, image/png" :allow-multiple="true" max-files="5" :server="server" v-on:init="handleInit"/>
    <div class="v-text-field__details">
      <div class="v-messages theme--light error--text" role="alert">
        <div class="v-messages__wrapper">
          <div class="v-messages__message" v-for="error in errorMessages" :key="error" v-html="error"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueFilePond from "vue-filepond"
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import {mapState} from "vuex";

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
)

export default {
  name: "GalleryWidget",
  components: {
    FilePond
  },
  data() {
    return {
      currentFiles: []
    }
  },
  computed: {
    ...mapState("auth", ['tokens']),
    headers() {
      return {
        Authorization: 'Bearer ' + this.tokens.token,
      };
    },
    server() {
      return {
        url: process.env.VUE_APP_API_URL + '/asset',
        process: {
          headers: this.headers,
          onload: (response) => {
            this.currentFiles.push(response)
            this.$emit('input', this.currentFiles)
            return response
          },
          ondata: (formData) => {
            formData.append('asset[folderId]', this.folderId)
            return formData
          }
        },
        revert: {
          headers: this.headers,
          onload: (response) => {
            this.currentFiles.splice(this.currentFiles.indexOf(response), 1)
            this.$emit('input', this.currentFiles)
          }
        },
        load: {
          headers: this.headers,
        },
        // eslint-disable-next-line no-unused-vars
        remove: (source, load, error) => {

          let currentFiles = this.currentFiles

          this.$axios.delete('/asset', {
            data: source,
            headers: this.headers,
          })
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            currentFiles.splice(currentFiles.indexOf(source), 1)
            this.$emit('input', currentFiles)
            load()
          })
          .catch(error => error('Oups, une erreur est survenue'))
        }

      }
    }
  },
  methods: {
    handleInit() {
      if (this.value) {
        this.value.forEach((assetId) => this.$refs.pond.addFile(assetId, {type: 'local'}))
      }
    }
  },
  mounted() {
    this.currentFiles = this.value
  },
  props: {
    value: {
      required: false
    },
    folderId: {
      type: Number,
      required: true
    },
    labelIdle: {
      default: "Ajoute des photos (entre 1 et 5)",
      required: false
    },
    errorMessages: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
