<template>
  <div>
    <v-list three-line>
      <template v-for="(article, index) in articles">

        <v-divider v-if="index !== 0" :key="index" inset/>

        <v-list-item :key="article.uri">
          <v-list-item-avatar>
            <OuttriperPhoto diameter="37" :outtriper="article.author"/>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title @click="redirect(article.uri)" style="cursor: pointer" class="text-wrap">{{
                article.title
              }}
            </v-list-item-title>
            <v-list-item-subtitle><span class="primary--text">#{{ article.category[0].title }}</span>  <strong>{{ article.author.username }}</strong>

            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text>{{ article.postDate | date }}</v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import dayjs from "dayjs";
import OuttriperPhoto from "@/components/Outtriper/OuttriperPhoto";

export default {
  name: "OuttripeurArticleList",
  components: {OuttriperPhoto},
  props: {
    articles: {
      required: true
    }
  },
  methods: {
    redirect(route) {
      window.open(`${process.env.VUE_APP_FRONTEND_URL}/${route}`, '_blank');
    }
  },
  filters: {
    firstLetter(name) {
      return name.charAt(0).toUpperCase()
    },
    date(date) {
      return dayjs(date).format('DD/MM/YYYY')
    }
  },
}
</script>

<style scoped>

</style>
