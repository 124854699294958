<template>
  <v-container fluid>
    <AdBanner/>
    <h1>Outtrip Premium</h1>
    <v-card flat tile>
      <v-window v-model="onboarding">
        <v-window-item :value="0">
          <v-card height="200">
            <v-row class="fill-height flex-column" align="center" justify="center">
              <v-icon color="primary" large>mdi-account-group</v-icon>
              <span class="text-h6 font-weight-bold text-center font-weight-bold d-block">Communique avec tous les membres de la communauté</span>
              <span class="text-subtitle-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</span>
            </v-row>
          </v-card>
        </v-window-item>
        <v-window-item :value="1">
          <v-card height="200">
            <v-row class="fill-height flex-column" align="center" justify="center">
              <v-icon color="primary" large>mdi-bell-ring</v-icon>
              <span class="text-h6 font-weight-bold text-center d-block">Soit averti des nouveauté en priorité</span>
              <span class="text-subtitle-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</span>
            </v-row>
          </v-card>
        </v-window-item>
        <v-window-item :value="2">
          <v-card height="200">
            <v-row class="fill-height flex-column" align="center" justify="center">
              <v-icon color="primary" large>mdi-head-check-outline</v-icon>
              <span class="text-h6 font-weight-bold text-center d-block">Navigue sans publicité</span>
              <span class="text-subtitle-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</span>
            </v-row>
          </v-card>
        </v-window-item>
        <v-window-item :value="3">
          <v-card height="200">
            <v-row class="fill-height flex-column" align="center" justify="center">
              <v-icon color="primary" large>mdi-account-check</v-icon>
              <span class="text-h6 font-weight-bold text-center d-block">Passe ton profil en vérifié</span>
              <span class="text-subtitle-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</span>
            </v-row>
          </v-card>
        </v-window-item>
      </v-window>

      <v-card-actions class="justify-space-between">
        <v-btn text @click="prev">
          <v-icon color="secondary">mdi-chevron-left</v-icon>
        </v-btn>
        <v-item-group v-model="onboarding" class="text-center" mandatory>
          <v-item v-for="n in length" :key="`btn-${n}`" v-slot="{ active, toggle }">
            <v-btn :input-value="active" icon @click="toggle">
              <v-icon color="primary">mdi-record</v-icon>
            </v-btn>
          </v-item>
        </v-item-group>
        <v-btn text @click="next">
          <v-icon color="secondary">mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-row>
      <v-col>
        <v-card>
          <v-sheet outlined elevation="2" class="text-body-1">
            <v-sheet class="pa-4 text-center">
              <div class="text-h6 font-weight-bold text-uppercase mb-6">Annuel</div>
              <div class="d-flex justify-center ml-n3"><div class="font-weight-regular text-h4 mr-1" style="margin-top: 10px;">&euro;</div><div class="text-h1 font-weight-black text-number">20</div></div>
              <div class="grey--text justify-end overline">/ an</div>
            </v-sheet>
            <v-divider></v-divider>
            <v-sheet class="pa-4 v-sheet grey lighten-5">
              <v-btn block outlined size="x-large" :loading="loading" @click="onClick('annual')">Souscrire</v-btn>
            </v-sheet>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-sheet outlined elevation="2" class="text-body-1">
            <v-sheet class="pa-4 text-center">
              <div class="text-h6 font-weight-bold text-uppercase mb-6">Mensuel</div>
              <div class="d-flex justify-center ml-n3"><div class="font-weight-regular text-h4 mr-1" style="margin-top: 10px;">&euro;</div><div class="text-h1 font-weight-black text-number">3</div></div>
              <div class="grey--text justify-end overline">/ mois</div>
            </v-sheet>
            <v-divider></v-divider>
            <v-sheet class="pa-4 v-sheet grey lighten-5">
              <v-btn block outlined color="primary" size="x-large" :loading="loading" @click="onClick('mensual')">Souscrire</v-btn>
            </v-sheet>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
    <stripe-checkout ref="checkoutRef" mode="subscription" :pk="publishableKey" :session-id="sessionId" @loading="v => loading = v"/>
  </v-container>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import AdBanner from "@/views/AdBanner";

export default {
  name: "Premium",
  components: {
    StripeCheckout,
    AdBanner
  },
  data: () => ({
    length: 4,
    onboarding: 0,
    arguments: [
      {
        icon: 'mdi-users',
        header: 'Communique avec tous les membres de la communauté',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna'
      },
              {
        icon: 'mdi-users',
        header: 'Soit averti des nouveauté en priorité',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna'
      },
              {
        icon: 'mdi-users',
        header: 'Navigue sans publicité',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna'
      },
              {
        icon: 'mdi-users',
        header: 'Passe ton profil en vérifié',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna'
      },
    ],
    loading: false,
    publishableKey: process.env.VUE_APP_STRIPE_API_SECRET_KEY,
    sessionId: null
  }),

  methods: {
    next () {
      this.onboarding = this.onboarding + 1 === this.length
          ? 0
          : this.onboarding + 1
    },
    prev () {
      this.onboarding = this.onboarding - 1 < 0
          ? this.length - 1
          : this.onboarding - 1
    },
    onClick(price) {
      if (this.loading === true) {
        return;
      }

      this.loading = true;

      this.$axios.post("/subscription/checkout/session", {
        price: price,
      }).then(responses => {
        this.sessionId = responses.data.id;
        this.$refs.checkoutRef.redirectToCheckout();
      })


    }
  },
}
</script>
