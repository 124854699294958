<template>
  <div class="pa-6 text-center">
    <h1 class="my-3">S'inscrire</h1>
    <ul>
      <li v-for="error in errors" :key="error" v-html="error"></li>
    </ul>
    <v-form @submit.prevent="onSubmit">
      <v-text-field v-model="v$.form.username.$model" type="text" outlined rounded label="Pseudo" required :error-messages="usernameErrors"></v-text-field>
      <v-text-field v-model="v$.form.email.$model" type="text" outlined rounded label="Email" required :error-messages="emailErros"></v-text-field>
      <v-text-field v-model="form.password" :type="passwordShow ? 'text' : 'password'" :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'" outlined rounded label="Mot de passe" @click:append="passwordShow = !passwordShow" required></v-text-field>
      <v-text-field v-model="v$.form.confirmPassword.$model" :type="confirmPasswordShow ? 'text' : 'password'" :append-icon="confirmPasswordShow ? 'mdi-eye' : 'mdi-eye-off'" outlined rounded label="Confirme ton mot de passe" @click:append="confirmPasswordShow = !confirmPasswordShow" required :error-messages="confirmPasswordErrors"></v-text-field>
      <v-checkbox v-model="form.subscribeToNewsletter" required>
        <template v-slot:label>Je m'inscris à la newsletter de Outtrip</template>
      </v-checkbox>
      <v-checkbox v-model="v$.form.agreeTerms.$model" required :error-messages="agreeTermsErrors">
        <template v-slot:label>J'accepte de recevoir vos e-mails et confirme avoir pris connaissance de votre politique de confidentialité et vos conditions de vente et d'utilisation.</template>
      </v-checkbox>

      <v-btn type="submit" block color="primary" rounded :loading="loading" :disabled="loading">s'inscrire</v-btn>
    </v-form>
    <p class="mt-10">
      <router-link to="/login">Se connecter</router-link>
    </p>
    <v-snackbar v-model="snackbar" absolute bottom left color="error">{{ message }}</v-snackbar>
  </div>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import useVuelidate from "@vuelidate/core";
export default {
  name: "Register",
  data() {
    return {
      form: {
        username: null,
        password: null,
        confirmPassword: null,
        email: null,
        subscribeToNewsletter: null,
        agreeTerms: false,
      },
      passwordShow: false,
      confirmPasswordShow: false,
      snackbar: false,
      loading: false,
      message: '',
      errors: []
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations: () => ({
    form: {
      username:{
        required,
        regex: function(value) {
          const regex = new RegExp(/^[A-Za-z0-9\-_.]+$/);
          return regex.test(value)
        }
      },
      agreeTerms: {
        isTrue: function(value) {
          return value
        },
      },
      confirmPassword: {
        required,
        repeat: function(value) {
          return value === this.form.password
        },
      },
      email: {email}
    }
  }),
  methods: {
    redirect() {
      this.$router.push({name: 'Profil'})
    },
    onSubmit: async function () {
      if (!this.loading) {
        this.loading = true;

        const isFormCorrect = await this.v$.$validate()

        if (!isFormCorrect) {
          this.loading = false;
          return;
        }

        var self = this;

        const FormData = require('form-data');

        const form = new FormData();
        form.append('inscription_form[outtripUsername]', this.form.username);
        form.append('inscription_form[email]', this.form.email);
        form.append('inscription_form[plainPassword][first]', this.form.password);
        form.append('inscription_form[plainPassword][second]', this.form.confirmPassword);
        form.append('inscription_form[subscribeToNewsletter]', this.form.subscribeToNewsletter);
        form.append('inscription_form[agreeTerms]', this.form.agreeTerms);

        this.$axios.post('/user/register', form, { headers: {'content-type': 'application/x-www-form-urlencoded'} })
          .then(() => {
            console.log('then1');
            this.$router.push({name: 'RegisterSuccess'})
          })
          .catch(function (error) {
            self.errors = error.response.data.form.errors;
            self.message = 'Le formulaire comporte des erreurs.';
            self.snackbar = true;
          })
          .then(function () {
            self.loading = false;
          });
      }
    }
  },
  computed: {
    usernameErrors() {
      const errors = []
      if (!this.v$.form.username.$dirty) return errors
      this.v$.form.username.required.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.form.username.regex.$invalid && errors.push('Le pseudo peut contenir des lettres non accentuées en majuscule et minuscule, les chiffres, -, _ ou .')
      return errors
    },
    agreeTermsErrors() {
      const errors = []
      if (!this.v$.form.agreeTerms.$dirty) return errors
      this.v$.form.agreeTerms.isTrue.$invalid && errors.push('Vous devez accpeter les conditions générales d\'utilisation.')
      return errors
    },
    confirmPasswordErrors() {
      const errors = []
      if (!this.v$.form.confirmPassword.$dirty) return errors
      this.v$.form.confirmPassword.required.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.form.confirmPassword.repeat.$invalid && errors.push('Le mots de passes sont différents.')
      return errors
    },
    emailErros() {
      const errors = []
      if (!this.v$.form.confirmPassword.$dirty) return errors
      this.v$.form.email.email.$invalid && errors.push('Ce champs est incorrect.')
      return errors
    },
  }
}
</script>

<style scoped>

</style>
