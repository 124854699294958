<template>
  <v-dialog v-model="isOpen" max-width="500">
    <v-card>
      <v-card-title class="text-h5">
        Devient premium
      </v-card-title>

      <v-card-text>
        Vous devez être premium pour avoir cette fonctionnalité
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" text @click="isOpen = false">
          Annuler
        </v-btn>

        <v-btn color="secondary" dark link :to="{name: 'Premium'}" @click="isOpen = false">
          <v-icon left color="premium">mdi-leaf-circle-outline</v-icon>
          <span class="premium--text">Devenir premium</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PremiumModal",
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    open() {
      this.isOpen = true
    },
    close() {
      this.isOpen = false
    }
  },
}
</script>

<style scoped>

</style>
