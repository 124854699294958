<template>
  <div>
    <v-list three-line>
      <template v-for="(event, index) in events">

        <v-divider v-if="index !== 0" :key="index" inset/>

        <v-list-item :key="event.slug">
          <v-list-item-avatar rounded>
            <v-img :src="event.media"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title @click="redirectEvent(event.slug)" class="text-wrap" style="cursor: pointer" v-html="event.title"></v-list-item-title>
            <v-list-item-subtitle>{{event.startAt.date | date}}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="user && event.created_by_id == user.apiId">
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group color="primary">
                  <v-list-item @click="goToEdit(event.craftCmsId)">
                    <v-list-item-icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Editer</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item link :to="{name: 'MyActivitiesParticipants', params: {id: event.craftCmsId}}">
                    <v-list-item-icon>
                      <v-icon>mdi-account-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Participants</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click.stop="goToDelete(event.craftCmsId)">
                    <v-list-item-icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Supprimer</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>

    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          Supprimer l'évènement ?
        </v-card-title>

        <v-card-text>
          Etes vous vraiment sur de vouloir supprimer l'évènement ? Cette action est irréversible !
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="dialog = false">
            Annuler
          </v-btn>

          <v-btn color="error" @click="dialog = false">
            <v-icon>mdi-delete</v-icon>
            Supprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {mapState} from "vuex";

export default {
  name: "EventList",
  computed: {
    ...mapState("auth", ['user']),
  },
  props: {
    events: {
      required: true
    }
  },
  data(){
    return{
      dialog: false,
      selectedEvent: null
    }
  },
  methods: {
    redirectEvent(slug) {
      this.$router.push({name: 'Event', params: {slug: slug}})
    },
    goToEdit(id) {
      this.$router.push({name: 'EventEdit', params: {id: id}})
    },
    goToDelete(id) {
      this.dialog = true
      this.selectedEvent = id;
    }
  },
  filters: {
    date(date) {
      return dayjs(date).format('DD/MM/YYYY')
    }
  }
}
</script>

<style scoped>

</style>
