<template>
  <div class="py-2 text-center">
    <div class="d-flex justify-center align-center">
      <router-link :to="'/group/'+group.id">
        <cld-image class="ml-2" :public-id="group.logo[0].url" loading="lazy" type="fetch">
          <cld-transformation width="200" quality="auto:eco" crop="pad"></cld-transformation>
        </cld-image>
      </router-link>
    </div>
    <a :href="sourceUrl" target="_blank">Voir la fiche originale</a>
  </div>
</template>

<script>
export default {
  props: ['group', 'sourceUrl']
}
</script>
