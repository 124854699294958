<template>
  <div class="pa-6">
    <h1>Mes informations</h1>
    <v-form @submit.prevent="onSubmit">
    <v-select v-model="formData.userGender" :items="genderOptions" label="Tu est ..." outlined rounded></v-select>
    <v-text-field v-model="formData.username" label="Ton pseudo" outlined rounded disabled></v-text-field>
    <PlaceField v-model="formData.userLocation" :error-messages="[]" label="Ta ville"/>
    <p>Peux tu héberger des outtripeurs chez toi ?</p>
    <v-radio-group v-model="formData.canHostSomeone" row>
      <v-radio label="Oui" value="1"></v-radio>
      <v-radio label="Non" value="0"></v-radio>
    </v-radio-group>
    <v-text-field v-model="formData.howManyHostMaximum" v-if="formData.canHostSomeone === '1'" label="Combien de personne peux-tu héberger ?" outlined rounded></v-text-field>
    <v-textarea v-model="formData.aboutMe" label="A propos de toi..." outlined rounded></v-textarea>
    <v-select v-model="formData.relationshipStatus" :items="relationshipOptions" label="Ta situation amoureuse" outlined rounded></v-select>
    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field :value="computedDateFormattedDayjs" label="Ta date de naissance" prepend-icon="mdi-calendar" outlined rounded readonly v-bind="attrs" v-on="on"></v-text-field>
      </template>
      <v-date-picker v-model="formData.dateOfBirth" @input="menu = false" locale="fr-fr"></v-date-picker>
    </v-menu>

    <p>Les sports que tu pratiques...</p>
    <div v-for="sport in sportsOptions" :key="sport.value" class="sportChoice">
      <v-checkbox v-model="formData.sports" :label="sport.text" :value="sport.value"></v-checkbox>
    </div>

    <p>Souhaites-tu prêter/louer du materiel ?</p>
    <div v-for="equip in equipementsRentalListOptions" :key="equip.value" class="sportChoice">
      <v-checkbox v-model="formData.userEquipementRental" :label="equip.text" :value="equip.value"></v-checkbox>
    </div>

    <v-text-field v-model="formData.equipmentRentalPrice" label="En contrepartie de ..." outlined rounded></v-text-field>

    <h2>Mes réseaux sociaux</h2>
    <v-text-field v-model="formData.instagram" label="Instagram" prepend-icon="mdi-instagram" prefix="https://instagram.com/" outlined rounded></v-text-field>
    <v-text-field v-model="formData.facebook" label="Facebook" prepend-icon="mdi-facebook" prefix="https://facebook.com/" outlined rounded></v-text-field>
    <v-text-field v-model="formData.twitter" label="Twitter" prepend-icon="mdi-twitter" prefix="https://twitter.com/" outlined rounded></v-text-field>

    <v-btn block color="primary" type="submit" :loading="loading">Enregistrer</v-btn>
    <v-btn text color="error" @click="deleteAccount" class="my-2">Supprimer mon compte</v-btn>
    </v-form>

    <v-snackbar v-model="snackbarSuccess" timeout="5000" color="success" left style="z-index: 1001">
      {{ snackbarText }}
    </v-snackbar>
    <v-snackbar v-model="snackbarError" timeout="2000" color="error" style="z-index: 1001" left>
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import {mapState} from "vuex";
import dayjs from "dayjs";
import PlaceField from "@/components/Form/PlaceField";

export default {
  name: "MyInformations",
  components: {
    PlaceField,
  },
  data() {
    return {
      loading: false,
      snackbarSuccess: false,
      snackbarError: false,
      snackbarText: null,
      formData: {
        userGender: 'outtripeuse',
        photo: null,
        username: null,
        userLocation: null,
        canHostSomeone: null,
        howManyHostMaximum: null,
        aboutMe: null,
        relationshipStatus: null,
        dateOfBirth: null,
        sports: [],
        userEquipementRental: [],
        equipmentRentalPrice: null,
        instagram: null,
        facebook: null,
        twitter: null,
      },
      menu: false,
      genderOptions: [{text: "Outtripeuse", value: "outtripeuse"}, {text: "Outtripeur", value: "outtripeur"}],
      relationshipOptions: [{text: "En couple", value: "inRelationship"}, {text: "Célibataire", value: "single"}],
      sportsOptions: [
        {text: "Course à pied", value: "167937"},
        {text: "Gravel", value: "187310"},
        {text: "Kayak - Paddle", value: "169559"},
        {text: "Parapente", value: "169587"},
        {text: "Rando-Trail", value: "6"},
        {text: "Raquette", value: "169588"},
        {text: "Ski de fond", value: "202"},
        {text: "Ski de rando", value: "169589"},
        {text: "Surf", value: "169590"},
        {text: "Vélo de route", value: "37"},
        {text: "Vtt", value: "169591"},
        {text: "Via Ferrata", value: "195944"}
      ],
      equipementsRentalListOptions: [
        {value: 'bodyboard', text: 'BodyBoa'},
        {value: 'canoeKayak', text: 'Canoë-Kayak'},
        {value: 'kiteSurf', text: 'Kite Surf'},
        {value: 'longboardSurf', text: 'Longboard Surf'},
        {value: 'sledge', text: 'Luge'},
        {value: 'surfBoard', text: 'Planche de surf'},
        {value: 'snowshoes', text: 'Raquettes à neige'},
        {value: 'alpineSkiing', text: 'Ski alpin'},
        {value: 'crossCountrySkiing', text: 'Ski de fond'},
        {value: 'nordicSkiing', text: 'Ski de randonnée'},
        {value: 'snowboard', text: 'Snowboard'},
        {value: 'standUpPaddle', text: 'Stand-up Paddle'},
        {value: 'roadBike', text: 'Vélo de route'},
        {value: 'mountainBike', text: 'Vtt'},
        {value: 'wakeboard', text: 'Wakeboard'}
      ]
    }
  },
  created() {
    this.formData.userGender = this.user.userGender ? this.user.userGender : 'outtripeuse'
    this.formData.username = this.user.username
    this.formData.canHostSomeone = this.user.canHostSomeone
    this.formData.howManyHostMaximum = this.user.howManyHostMaximum
    this.formData.aboutMe = this.user.userAbout
    this.formData.relationshipStatus = this.user.relationshipStatus
    this.formData.dateOfBirth = this.user.dateOfBirth
    this.user.sports.map((sport) => {
      this.formData.sports.push(sport.id);
    })
    this.formData.userEquipementRental = this.user.equipmentRental
    this.formData.equipmentRentalPrice = this.user.equipmentRentalPrice
    this.formData.instagram = this.user.instagram
    this.formData.facebook = this.user.facebook
    this.formData.twitter = this.user.twitter
    console.log(this.user.userLocation);
    if (this.user.userLocation) {
      this.formData.userLocation = {
        text: this.user.userLocation.address,
        lat: this.user.userLocation.lat,
        lng: this.user.userLocation.lng,
        city: this.user.userLocation.parts.city,
        country: this.user.userLocation.parts.country,
        county: this.user.userLocation.parts.county,
        postcode: this.user.userLocation.parts.postcode,
        state: this.user.userLocation.parts.state
      }
    }
  },
  computed: {
    ...mapState("auth", ['user']),
    computedDateFormattedDayjs() {
      return this.formData.dateOfBirth ? dayjs(this.formData.dateOfBirth).format('DD/MM/YYYY') : ''
    }
  },
  methods: {
    onSubmit() {

      this.loading = true;

      if (this.formData.hosting === true){
        this.formData.hosting = 1
      }else{
        this.formData.hosting = 0
      }

      const form = new FormData();

      form.append('my_informations[username]', this.user.username);
      form.append('my_informations[email]', this.user.email);

      if (this.formData.userGender) {
        form.append('my_informations[gender]', this.formData.userGender);
      }
      if (this.formData.instagram) {
        form.append('my_informations[instagramUsername]', this.formData.instagram);
      }
      if (this.formData.twitter) {
        form.append('my_informations[twitterUsername]', this.formData.twitter);
      }
      if (this.formData.facebook) {
        form.append('my_informations[facebookUsername]', this.formData.facebook);
      }
      if (this.formData.dateOfBirth) {
        form.append('my_informations[dateOfBirth]', dayjs(this.formData.dateOfBirth).format('YYYY-MM-DD'));
      }
      if (this.formData.aboutMe) {
        form.append('my_informations[about]', this.formData.aboutMe);
      }
      if (this.formData.gender) {
        form.append('my_informations[gender]', this.formData.gender);
      }
      if (this.formData.userWebsite) {
        form.append('my_informations[userWebsite]', this.formData.userWebsite);
      }
      if (this.formData.userPhoneNumber) {
        form.append('my_informations[userPhoneNumber]', this.formData.userPhoneNumber);
      }

      if (this.formData.relationship) {
        form.append('my_informations[relationshipStatus]', this.formData.relationship);
      }

      form.append('my_informations[canHostSomeone]', this.formData.canHostSomeone);
      form.append('my_informations[howManyHostMaximum]', this.formData.howManyHostMaximum);

      if (this.formData.equipmentRentalPrice) {
        form.append('my_informations[equipmentRentalPrice]', this.formData.equipmentRentalPrice);
      }

      for (let i = 0; i < this.formData.sports.length; i++) {
        form.append('my_informations[sports][]', this.formData.sports[i]);
      }

      for (let i = 0; i < this.formData.userEquipementRental.length; i++) {
        form.append('my_informations[equipmentRental][]', this.formData.userEquipementRental[i]);
      }

      form.append('my_informations[place][lat]', this.formData.userLocation.lat);
      form.append('my_informations[place][lng]', this.formData.userLocation.lng);
      form.append('my_informations[place][text]', this.formData.userLocation.text);

      if (this.formData.userLocation.country) {
        form.append('my_informations[place][parts][country]', this.formData.userLocation.country);
      }
      if (this.formData.userLocation.county) {
        form.append('my_informations[place][parts][county]', this.formData.userLocation.county);
      }
      if (this.formData.userLocation.postcode) {
        form.append('my_informations[place][parts][postcode]', this.formData.userLocation.postcode);
      }
      if (this.formData.userLocation.state) {
        form.append('my_informations[place][parts][state]', this.formData.userLocation.state);
      }
      if (this.formData.userLocation.street) {
        form.append('my_informations[place][parts][street]', this.formData.userLocation.street);
      }
      if (this.formData.userLocation.town) {
        form.append('my_informations[place][parts][town]', this.formData.userLocation.town);
      }

      this.isBusy = true;

      this.$axios.post('/mon-compte/mes-informations', form)
        .then(() => {
          this.$axios.get('/user/me').then((meResponse) => {
            this.$store.commit('auth/user', meResponse.data);
          })

          this.snackbarSuccess = true
          this.snackbarText = "Ton profil seras mis à jours dans les 5 prochaines minutes."
        })
        .catch(() => {
          this.snackbarError = true
          this.snackbarText = "Oups une erreur est survenue..."
        })
        .then(() => this.loading = false)
    },
    deleteAccount() {
      this.$router.push({name: 'RemoveAccount'})
    }
  }
}
</script>

<style lang="scss" scoped>
.sportChoice {
  display: inline-block;
  margin: 3px 7px;
}
</style>
