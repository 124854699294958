<template>
  <div style="width: 100%; height: 100%;">
    <v-container v-if="!user">
      <h1>Messagerie</h1>
      <p>Ici c'est la messagerie, une fois connecté tu pourras :</p>
      <ul>
        <li>Discuter avec les autres personnes de la communauté</li>
        <li>Rejoindre les groupes d'activité</li>
      </ul>
      <em>Connecte-toi</em> ou <em>créer un compte</em>
    </v-container>
    <div v-else style="width: 100%; height: 100%;">
      <v-list subheader>
        <v-subheader
            v-text="'Canaux publics'"
        ></v-subheader>
        <v-skeleton-loader
            v-if="channelsListJoined === null"
            type="list-item-avatar@3"
        ></v-skeleton-loader>
        <v-list-item
            v-for="item in channelsListJoined"
            v-else
            :key="item._id"
            @click="go('group/'+item.name+'?layout=embedded')"
        >
          <v-list-item-avatar>
            <v-avatar
                color="primary"
                size="36"
            >
              <span class="white--text text-h5">{{ item.name[0] }}</span>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon>
            <v-icon color="primary accent-4">
              mdi-message-outline
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
      <v-divider
          :inset="false"
      ></v-divider>

      <v-list subheader>
        <v-subheader
            v-text="'Canaux privés'"
        ></v-subheader>
        <v-skeleton-loader
            v-if="groupList === null"
            type="list-item-avatar@3"
        ></v-skeleton-loader>
        <v-list-item
            v-for="item in groupList"
            v-else
            :key="item._id"
            @click="go('group/'+item.name+'?layout=embedded')"
        >
          <v-list-item-avatar>
            <v-avatar
                color="primary"
                size="36"
            >
              <span class="white--text text-h5">{{ item.name[0] }}</span>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon>
            <v-icon color="primary accent-4">
              mdi-message-outline
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>

      <v-divider
          :inset="false"
      ></v-divider>
      <v-list subheader>
        <v-subheader
            v-text="'Messages privés'"
        ></v-subheader>
        <v-skeleton-loader
            v-if="imList === null"
            type="list-item-avatar@3"
        ></v-skeleton-loader>
        <v-list-item
            v-for="item in imList"
            v-else
            :key="item._id"
            @click="go('direct/'+item.usernames[0]+'?layout=embedded')"
        >
          <v-list-item-avatar>
            <v-avatar
                color="primary"
                size="36"
            >
              <span class="white--text text-h5">{{ item.usernames[0][0] }}</span>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="item.usernames[0]"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon color="primary accent-4">
              mdi-message-outline
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  data: () => ({
    authToken: null,
    imList: null,
    groupList: null,
    channelsListJoined: null,
  }),
  computed: {
    ...mapState("auth", ['user'])
  },
  mounted() {
    this.$axios.get(`/messaging/create-user-token`).then((response) => {
      this.authToken = response.data.data.authToken;

      this.$axios.get(`/messaging/im-list`, {params: {'authToken': this.authToken}}).then((response) => {
        this.imList = response.data.ims
      })

      this.$axios.get(`/messaging/group-list`, {params: {'authToken': this.authToken}}).then((response) => {
        this.groupList = response.data.groups
      })

      this.$axios.get(`/messaging/channels-list-joined`, {params: {'authToken': this.authToken}}).then((response) => {
        this.channelsListJoined = response.data.channels
      })
    })
  },
  methods: {
    go(id) {
      this.$router.push({name: 'MessagingChat', query: {q: id}})
    }
  }
}
</script>
