<template>
    <v-dialog v-model="dialog" width="800" content-class="contentDialog">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 wb">
          Besoin d'aide pour savoir quoi renseigner ?
          <v-btn icon fab class="closeBtn">
            <v-icon @click="dialog = false">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-3" v-if="sportValue === 'chaussures'">
          <p>As-tu des conseils spécifiques sur les chaussures à prendre pour cet outtrip ? Exemple : privilégier des
            chaussures à crampons à la sortie de l’hiver car risque de névé ou après temps de pluie car terrain
            boueux.</p>
        </v-card-text>
        <v-card-text class="mt-3" v-if="sportValue === 'baton'">
          <p>Recommande tu l’utilisation de bâtons pour cet outtrip ? Et si oui pourquoi ?</p>
        </v-card-text>
        <v-card-text class="mt-3" v-if="sportValue === 'sac'">
          <p>Que faut-il prendre dans son sac à tout prix ? Une veste parce qu’il fait froid là haut ?</p>
        </v-card-text>
        <v-card-text class="mt-3" v-if="sportValue === 'raquettes'">
          <p>As-tu des conseils spécifiques sur les chaussures à prendre pour cet outtrip ? Exemple : privilégier des
            chaussures à crampons à la sortie de l’hiver car risque de névé ou après temps de pluie car terrain
            boueux.</p>
        </v-card-text>
        <v-card-text class="mt-3" v-if="sportValue === '167937'">
          <p>Ça t'es déjà arrivé de débarquer dans une région... pour les vacances ou plus si affinité et de taper
            frénétiquement "où courir à Bab El Oued" sans rien trouver ? De finir par arpenter inlassablement chaque
            recoin de Bab El esn espérant trouver un petit coin sympa pour te dégourdir les pattes ?!</p>
          <p>On est tous pareil... c’est pourquoi maintenant, grâce à toi, les outtripeurs accro au bitume pourront
            trouver le bonheur rapidement et simplement. Attention si ton parcours est plutôt de type "trail" merci de
            le renseigner dans outtrip "randotrail".</p>
          <p>Pour décrire ton activité, pense bien à renseigner où celui-ci s'effectue : est-ce un itinéraire qui passe
            par plusieurs lieux, un spot précis (exemple les Tuileries à Paris) ou bien une piste d'athlétisme. Dans ces
            cas là, précise bien les horaires d’ouverture des lieux qui peuvent être fermés au public et les
            conditions.</p>
          <p>Précise aussi s’il existe des points d’eau sur le chemin, des points d’intérêts ...</p>
        </v-card-text>
        <v-card-text class="mt-3" v-if="sportValue === '169559'">
          <p>Tu t'es déjà retrouvé au bord d'un lac, en te disant "oui mais c'est bien gentil, où est-ce que je vais
            pouvoir descendre en fait ?". A ne pas savoir quels sont les lieux autorisés ou non à la pratique du kayak
            ou du paddle. Ou encore si certains endroits peuvent être dangereux... C'est pas toujours facile de trouver
            ces informations... n’est-ce pas ?</p>
          <p>Alors pour permettre à tous nos outtripeurs de pouvoir pratiquer en toute sécurité donne le plus
            d'informations possibles :</p>
          <ol>
            <li>l'emplacement de la mise à l’eau</li>
            <li>le cadre, l'environnement, les différents points d’intérêts qu’il est possible de longer</li>
            <li>les conditions météo ou marée : exemple "il y a peu de vent sur le lac, mais si celui-ci se lève il est
              facilement possible de rejoindre un des bords" "vagues fortes..."
            </li>
            <li>Sécurité</li>
          </ol>
        </v-card-text>
        <v-card-text class="mt-3" v-if="sportValue === '6' || sportValue === '169588'">
          <p><strong>Dans le descriptif de ton outtrip, merci d’indiquer le maximum d’informations pour permettre à
            l’outtripeur de pouvoir facilement emprunter ce parcours.</strong></p>
          <p>Ça ne t’es jamais arrivé de partir en outtrip et de te dire "on est en pleine canicule...ça va taper, enfin
            sauf si je grimpe dans la montagne...enfin on verra bien". "avec tout ce qu'il est tombé hier, je me demande
            comment va être le terrain ?". "mon pote a le vertige, je l'embarque ou pas dans cette rando" ... On se pose
            tellement de questions lorsqu’on ne connaît pas un parcours... et il est parfois difficile d’avoir les
            réponses.</p>
          <p>Alors pour que celui qui te lise soit rassuré et ai toutes les informations les plus utiles, merci de
            détailler au maximum ta fiche !</p>
        </v-card-text>
        <v-card-text class="mt-3" v-if="sportValue === '202'">
          <p>Si tu as l’âme de Fourcade et la poussée de Thievent, tu as déjà dû te demander “où est-ce que je peux
            pratiquer du ski de fond dans le coin ?”. Maintenant que tu connais par coeur ton domaine préféré, il est
            temps de le présenter aux autres outtripeurs.</p>
          <p>Pour permettre à tous nos outtripeurs de pouvoir trouver LA station de ski de fond de leur rêve, n’oublie
            pas de préciser :</p>
          <ol>
            <li>le nombre de pistes, le nombre de kilomètres, les difficultés ex : <em>8 km de pistes vous attendent,
              dont certaines sont en accès libre</em>.
            </li>
            <li>le prix : <strong>La Bresse</strong> bénéficie d’un espace d’initiation gratuit, les forfaits sont à
              partir de 7,50 euros (7 à 15 ans). Et de 3,80 euros (à partir de 16 ans).
            </li>
            <li>les paysages, la situation ex : sur cette piste, vue imprenable sur le Col de la Madeleine, on évolue
              <em>au coeur du Parc naturel du Haut-Jura labellisé Nordic France</em>.
            </li>
            <li>l’ambiance. ex : La Chioula est une station familiale, accessible à tous</li>
            <li>la location éventuelle de matériel. ex : location de matériel possible prêt de la station, ouvert du 15
              décembre au 8 janvier ou à l’inverse : pas de magasin de location de ski de fond attention.
            </li>
          </ol>
        </v-card-text>
        <v-card-text class="mt-3" v-if="sportValue === '195944'">
          <ul>
            <li>F (Facile) : Aucune difficulté.</li>
            <li>PD (Peu Difficile) : 1 ou 2 passages légèrement athlétiques ou un peu en hauteur. Itinéraire pour
              enfants et débutants.
            </li>
            <li>AD (Assez Difficile) : Passages verticaux, parfois vertigineux et/ou passages sur des ponts un peu
              vacillants. Itinéraire pour un pratiquant peu entrainé maitrisant l'activité ou encadré.
            </li>
            <li>D (Difficile) : Passages verticaux assez physiques, engagement important. Itinéraire pour un pratiquant
              entrainé maitrisant l'activité ou encadré.
            </li>
            <li>TD (Très Difficile) : Passages très physique, souvent en dévers. Ces passages sont vite très éprouvant
              pour les bras. Itinéraire pour un pratiquant bien entrainé et maitrisant parfaitement l'activité.
            </li>
            <li>ED (Extrêmement Difficile) : Via réservé aux personnes avec de très bonnes qualités physiques sans
              aucune appréhension du vide.
            </li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: "HelpToKnowWhatWrite",
  props: {
    sportValue: {
      required: true
    }
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    openDialog() {
      this.dialog = true
    }
  }
}
</script>

<style lang="scss">
.HelpToKnowWhatWrite {
  color: #48bfaf;
  text-decoration: underline;
  font-size: 0.7rem;
}

.wb {
  word-break: normal;
}

.closeBtn {
  position: absolute;
  right: 0;
  top: 3px;
}

.contentDialog.v-dialog:not(.v-dialog--fullscreen){
  height: 80vh;
}
</style>
