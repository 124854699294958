import Vue from 'vue'
import Vuex from 'vuex'
import filter from './modules/filter'
import auth from './modules/auth'
import map from './modules/map'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {
    userLocation: {lat: null, lng: null},
  },
  getters: {
    userLocation (state) {
      return state.userLocation;
    }
  },
  mutations: {
    userLocation (state, value) {
      state.userLocation = value;
    }
  },
  modules: {
    filter,
    auth,
    map
  },
  strict: debug,
  plugins: debug ? [Vuex.createLogger()] : []
})
