<template>
  <v-container fluid>
    <AdBanner/>
    <h1>Nouveaux évènements</h1>
    <v-skeleton-loader type="list-item-avatar-two-line@4" v-if="events === null"></v-skeleton-loader>
    <v-list v-else-if="events.length === 0">
      <v-alert type="info" outlined>
        Aucun nouveau évènement trouvé
      </v-alert>
    </v-list>
    <EventList :events="events" v-else/>
  </v-container>
</template>

<script>
import EventList from "@/components/Event/EventList";
import AdBanner from "@/views/AdBanner";

export default {
  components: {EventList, AdBanner},
  data: () => ({
    events: null,
  }),
  mounted() {
    this.loadEvents();
  },
  methods: {
    loadEvents() {
      this.$axios.get('/events/new', {params: {state: this.$route.query.state}}).then(response => {
        this.events = response.data
      })
    },
  }
}
</script>
