<template>
  <div>
    <span class="mr-6 no-wrap" v-if="entry.sport[0].title">
      <v-icon color="primary">{{ entry.sport[0].materialIcon }}</v-icon>
      <span class="ml-2">{{ entry.sport[0].title }}</span>
    </span>
    <span class="mr-6 no-wrap" v-if="entry.outtripAltitude">
      <v-icon color="primary">mdi-image-filter-hdr</v-icon>
      <span class="ml-2">{{ entry.outtripAltitude }} m</span>
    </span>
    <span class="mr-6 no-wrap" v-if="entry.outtripDuration">
      <v-icon color="primary">mdi-clock-outline</v-icon>
      <span class="ml-2">{{ entry.outtripDuration }}h</span>
    </span>
    <span class="mr-6 no-wrap" v-if="entry.outtripDeparture.parts.city">
      <v-icon color="primary">mdi-map-marker</v-icon>
      <span class="ml-2">{{ entry.outtripDeparture.parts.city }}</span>
    </span>
    <span class="mr-6 no-wrap" v-if="entry.outtripDistance">
      <v-icon color="primary">mdi-map-marker-distance</v-icon>
      <span class="ml-2">{{ entry.outtripDistance }} km</span>
    </span>
    <span class="mr-6 no-wrap" v-if="entry.outtripPositiveElevation">
      <v-icon color="primary">mdi-arrow-top-right</v-icon>
      <span class="ml-2">{{ entry.outtripPositiveElevation }} m</span>
    </span>
    <span class="mr-6 no-wrap" v-if="entry.negativeElevation">
      <v-icon color="primary">mdi-arrow-bottom-right</v-icon>
      <span class="ml-2">{{ entry.negativeElevation }} m</span>
    </span>
    <span class="mr-6 no-wrap" v-if="entry.outtripSlopeLevel">
      <v-icon color="primary">mdi-angle-acute</v-icon>
      <span class="ml-2">{{ entry.outtripSlopeLevel }}</span>
    </span>
    <span class="mr-6 no-wrap" v-if="entry.outtripNumberOfGreenTrack">
      <v-icon color="green">mdi-ski</v-icon>
      <span class="ml-2">{{ entry.outtripNumberOfGreenTrack }}</span>
    </span>
    <span class="mr-6 no-wrap" v-if="entry.outtripNumberOfBlueTrack">
      <v-icon color="blue">mdi-ski</v-icon>
      <span class="ml-2">{{ entry.outtripNumberOfBlueTrack }}</span>
    </span>
    <span class="mr-6 no-wrap" v-if="entry.outtripNumberOfRedTrack">
      <v-icon color="red">mdi-ski</v-icon>
      <span class="ml-2">{{ entry.outtripNumberOfRedTrack }}</span>
    </span>
    <span class="mr-6 no-wrap" v-if="entry.outtripNumberOfBlackTrack">
      <v-icon color="black">mdi-ski</v-icon>
      <span class="ml-2">{{ entry.outtripNumberOfBlackTrack }}</span>
    </span>
    <span class="mr-6 no-wrap" v-if="entry.tourType">
      <v-icon color="primary">mdi-trending-up</v-icon>
      <span class="ml-2">{{ entry.tourType }}</span>
    </span>
    <div class="mr-6" v-if="entry.months && entry.months.length > 0">
      <v-icon color="primary">mdi-calendar</v-icon>
      <span class="ml-2">{{ entry.months.join(' / ') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "OuttripIconsItineraires",
  props: {
    entry: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
