<template>
  <div class="pa-6 text-center">
    <h1 class="my-3">Connexion</h1>
    <v-form @submit.prevent="connect">
      <v-text-field v-model="form.username" type="text" outlined rounded label="Email" required></v-text-field>
      <v-text-field v-model="form.password" :type="show ? 'text' : 'password'" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" outlined rounded label="Mot de passe" @click:append="show = !show" required></v-text-field>
      <v-btn type="submit" color="primary" rounded :loading="loading" :disabled="loading">Se connecter</v-btn>
    </v-form>
    <p class="mt-10">Pas encore de compte ?
      <router-link to="/s-inscrire">Créer un compte</router-link>
    </p>
    <router-link to="/mot-de-passe-oublie">Mot de passe oublié ?</router-link>

    <v-snackbar v-model="snackbar" absolute bottom left color="error">{{ message }}</v-snackbar>
  </div>
</template>

<script>
export default {
  name: "LoginForm",
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      show: false,
      snackbar: false,
      loading: false,
      message: ''
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$emit("success")
    }
  },
  methods: {
    connect() {
      this.loading = true
      if (this.form.username.length > 0 && this.form.password.length > 0) {

        this.$axios.post("/login_check", {
            username: this.form.username,
            password: this.form.password
        })
            .then((loginCheckResponse) => {
              if (loginCheckResponse.data.token) {
                this.$axios.get('/user/me', {
                  headers: {
                    "Authorization": "Bearer " + loginCheckResponse.data.token,
                  }
                }).then((meResponse) => {
                  this.$store.commit('auth/loginSuccess', {tokens: loginCheckResponse.data, user: meResponse.data})
                  this.$emit("success");
                })
              }
            })
            .catch(error => {
              this.snackbar = true
              this.message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString()
            })
            .then(() => this.loading = false)
        ;
      }
    }
  }
}
</script>

<style scoped>

</style>
