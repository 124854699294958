<template>
  <div>
    <h3 class="my-2" v-if="
    entry.outtripShoesEquipment ||
    entry.outtripBagEquipment ||
    entry.outtripSticksEquipment ||
    entry.outtripSnowShoesEquipment ||
    entry.outtripSkiEquipment ||
    entry.outtripBikeEquipment ||
    entry.outtripNutritionEquipment
">Equipement</h3>

    <div v-if="entry.outtripShoesEquipment" class="mb-3">
      <strong>Chassures : </strong>
      <span v-html="entry.outtripShoesEquipment"></span>
    </div>

    <div v-if="entry.outtripBagEquipment" class="mb-3">
      <strong>Sac : </strong>
      <span v-html="entry.outtripBagEquipment"></span>
    </div>

    <div v-if="entry.outtripSticksEquipment" class="mb-3">
      <strong>Bâtons : </strong>
      <span v-html="entry.outtripSticksEquipment"></span>
    </div>

    <div v-if="entry.outtripSnowShoesEquipment" class="mb-3">
      <strong>Raquettes : </strong>
      <span v-html="entry.outtripSnowShoesEquipment"></span>
    </div>

    <div v-if="entry.outtripSkiEquipment" class="mb-3">
      <strong>Ski : </strong>
      <span v-html="entry.outtripSkiEquipment"></span>
    </div>

    <div v-if="entry.outtripBikeEquipment" class="mb-3">
      <strong>Vélo : </strong>
      <span v-html="entry.outtripBikeEquipment"></span>
    </div>

    <div v-if="entry.outtripNutritionEquipment" class="mb-3">
      <strong>Nutrition : </strong>
      <span v-html="entry.outtripNutritionEquipment"></span>
    </div>


  </div>
</template>

<script>
export default {
  name: "OuttripEquipement",
  props: {
    entry: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
