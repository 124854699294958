<template>
  <div class="pa-6" style="margin-bottom: 94px">
    <h1>Mes activités</h1>

    <v-skeleton-loader
        type="list-item-avatar-two-line@8"
        v-if="activites === null"
    ></v-skeleton-loader>
    <v-alert type="info" outlined v-else-if="activites.length <= 0">
      Aucun évènement de trouvé ! <v-btn text link :to="{name:'CreateEvent'}">Ajoutes-en une !</v-btn>
    </v-alert>
    <EventList v-else-if="activites.length > 0" :events="activites"/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import dayjs from "dayjs";
import EventList from "@/components/Event/EventList";

export default {
  name: "MyActivities",
  components: {EventList},
  data() {
    return {
      activites: null,
      participants: null,
      snackbarSuccess: false,
      snackbarError: false,
      snackbarText: null,
      loadingButton: false,
      dialog: false,
      dialogSignalement: false,
      reportId: null,
      activiteId: null
    }
  },
  mounted() {
    this.$axios.get('/user/' + this.user.id + '/events?pwa=1').then(response => {
      this.activites = response.data
    })
  },
  computed: {
    ...mapState("auth", ['user'])
  },
  filters: {
    date(date) {
      return dayjs(date).format('DD/MM/YYYY')
    }
  }
}
</script>
