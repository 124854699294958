<template>
  <v-container fluid>
      <h1>Le paiment s'est bien déroulé !</h1>
      <div class="text-center pa-3" style="background-color: #48bfaf">
        <p style="color: white" v-if="isLoading">L'abonnement est en cours de validation...</p>
        <div><v-progress-circular
            :width="3"
            color="white"
            indeterminate
            v-if="isLoading"
        ></v-progress-circular>
          <p style="color: white" v-if="!isLoading">Féliciation tu es maintenant premium !</p>
          <v-icon left size="4rem" color="white" v-if="!isLoading">mdi-check</v-icon>
        </div>
      </div>
      <div class="text-center pa-3 mt-3" style="background-color: #171923">
        <p style="color: #FFCC33">Vérifie ton compte afin d'obtenir le badge Outtrip</p>
        <div><v-icon left size="4rem" color="premium">mdi-leaf-circle-outline</v-icon></div>
        <v-btn color="secondary" link :to="{name: 'UserVerificationRequest'}" light>Vérifier mon compte</v-btn>
      </div>
    </v-container>
</template>

<script>
export default {
  data: () => ({
    isLoading: true
  }),
  mounted() {
      this.$axios.post('/subscription/check', {
        sessionId: this.$route.query.session_id
      }).then(() => {
        this.$axios.get('/user/me').then((meResponse) => {
          this.$store.commit('auth/user', meResponse.data)
          this.isLoading = false
        })
      })
  }
}
</script>