<template>
  <div>
    <v-btn rounded color="secondary" @click="click" :loading="isLoading">
      <v-icon left dark>
        mdi-chat-processing
      </v-icon>
      {{ label }}
    </v-btn>

    <PremiumModal ref="premiumModal"/>
    <LoginModal ref="loginModal" @success="closeLoginModal" style="z-index: 1005"/>

  </div>
</template>

<script>
import PremiumModal from "@/components/PremiumModal";
import {mapState} from "vuex";
import LoginModal from "@/components/LoginModal";

export default {
  name: 'MessagingGoToButton',
  components: {PremiumModal, LoginModal},
  data: () => ({
    isLoading: false
  }),
  computed: {
    ...mapState("auth", ['user'])
  },
  props: {
    label: {
      type: String,
      required: true
    },
    q: {
      type: String,
      required: true
    }
  },
  methods: {
    openLoginModal() {
      this.$refs.loginModal.openLoginModal()
    },
    closeLoginModal() {
      this.$refs.loginModal.closeLoginModal()
      this.redirect();
    },
    click() {
      if (!this.user) {
        this.openLoginModal();
        return;
      }

      this.redirect();
    },
    redirect() {
      this.isLoading = true;
      this.$axios.get(`/messaging/ask-authorization`, {
        params: {
          q: this.q,
        }
      })
      .then(response => {
        const data = response.data;
        console.log(data);
        if (!data.authorized) {
          if (data.reason === 'not_premium') {
            this.$refs.premiumModal.open()
            return;
          }
        }

        this.$router.push({name: 'MessagingChat', query: {q: this.q}})
      })
      .then(() => this.isLoading = false)
    }
  }
}
</script>
