<template>
  <v-container fluid>
    <AdBanner/>
    <h1>Nouvelles activités</h1>
    <v-skeleton-loader type="list-item-avatar-two-line@4" v-if="outtrips === null"></v-skeleton-loader>
    <v-list v-else-if="outtrips.length === 0">
      <v-alert type="info" outlined>
        Aucune nouvelle activité trouvée
      </v-alert>
    </v-list>
    <OuttripList :outtrips="outtrips" v-else/>
  </v-container>
</template>

<script>
import OuttripList from "@/components/Outtrip/OuttripList";
import AdBanner from "@/views/AdBanner";

export default {
  components: {OuttripList, AdBanner},
  data: () => ({
    outtrips: null,
  }),
  mounted() {
    this.loadActivites();
  },
  methods: {
    loadActivites() {
      this.$axios.get('/outtrips/new', {params: {state: this.$route.query.state}}).then(response => {
        this.outtrips = response.data
      })
    },
  }
}
</script>
