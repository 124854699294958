<template>
  <v-container fluid>
    <h1>Créer une activité</h1>
    <div v-if="!user">
      <v-alert border="left" colored-border type="warning" elevation="2">
        Connecte toi pour accéder à la page.
        <span class="primary--text text-decoration-underline" style="cursor: pointer" @click.stop="openLoginModal">Se connecter</span>
      </v-alert>
      <LoginModal ref="loginModal" @success="closeLoginModal"/>
      <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
    </div>
    <div v-else>
      <v-form @submit.prevent="submit">
        <v-text-field v-model="title" label="Titre de l'évènement" outlined rounded required :error-messages="titleErrors"></v-text-field>
        <OuttripField ref="outtripField" empty-message="Aucune activité trouvée." v-model="outtrips"/>
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="computedDateFormattedDayjs" label="Date début et fin" prepend-inner-icon="mdi-calendar" outlined rounded readonly v-bind="attrs" v-on="on" required :error-messages="dateErrors"></v-text-field>
          </template>
          <v-date-picker v-model="datetime" @blur="menu = false" locale="fr-fr" range></v-date-picker>
        </v-menu>
        <PlaceField v-model="location" label="Lieu du rendez-vous" :error-messages="locationErrors"/>
        <v-select v-model="sports" outlined rounded :items="sportList" label="Sports" multiple persistent-hint required :error-messages="sportErrors"></v-select>
        <v-textarea v-model="description" outlined rounded label="Description" :error-messages="descriptionErrors"></v-textarea>
        <v-text-field v-model.number="participants" label="Nombre de participants" outlined rounded required :error-messages="nbParticipantsErrors"></v-text-field>
        <v-switch v-model="autoAccept" label="Accepter automatiquement les participants"></v-switch>
        <v-text-field v-model.number="priceWithTaxes" label="Participation financière" :messages="priceWithTaxesMessages" suffix="€" outlined rounded></v-text-field>
        <GalleryWidget v-model="photos" :folder-id="18" :error-messages="locationErrors"/>
        <v-btn block color="primary" type="submit" :loading="loading">Publier</v-btn>
      </v-form>
    </div>


    <v-snackbar v-model="snackbarSuccess" timeout="5000" color="success" left style="z-index: 1001">
      {{ snackbarText }}
    </v-snackbar>
    <v-snackbar v-model="snackbarError" timeout="2000" color="error" style="z-index: 1001" left>
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import {numeric, required} from "vuelidate/lib/validators";
import GalleryWidget from "@/components/Form/GalleryWidget";
import OuttripField from "../components/Form/OuttripField";
import PlaceField from "../components/Form/PlaceField";
import useVuelidate from '@vuelidate/core'
import LoginModal from "@/components/LoginModal.vue";
import {mapState} from "vuex";

export default {
  name: "CreateEvent",
  components: {GalleryWidget, OuttripField, PlaceField, LoginModal},
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      snackbarSuccess: false,
      snackbarError: false,
      snackbarText: null,
      loading: false,
      title: null,
      menu: null,
      datetime: [],
      autoAccept: true,
      sports: null,
      participants: null,
      description: null,
      outtrips: [],
      location: null,
      priceWithTaxes: 0,
      photos:[],
      sportList: [
        {text: "Course à pied", value: "167937"},
        {text: "Gravel", value: "187310"},
        {text: "Kayak - Paddle", value: "169559"},
        {text: "Parapente", value: "169587"},
        {text: "Rando-Trail", value: "6"},
        {text: "Raquette", value: "169588"},
        {text: "Ski de fond", value: "202"},
        {text: "Ski de rando", value: "169589"},
        {text: "Surf", value: "169590"},
        {text: "Vélo de route", value: "37"},
        {text: "Vtt", value: "169591"},
        {text: "Via ferrata", value: "195944"}
      ],
      priceWithTaxesMessages: [
          'La participation financière sera remise en main propre à l\'organisateur de l\'évènement par le participant.'
      ]
    }
  },
  validations: {
    title: {
      required,
      $autoDirty: true
    },
    description: {
      required,
      $autoDirty: true
    },
    participants: {
      required,
      numeric,
      $autoDirty: true
    },
    datetime: {
      required,
      $autoDirty: true
    },
    sports: {
      required,
      $autoDirty: true
    },
    location: {
      required
    },
  },
  computed: {
    ...mapState("auth", ['user']),
    titleErrors() {
      const errors = []
      if (!this.v$.title.$dirty) return errors
      this.v$.title.required.$invalid && errors.push('Le titre est obligatoire.')
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.v$.description.$dirty) return errors
      this.v$.description.required.$invalid && errors.push('La description est obligatoire.')
      return errors
    },
    dateErrors() {
      const errors = []
      if (!this.v$.datetime.$dirty) return errors
      this.v$.datetime.required.$invalid && errors.push('La date est obligatoire.')
      return errors
    },
    sportErrors() {
      const errors = []
      if (!this.v$.sports.$dirty) return errors
      this.v$.sports.required.$invalid && errors.push('Le choix du sport est obligatoire.')
      return errors
    },
    nbParticipantsErrors() {
      const errors = []
      if (!this.v$.participants.$dirty) return errors
      this.v$.participants.required.$invalid && errors.push('Le nombre de participant est obligatoire.')
      this.v$.participants.numeric.$invalid && errors.push('Ceci n\'est pas un nombre valide.')
      return errors
    },
    computedDateFormattedDayjs() {
      if (this.datetime) {
        let dates = []
        this.datetime.map(datetime => dates.push(dayjs(datetime).format('DD/MM/YYYY')))

        return dates.join(' ~ ')
      } else return ''
    },
    locationErrors() {
      const errors = []
      if (!this.v$.location.$dirty) return errors
      this.v$.location.required.$invalid && errors.push('Ce champs est obligatoire.')
      return errors
    },
  },
  methods: {
    openLoginModal() {
      this.$refs.loginModal.openLoginModal()
    },
    closeLoginModal() {
      this.$refs.loginModal.closeLoginModal()
    },
    async submit() {
      this.loading = true

      const isFormCorrect = await this.v$.$validate()

      if (!isFormCorrect) {
        this.snackbarError = true
        this.snackbarText = 'Le formulaire contient des erreurs.';
        this.loading = false
        return;
      }

      const form = this.getFormData();

      this.$axios.post('/event', form)
          .then(() => {
            this.snackbarSuccess = true
            this.snackbarText = "Ton évènement a bien été créé :). Il sera accessible dans les 5 prochaines minutes."

            setTimeout(() => this.$router.push({name: 'Home'}), 5000)
          })
          .catch(error => {
            this.snackbarError = true
            this.snackbarText = error.message
          })
          .then(() => this.loading = false)
    },
    getFormData() {
      const form = new FormData();

      if (this.sports) {
        for (let i = 0; i < this.sports.length; i++) {
          form.append('event[fields][sports][]', this.sports[i]);
        }
      }

      if (this.title) {
        form.append('event[title]', this.title);
      }
      if (this.description) {
        form.append('event[fields][description]', this.description);
      }
      if (this.participants) {
        form.append('event[fields][maximumParticipantsNumber]', this.participants);
      }

      form.append('event[fields][eventAccepterAutomatiquementLesParticipants]', '0');

      if (this.autoAccept) {
        form.append('event[fields][eventAccepterAutomatiquementLesParticipants]', '1');
      }

      if (this.priceWithTaxes) {
        form.append('event[fields][eventParticipationFinanciere]', this.priceWithTaxes);
      }
      if (this.outtrips) {
        for (let i = 0; i < this.outtrips.length; i++) {
          form.append('event[fields][eventOuttrips][]', this.outtrips[i].craftCmsId);
        }
      }
      if (this.location.text) {
        form.append('event[fields][location][address]', this.location.text);
      }
      if (this.location.lat) {
        form.append('event[fields][location][lat]', this.location.lat);
      }
      if (this.location.lng) {
        form.append('event[fields][location][lng]', this.location.lng);
      }
      if (this.location.street) {
        form.append('event[fields][location][parts][address]', this.location.street);
      }
      if (this.location.town) {
        form.append('event[fields][location][parts][city]', this.location.town);
      }
      if (this.location.country) {
        form.append('event[fields][location][parts][country]', this.location.country);
      }
      if (this.location.county) {
        form.append('event[fields][location][parts][county]', this.location.county);
      }
      if (this.location.postcode) {
        form.append('event[fields][location][parts][postcode]', this.location.postcode);
      }
      if (this.location.state) {
        form.append('event[fields][location][parts][state]', this.location.state);
      }

      if (this.datetime[0]) {
        form.append('event[fields][startDate][date]', dayjs(this.datetime[0]).format('YYYY-MM-DD'));
        form.append('event[fields][startDate][timezone]', 'Europe/Paris');
        form.append('event[fields][startDate][time]', dayjs(this.datetime[0]).format('HH:mm:ss'));
      }

      if (this.datetime[1]) {
        form.append('event[fields][endDate][date]', dayjs(this.datetime[1]).format('YYYY-MM-DD'));
        form.append('event[fields][endDate][timezone]', 'Europe/Paris');
        form.append('event[fields][endDate][time]', dayjs(this.datetime[1]).format('HH:mm:ss'));
      }


      for (let i = 0; i < this.photos.length; i++) {
        form.append('event[fields][eventGallery][]', this.photos[i]);
      }

      return form;
    }
  },
  mounted() {
    this.$axios.get('/user/me').then((meResponse) => {
      this.$store.commit('auth/user', meResponse.data)
    })

    if (!this.user) {
      this.openLoginModal()
    }

    if (this.$route.query.outtripId) {
      this.$refs.outtripField.isLoading = true;

      this.$axios.get(`/outtrip/${this.$route.query.outtripId}/card-details`)
          .then((response) => this.outtrips = [response.data])
          .then(() => this.$refs.outtripField.isLoading = false)
    }
  }
}
</script>
