<template>
  <div>
    <v-list three-line>
      <template v-for="(outtrip, index) in outtrips">
        <v-divider v-if="index !== 0" :key="index" inset/>
        <v-list-item v-intersect="index+1 === outtrips.length && onIntersect" :key="outtrip.slug" link :to="{name: 'Outtrip', params: {slug: outtrip.slug}}">
          <v-list-item-avatar rounded>
            <v-img :src="outtrip.url" v-if="outtrip.url"></v-img>
            <v-img :src="outtrip.outtripGallery[0].embeddedAsset.url" v-else-if="outtrip.outtripGallery.length > 0 && outtrip.outtripGallery[0].embeddedAsset"></v-img>
            <v-img :src="outtrip.outtripGallery[0].url" v-else-if="outtrip.outtripGallery.length > 0"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="text-wrap" style="cursor: pointer">
              <v-icon size="small" color="primary" v-if="user && outtrip.user_id == user.id && outtrip.status === 'live'">mdi-checkbox-blank-circle</v-icon>
              <v-icon size="small" color="warning" v-else-if="user && outtrip.user_id == user.id && outtrip.status === 'disabled'">mdi-checkbox-blank-circle</v-icon>
              {{ outtrip.title }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <div class="spacing">
                <span class="icons no-wrap" v-if="outtrip.materialIcon">
                 <v-icon color="primary">{{ outtrip.materialIcon }}</v-icon>
                 <span>{{ outtrip.sportTitle }}</span>
                </span>
                <span class="icons no-wrap" v-if="outtrip.sport">
                 <v-icon color="primary">{{ outtrip.sport[0].materialIcon }}</v-icon>
                 <span>{{ outtrip.sport[0].title }}</span>
                </span>
                <span class="icons no-wrap" v-if="outtrip.city">
                 <v-icon color="primary">mdi-map-marker</v-icon>
                 <span>{{ outtrip.city }}</span>
                </span>
                <span class="icons no-wrap" v-if="outtrip.outtripDeparture">
                 <v-icon color="primary">mdi-map-marker</v-icon>
                 <span>{{ outtrip.outtripDeparture.parts.city }}</span>
                </span>
                <span class="icons no-wrap" v-if="outtrip.distance">
                 <v-icon color="primary">mdi-map-marker-distance</v-icon>
                 <span>{{ outtrip.distance }} km</span>
                </span>
                <span class="icons no-wrap" v-if="outtrip.outtripDistance">
                 <v-icon color="primary">mdi-map-marker-distance</v-icon>
                 <span>{{ outtrip.outtripDistance }} km</span>
                </span>
                <span class="icons no-wrap" v-if="outtrip.altitude">
                 <v-icon color="primary">mdi-image-filter-hdr</v-icon>
                 <span>{{ outtrip.altitude }}km</span>
                </span>
                <span class="icons no-wrap" v-if="outtrip.outtripAltitude">
                 <v-icon color="primary">mdi-image-filter-hdr</v-icon>
                 <span>{{ outtrip.outtripAltitude }}km</span>
                </span>
                <span class="icons no-wrap" v-if="outtrip.averageDuration">
                 <v-icon color="primary">mdi-clock-outline</v-icon>
                 <span>{{ outtrip.averageDuration }}h</span>
                </span>
                <span class="icons no-wrap" v-if="outtrip.outtripAltitude">
                 <v-icon color="primary">mdi-clock-outline</v-icon>
                 <span>{{ outtrip.outtripAltitude }}h</span>
                </span>
                <span class="icons no-wrap" v-if="outtrip.positiveElevation">
                 <v-icon color="primary">mdi-arrow-top-right</v-icon>
                 <span>{{ outtrip.positiveElevation }}m</span>
                </span>
                <span class="icons no-wrap" v-if="outtrip.outtripPositiveElevation">
                 <v-icon color="primary">mdi-arrow-top-right</v-icon>
                 <span>{{ outtrip.outtripPositiveElevation }}m</span>
                </span>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="user && outtrip.user_id == user.id">
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group color="primary">
                  <v-list-item @click="goToEdit(outtrip.craftCmsId)">
                    <v-list-item-icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Editer</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click.stop="goToDelete(outtrip.craftCmsId)">
                    <v-list-item-icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Supprimer</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>

    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          Supprimer l'outtrip ?
        </v-card-title>

        <v-card-text>
          Etes vous vraiment sur de vouloir supprimer l'outtrip ? Cette action est irréversible !
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="dialog = false">
            Annuler
          </v-btn>

          <v-btn color="error" @click="dialog = false">
            <v-icon>mdi-delete</v-icon>
            Supprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: "OuttripList",
  props: {
    outtrips: {
      required: true,
      selectedEvent: null
    }
  },
  data(){
    return{
      dialog: false,
    }
  },
  methods: {
    goToEdit(id) {
      this.$router.push({name: 'OuttripEdit', params: {id: id}})
    },
    goToDelete(id) {
      this.dialog = true
      this.selectedEvent = id;
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.$emit('lastShow');
      }
    },
  },
  computed: {
    ...mapState("auth", ['user']),
  }
}
</script>


<style scoped lang="scss">
.spacing {
  .icons {
    margin-right: 10px;

    span {
      margin-left: 5px;
    }
  }
}
</style>
