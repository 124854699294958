<template>
  <div>
    <v-switch v-model="formData.bus" label="Accès en bus"></v-switch>
    <v-switch v-model="formData.train" label="Accès en train"></v-switch>
    <v-switch v-model="formData.voiture" label="Accès en voiture"></v-switch>
    <v-switch v-model="formData.marche" label="Accès en marche"></v-switch>
  </div>
</template>

<script>
export default {
  name: "FormTransportSwitch",
  props: {
    formData: {
      required: true,
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
