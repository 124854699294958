<template>
  <div>
    <v-btn :color="color" :loading="loading" @click="download">
      <v-icon left>{{ icon }}</v-icon>
      <span>{{ text }}</span>
    </v-btn>

    <PremiumModal ref="premiumModal"/>
  </div>
</template>

<script>
import download from "downloadjs"
import PremiumModal from "@/components/PremiumModal";

export default {
  name: "DownloadGPX",
  components: {PremiumModal},
  props: {
    outtrip: {
      required: true
    }
  },
  data() {
    return {
      loading: false,
      color: "primary",
      icon: "mdi-cloud-download",
      text: "Télécharger le fichier GPX"
    }
  },
  methods: {
    download() {
      const premium = this.$store.getters["auth/user"].isPremium

      if (premium) {
        this.loading = true
        this.$axios.get(`/outtrip/${this.outtrip.id}/download-gpx-trace`)
            .then((response) => {
              this.color = "success"
              this.icon = "mdi-check"
              this.text = "Fichier téléchargé"
              download(response.data.content, response.data.filename, response.data.mimeType);
            })
            .catch(() => {
              this.color = "error"
              this.icon = "mdi-close"
              this.text = "Une erreur est survenue"
            })
            .then(() => this.loading = false)
      } else {
        this.$refs.premiumModal.open()
      }
    }
  },
}
</script>