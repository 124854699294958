const tokens = JSON.parse(localStorage.getItem('tokens'))
const user = JSON.parse(localStorage.getItem('user'))
const initialState = tokens
    ? {status: {loggedIn: true}, tokens, user}
    : {status: {loggedIn: false}, tokens: null, user: null}

export default {
    namespaced: true,
    state: initialState,
    getters:{
        loggedIn(state){
            console.log(state);
           return state.status.loggedIn
        },
        user(state) {
           return state.user
        },
        refreshToken(state) {
            return state.tokens.refresh_token
        },
        token(state) {
            if (state.tokens) {
                return state.tokens.token;
            }

            return null;
        },

    },
    actions: {
        logout({commit}) {
            commit('logout');
        },
        refreshToken({commit}, token) {
            commit('refreshToken', token);
        }
    },
    mutations: {
        user(state, user) {
            state.user = user
            localStorage.setItem("user", JSON.stringify(state.user))
        },
        loginSuccess(state, payload) {
            state.status.loggedIn = true;
            state.tokens = payload.tokens;
            state.user = payload.user;

            localStorage.setItem("tokens", JSON.stringify(state.tokens))
            localStorage.setItem("user", JSON.stringify(state.user))
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.tokens = null;
            state.user = null;

            localStorage.setItem("tokens", JSON.stringify(state.tokens))
            localStorage.setItem("user", JSON.stringify(state.user))
        },
        logout(state) {
            state.status.loggedIn = false;
            state.tokens = null;
            state.user = null;

            localStorage.removeItem("tokens");
            localStorage.removeItem("user");
        },
        refreshToken(state, token) {
            state.status.loggedIn = true;
            state.tokens = {...state.tokens, token: token};

            localStorage.setItem("tokens", JSON.stringify(state.tokens))
        }
    }
}
