<template>
  <v-card>
    <v-card-title class="mt-8 bg-title" v-html="refuge.title"></v-card-title>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text right link :to="{name: 'PointOfInterest', params:{id: refuge.id}}">
        Explorer
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'PointOfInterestCard',
  props: {
    refuge: {
      required: true
    }
  }
}
</script>

<style scoped>
.v-card__title {
  word-break: keep-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
