export default {
  namespaced: true,
  state: () => ({
    type: 'tracks',
    sports: [],
    group: [],
    place: null
  }),
  getters: {
    type (state) {
      return state.type;
    },
    sports (state) {
      return state.sports;
    },
    place (state) {
      return state.place;
    }
  },
  mutations: {
    type (state, value) {
      state.type = value;
    },
    sports (state, value) {
      state.sports = value;
    },
    place (state, value) {
      state.place = value;
    },
    group (state, value) {
      state.group = value;
    },
    clear(state) {
      state.type = 'tracks';
      state.sports = [];
      state.place = null;
    },
  }
}
