<template>
  <div style="width: 100%; height: 100%;">
    <iframe id="rocketchat" ref="iframe" :src="'https://messagerie.outtrip.fr/'+url" frameBorder="0"
            style="width: 100%; height: 100%;" title="myframe" @load="login"></iframe>
  </div>
</template>

<script>
export default {
  computed: {
    url() {
      if (typeof this.$route.query.q !== 'undefined') {
        return this.$route.query.q;
      }

      return '';
    }
  },
  methods: {
    login() {
      this.$axios.get(`/messaging/create-user-token`).then((response) => {
        document.getElementById("rocketchat").contentWindow.postMessage({
          externalCommand: 'login-with-token',
          token: response.data.data.authToken
        }, '*');
      })
    },
  }
}
</script>
