<template>
  <div>
  <v-stepper
      v-model="e6"
      vertical
  >
    <v-stepper-step
        :complete="e6 > 1"
        step="1"
        editable
    >
      Bienvenue, withalex
      <small>Pour mieux te connaitre et que les autres outtripeurs puissent te connaitre aussi nous te proposons de te decrire rapidement :</small>
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-container>
        <v-textarea rounded clearable outlined clear-icon="mdi-close-circle"></v-textarea>
        <v-btn text>Passer</v-btn>
        <v-btn rounded color="primary" @click="e6 = 2">
          Continuer
        </v-btn>
      </v-container>
    </v-stepper-content>

    <v-stepper-step
        :complete="e6 > 2"
        step="2"
        editable
    >
      Tes passions
      <small>Dis nous en un peu plus sur tes passions.</small>
    </v-stepper-step>

    <v-stepper-content step="2">
      <v-container>
        <div v-for="sport in sportsOptions" :key="sport.value">
          <v-checkbox v-model="formData.sports" :label="sport.text" :value="sport.value"></v-checkbox>
        </div>
        <v-btn text>Passer</v-btn>
        <v-btn rounded color="primary" @click="e6 = 3">
          Continuer
        </v-btn>
      </v-container>
    </v-stepper-content>

    <v-stepper-step
        :complete="e6 > 3"
        step="3"
        editable
    >
      Qui es-tu ?
    </v-stepper-step>

    <v-stepper-content step="3">
      <v-container>
        <v-select v-model="formData.gender" outlined rounded :items="genderOptions" label="Tu es ..." item-text="label" item-value="value"></v-select>
        <v-select v-model="formData.relationship" outlined rounded :items="inRelationshipOptions" label="Ta situation amoureuse" item-text="label" item-value="value"></v-select>
        <PlaceField v-model="formData.place"/>
        <v-radio-group v-model="formData.hosting" row label="Peux-tu héberger des membres de la communauté chez toi ?">
          <v-radio v-for="option in canHostSomeoneOptions" :key="option.value" :label="option.label" :value="option.value"></v-radio>
        </v-radio-group>
        <v-select v-model="formData.userEquipementRental" outlined rounded multiple :items="equipementsRentalListOptions" label="Souhaites-tu prêter/louer du materiel ?" item-text="label" item-value="value"></v-select>
        <v-btn text>Passer</v-btn>
        <v-btn rounded color="primary" @click="e6 = 4">
          Continuer
        </v-btn>
      </v-container>
    </v-stepper-content>

    <v-stepper-step step="4" editable>
      Ajoutes une photo
      <small>pour que les autres outtripeurs te reconnaissent ;)</small>
    </v-stepper-step>
    <v-stepper-content step="4">
      <v-card
          color="grey lighten-1"
          class="mb-12"
          height="200px"
      ></v-card>
      <v-btn text>Passer</v-btn>
      <v-btn rounded color="primary" @click="e6 = 1">
        Continuer
      </v-btn>
    </v-stepper-content>
  </v-stepper>
  </div>
</template>

<script>
import PlaceField from "@/components/Form/PlaceField";
export default {
  components: {PlaceField},
  data () {
    return {
      e6: 1,
      formData: {
        sports: [],
        hosting: null,
        gender: null,
        relationship: null,
        userEquipementRental: [],
        place: null
      },
      sportsOptions: [
        {text: "Course à pied", value: "167937"},
        {text: "Gravel", value: "187310"},
        {text: "Kayak - Paddle", value: "169559"},
        {text: "Parapente", value: "169587"},
        {text: "Rando-Trail", value: "6"},
        {text: "Raquette", value: "169588"},
        {text: "Ski de fond", value: "202"},
        {text: "Ski de rando", value: "169589"},
        {text: "Surf", value: "169590"},
        {text: "Vélo de route", value: "37"},
        {text: "Vtt", value: "169591"},
        {text: "Via Ferrata", value: "195944"}
      ],
      genderOptions: [{label: "un homme", value: "outtripeuse"}, {label: "une femme", value: "outtripeur"}],
      canHostSomeoneOptions: [{label: "Oui", value: "1"},{label: "Non", value: "0"}],
      inRelationshipOptions: [{label: "En couple", value: "inRelationship"},{label: "Célibataire", value: "single"}],
      equipementsRentalListOptions: [{ value: 'bodyboard', label: 'BodyBoard'}, { value: 'canoeKayak', label: 'Canoë-Kayak'}, { value: 'kiteSurf', label: 'Kite Surf'}, { value: 'longboardSurf', label:'Longboard Surf'}, { value: 'sledge', label:'Luge'}, { value: 'surfBoard', label: 'Planche de surf'}, { value: 'snowshoes', label:'Raquettes à neige'}, { value: 'alpineSkiing', label:'Ski alpin'}, { value: 'crossCountrySkiing', label:'Ski de fond'}, { value: 'nordicSkiing', label: 'Ski de randonnée'}, { value: 'snowboard', label:'Snowboard'}, { value: 'standUpPaddle', label:'Stand-up Paddle'}, { value: 'roadBike', label:'Vélo de route'}, { value: 'mountainBike', label:'Vtt'}, { value: 'wakeboard', label:'Wakeboard'},],
    }
  },
}
</script>
