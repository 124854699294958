<template>
  <v-card>
      <div class="d-flex flex-column" style="height: 100%">
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-spacer></v-spacer>
          <v-btn color="secondary" icon @click="$emit('clickOnClose')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
      </div>
        <v-card-title v-html="event.title"></v-card-title>

    <v-card-subtitle class="pb-0" v-if="event.city"><v-icon>mdi-map-marker-outline</v-icon><span v-html="event.city"></span> (<span v-html="event.postcode"></span>)</v-card-subtitle>

    <v-card-text class="text--primary">
      <div><v-icon>mdi-clock-start</v-icon><span v-html="event.start_at"></span></div>
      <div v-if="event.interestedUsers > 0"><span v-html="event.interestedUsers"></span> intéressée(s)</div>
      <div v-if="event.participatingUsers > 0"><span v-html="event.participatingUsers"></span> participant(s)</div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text right link :to="{name: 'Event', params:{slug: event.slug}}">
        Plus de détails
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    event: {
      required: true
    }
  }
}
</script>

<style scoped>
.v-card__title {
  word-break: keep-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
