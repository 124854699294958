<template>
  <v-container fluid>
    <h1>Mes notifications</h1>

    <v-skeleton-loader
        type="table-heading@8"
        v-if="initialisation"
    ></v-skeleton-loader>

    <div v-else>
      <v-tabs v-model="tab" align-with-title>
        <v-tab>Activités</v-tab>
        <v-tab>&Eacute;vènements</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-simple-table class="mt-4">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Notification
                </th>
                <th class="text-left">
                  Mobile
                </th>
                <th class="text-left">
                  Email
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(notif, key) in activityNotifications" :key="key">
                <td>{{ notif.label }}</td>
                <td v-if="notificationsUser">
                  <v-switch v-model="notificationsUser[notif.id].mobile"></v-switch>
                </td>
                <td v-else>
                  <v-switch disabled loading></v-switch>
                </td>
                <td v-if="notificationsUser">
                  <v-switch v-model="notificationsUser[notif.id].email"></v-switch>
                </td>
                <td v-else>
                  <v-switch disabled loading></v-switch>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
        <v-tab-item>
          <v-simple-table class="mt-4">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Notification
                </th>
                <th class="text-left">
                  Mobile
                </th>
                <th class="text-left">
                  Email
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(notif, key) in eventNotifications" :key="key">
                <td>{{ notif.label }}</td>
                <td v-if="notificationsUser">
                  <v-switch v-model="notificationsUser[notif.id].mobile"></v-switch>
                </td>
                <td v-else>
                  <v-switch disabled loading></v-switch>
                </td>
                <td v-if="notificationsUser">
                  <v-switch v-model="notificationsUser[notif.id].email"></v-switch>
                </td>
                <td v-else>
                  <v-switch disabled loading></v-switch>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
      </v-tabs-items>

      <v-btn color="primary" block @click="saveNotifications" :loading="loading">Enregistrer</v-btn>
    </div>

    <PremiumModal ref="premiumModal"/>
    <LoginModal ref="loginModal" />
  </v-container>
</template>

<script>
import {getToken} from "firebase/messaging"
import FormData from "form-data";
import PremiumModal from "@/components/PremiumModal";
import LoginModal from "@/components/LoginModal";
import {mapState} from "vuex";

export default {
  name: "Notification",
  components: {PremiumModal, LoginModal},
  computed: {
    ...mapState('auth', ['user'])
  },
  data() {
    return {
      tab: null,
      initialisation: true,
      autorisation: null,
      key: "BBOXWcmxlcpii0wpdk8nquAWw0FAM5DKuJpmX60ixvUGGjl0rwmm23LdF7yIKaSdeUyKt3iLeyOGV28dla53nug",
      token: null,
      activityNotifications: [],
      eventNotifications: [],
      notificationsUser: null,
      loading: false
    }
  },
  mounted() {
    this.requestPermission();
    this.refreshList()
  },
  methods: {
    refreshList() {
      this.$axios.get('/notifications/list')
          .then(response => {
            response.data.map((row) => {
              if (row.category === 'Activités') {
                this.activityNotifications.push(row);
              } else {
                this.eventNotifications.push(row);
              }
            })
          })
          .then(() => this.initialisation = false)

      if (this.user) {
        this.$axios.get('/notifications/user/list').then(response => this.notificationsUser = response.data);
      }

    },
    async saveNotifications() {
      if (!this.user) {
        this.$refs.loginModal.openLoginModal();
        return;
      }

      if (!this.user.isPremium) {
        this.$refs.premiumModal.open();
        return;
      }

      this.loading = true;
      const form = new FormData()

      Object.values(this.notificationsUser).map((value, key) => {
        form.append(`userHasNotifications[${key}][id]`, value.id)
        form.append(`userHasNotifications[${key}][userId]`, value.userId)
        form.append(`userHasNotifications[${key}][notificationId]`, value.notificationId)
        form.append(`userHasNotifications[${key}][mobile]`, value.mobile)
        form.append(`userHasNotifications[${key}][email]`, value.email)
        form.append(`userHasNotifications[${key}][token]`, value.token == null ? this.token : value.token)
      })
      this.$axios.post('/notifications/save', form, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
      .then(() => this.loading = false)
    },
    tokenFCM() {
      getToken(this.$messaging, {vapidKey: this.key})
          .then((currentToken) => {
            if (currentToken) {
              this.token = currentToken;
            } else {
              console.log('No registration token available. Request permission to generate one.');
            }
          }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      })
    },
    async requestPermission() {
      this.autorisation = await Notification.requestPermission()

      if (this.autorisation === 'granted') {
        this.tokenFCM()
      }
    }
  }
}
</script>

<style scoped>

</style>
