<template>
  <v-container>
    <div v-if="!groupInit" class="text-center">
      <v-skeleton-loader class="mx-6" type="image"></v-skeleton-loader>
      <v-skeleton-loader class="mx-6" type="article"></v-skeleton-loader>
    </div>
    <div v-else style="margin-bottom: 94px">
      <h1>{{ entry.title }}</h1>
      <div class="d-flex justify-center py-2">
        <cld-image :public-id="entry.logo[0].url" class="ml-2" loading="lazy" type="fetch">
          <cld-transformation crop="pad" quality="auto:eco" width="250"></cld-transformation>
        </cld-image>
      </div>

      <div>
        <v-tabs v-model="tab" centered>
          <v-tab>Détails</v-tab>
          <v-tab v-if="entry.hasPointsOfInterest" @change="initPointsOfInterest">Points d'intérêt</v-tab>
          <v-tab v-if="entry.hasActivites" @change="initActivity">Activités</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="tabs">
          <v-tab-item>
            <div v-html="entry.description"></div>
            Site internet : <a :href="entry.website" target="_blank">{{ entry.website }}</a>
          </v-tab-item>
          <v-tab-item v-if="entry.hasPointsOfInterest">
            <v-list>
              <v-list-item-group
                  color="primary"
              >
                <v-list-item
                    v-for="(item, i) in pointsOfInterest"
                    :key="i"
                    :to="{ name: 'PointOfInterest', params: { id: item.id }}"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                    <v-list-item-subtitle>{{ item.outtripAltitude }}m {{ item.mountainMassif }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="pointsOfInterest && pointsOfInterest.length > 0" v-intersect="loadMorePointOfInterest"></v-list-item>
              </v-list-item-group>
            </v-list>
          </v-tab-item>
          <v-tab-item v-if="entry.hasActivites" :eager="true">
            <GroupOuttripList ref="groupOuttripList" :group="entry"></GroupOuttripList>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </v-container>
</template>

<script>
import PointsOfInterestGql from '@/gql/points-of-interest.gql'
import GroupGql from '@/gql/group.gql'
import GroupOuttripList from "@/components/Group/OuttripList/GroupOuttripList.vue";

export default {
  name: "PointOfInterest",
  components: {GroupOuttripList},
  data: () => ({
    tab: null,
    entry: null,
    pointsOfInterest: null,
    outtrips: null,
    offset: 1,
    fetchMorePointOfInterest: null,
    loading: false,
    groupInit: false
  }),
  mounted() {
    this.$cms.post('', {
      query: GroupGql.loc.source.body,
      variables: {
        id: this.$route.params.id
      }
    }).then((response) => {
      this.entry = response.data.data.entry;
      this.groupInit = true
    })
  },
  methods: {
    initPointsOfInterest() {
      this.pointsOfInterest = [];
      this.loadMorePointOfInterest();
    },
    initActivity() {
      this.$refs.groupOuttripList.init();
    },
    loadMorePointOfInterest() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$cms.post('', {
        query: PointsOfInterestGql.loc.source.body,
        variables: {
          groupId: this.entry.id,
          offset: this.offset,
        }
      }).then((response) => {
        this.pointsOfInterest = this.pointsOfInterest.concat(response.data.data.entries);
        this.offset += response.data.data.entries.length
        this.loading = false
      })
    },
  }
}
</script>
