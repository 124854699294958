<template>
  <v-container fluid>
    <h1>Editer un outtrip</h1>
    <OuttripForm is-edit/>
  </v-container>
</template>

<script>
import OuttripForm from "../components/Outtrip/OuttripForm";

export default {
  name: "OuttripEdit",
  components: {OuttripForm},
  mounted() {
    this.$axios.get('/user/me').then((meResponse) => {
      this.$store.commit('auth/user', meResponse.data)
    })
  }
}
</script>

<style scoped>

</style>
