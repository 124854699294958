<template>
  <div>
    <v-list three-line>
      <template v-for="(user, index) in users">

        <v-divider v-if="index !== 0" :key="index" inset/>

        <v-list-item :key="`${name}_${user.id}`" @click="$emit('click', user)">
          <v-list-item-avatar style="width: 50px; height: 50px">
            <OuttriperPhoto :outtriper="user" :diameter=40 />
          </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="text-wrap" style="cursor: pointer">
            <slot name="beforeTitle" v-bind:user="user"></slot> {{ user.username }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-wrap" style="cursor: pointer">
            <span v-if="user.userLocation.parts.city">
              <v-icon color="primary">mdi-map-marker</v-icon> <span class="small">{{ user.userLocation.parts.city }}</span>
            </span>
            <span v-if="user.sports">
             <SportList :sports="user.sports" :max="2" :padding-left="0" small/>
            </span>
            </v-list-item-subtitle>

          </v-list-item-content>
          <v-list-item-action>
            <v-btn color="grey lighten-1" icon @click="onReport(user.id)">
              <v-icon>mdi-alert-circle</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>

    <v-dialog v-model="dialogSignalement" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Signalement de l'utilisateur
        </v-card-title>

        <div class="px-6">
          <p>Décrivez votre problème avec cet utilisateur</p>
          <v-textarea outlined rounded v-model="message"/>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialogSignalement = false">
            Annuler
          </v-btn>

          <v-btn color="green darken-1" text @click="saveReport()">
            Envoyer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbarSuccess" timeout="2000" color="success" left style="z-index: 1001">
      {{ snackbarText }}
    </v-snackbar>
    <v-snackbar v-model="snackbarError" timeout="2000" color="error" style="z-index: 1001" left>
      {{ snackbarText }}
    </v-snackbar>
  </div>

</template>

<script>
import SportList from "@/components/Sport/SportList";
import OuttriperPhoto from "@/components/Outtriper/OuttriperPhoto";

export default {
  name: "OuttriperList",
  components: {SportList, OuttriperPhoto},
  props: {
    name: {
      required: true
    },
    users: {
      required: true
    }
  },
  data() {
    return {
      snackbarSuccess: false,
      snackbarError: false,
      snackbarText: null,
      dialogSignalement: false,
      message: null,
      reportId: null
    }
  },
  methods: {
    redirect(user) {
      this.$router.push({name: "Outtrippeur", params: {user: user}})
    },
    onReport(id) {
      this.dialogSignalement = true
      this.reportId = id
    },
    saveReport() {
      this.$axios.post('/reporting/save',{
        "subjectId": parseInt(this.reportId),
        "subject": this.name,
        "message": this.message
      }).then(response => {
        this.dialogSignalement = false
        this.snackbarSuccess = true
        this.snackbarText = response.data.message
      }).catch(error => {
        this.snackbarError = true
        this.snackbarText = error.data
      })
    }
  },
  filters: {
    firstLetter(name) {
      return name.charAt(0).toUpperCase()
    }
  }
}
</script>

<style scoped>

</style>
