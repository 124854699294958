<template>
  <div class="scroll" v-if="scrollShow">
    <v-btn fab color="primary" small @click="scrollTo">
      <v-icon>
        mdi-chevron-up
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "ButtScrollTop",
  data() {
    return {
      scrollShow: false
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollShow = window.scrollY > 50
    },
    scrollTo() {
      window.scrollTo({top: 0, behavior: 'smooth'})
    }
  }
}
</script>

<style scoped>
.scroll {
  z-index: 1000;
  position: fixed;
  bottom: 85px;
  right: 0;
  margin-right: 1rem;
}
</style>
