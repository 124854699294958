<template>
  <div class="expand-text">
    <div v-html="text" class="px-6 text-left"></div>
    <div class="text-center">
      <v-btn color="primary" outlined class="my-3 mx-6" v-if="!btnToggle" @click="toggle">
        <v-icon>mdi-chevron-down</v-icon>
        en savoir plus
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonMoreInfo",
  props: {
    entryText: {
      required: true
    },
    length: {
      required: true
    }
  },
  data: () => ({
    btnToggle: false,
    text: null,
  }),
  mounted() {
    this.cutString(this.entryText, this.length)
  },
  methods: {
    cutString(responseFull, length) {
      let l = responseFull.length

      let cutat = responseFull.lastIndexOf(' ', length);
      if (cutat !== -1 && l > length) this.text = responseFull.substring(0, cutat) + ' ...';
      else {
        this.text = responseFull
        this.btnToggle = true
      }
    },
    toggle() {
      this.text = this.entryText
      this.btnToggle = true
    }
  }
}
</script>

<style scoped>

</style>
