<template>
  <div class="pa-6">
    <h1>Participant</h1>
    <v-skeleton-loader
        type="card-avatar, article, actions"
        v-if="loading"
    ></v-skeleton-loader>
    <div v-else>
      <Author :entry="user"/>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Status</v-list-item-title>
          <v-list-item-subtitle>
            <v-chip color="success" dark v-if="participant.status === 'accepted'">Acceptée</v-chip>
            <v-chip color="orange" dark v-else-if="participant.status === 'awaiting_for_acceptance'">
              En attente
            </v-chip>
            <v-chip color="error" dark v-else-if="participant.status === 'refused'">Refusée</v-chip>
            <v-chip color="grey" dark v-else-if="participant.status === 'cancel'">Annulée</v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-row class="pa-3" align="center" justify="space-around" v-if="participant.status === 'awaiting_for_acceptance'">
        <v-btn color="error" class="mr-1" :loading="loadingButton" @click="setStatusUser($route.params.eventId, user.id,'refused')">
          <v-icon>mdi-close</v-icon>
          Refuser
        </v-btn>
        <v-btn color="success" class="ml-1" :loading="loadingButton" @click="setStatusUser($route.params.eventId, user.id,'accepted')">
          <v-icon>mdi-check</v-icon>
          Accepter
        </v-btn>
      </v-row>
      <v-row align="center" justify="space-around" class="pa-3" v-else-if="participant.status === 'accepted'">
        <v-btn color="error" class="ml-1" :loading="loadingButton" @click="setStatusUser($route.params.eventId, user.id,'cancel')">
          <v-icon>mdi-check</v-icon>
          Annuler
        </v-btn>
      </v-row>
      <v-row align="center" justify="space-around">
        <v-btn class="pa-3" :loading="loadingButton" @click="onReport($route.params.eventId, user.id)" >
          <v-icon>mdi-alert-circle</v-icon>
          Signaler
        </v-btn>
      </v-row>
    </div>

    <v-snackbar v-model="snackbarSuccess" timeout="2000" color="success" left style="z-index: 1001">
      {{ snackbarText }}
    </v-snackbar>
    <v-snackbar v-model="snackbarError" timeout="2000" color="error" style="z-index: 1001" left>
      {{ snackbarText }}
    </v-snackbar>

    <v-dialog v-model="dialogSignalement" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Signalement de l'utilisateur
        </v-card-title>

        <div class="px-6">
          <p>Décrivez votre problème avec cet utilisateur</p>
          <v-textarea outlined rounded v-model="message"/>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialogSignalement = false" :loading="loadingButton">
            Annuler
          </v-btn>

          <v-btn color="green darken-1" text @click="saveReport()" :loading="loadingButton">
            Envoyer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import Author from "@/components/Author";

export default {
  name: "MyActivitiesParticipantsUser",
  components: {Author},
  data() {
    return {
      user: null,
      participant: null,
      loading: true,
      loadingButton: false,
      dialogSignalement: false,
      snackbarError: false,
      snackbarSuccess: false,
      snackbarText: '',
    }
  },
  mounted() {
    this.$axios.get(`/user/${this.$route.params.username}`)
        .then(response => response.data)
        .then(response => this.user = response.user)
        .then(() => {
          this.$axios.get(`/event/${this.$route.params.eventId}/participed/${this.user.id}`)
              .then(response => this.participant = response.data)
              .then(() => this.loading = false)
        })
  },
  methods: {
    onReport(activiteId, userId) {
      this.dialogSignalement = true
      this.activiteId = activiteId
      this.reportId = userId
    },
    setStatusUser(eventID, userID, status) {
      this.loadingButton = true
      this.$axios.post(`event/participed/user/save`, {
        "user": userID,
        "event": eventID,
        "status": status
      }).then(response => {
        this.snackbarSuccess = true
        this.snackbarText = response.data.message
        this.participant.status = status;
      }).catch(error => {
        this.snackbarError = true
        this.snackbarText = error.message
      })
      .then(() => this.loadingButton = false)
    },
    saveReport() {
      this.loadingButton = true;
      this.$axios.post('/reporting/save',{
        "subjectId": parseInt(this.reportId),
        "subject": this.user.username,
        "message": this.message
      }).then(response => {
        this.dialogSignalement = false
        this.snackbarSuccess = true
        this.snackbarText = response.data.message
      }).catch(error => {
        this.snackbarError = true
        this.snackbarText = error.data
      })
      .then(() => this.loadingButton = false)
    }
  }
}
</script>

<style lang="scss" scoped>
.listUser {
  display: flex;
  align-items: center;
  padding: 7px 0;

  .listUserName {
    margin-left: 10px;
  }
}
</style>
