<template>
  <div>
    <LoginForm @success="redirect"/>
  </div>
</template>

<script>
import LoginForm from "../components/LoginForm";

export default {
  name: "Login",
  components: {LoginForm},
  methods: {
    redirect() {
      this.$router.push({name: 'Profil'})
    }
  }
}
</script>

<style scoped>

</style>
