<template>
  <v-app>

    <v-main style="margin-bottom: 56px;">
      <v-dialog v-model="hashChanged" max-width="500" style="z-index: 1010" persistent>
        <v-card class="text-center py-8">
          <h4>Une nouvelle version de l'application est disponible !</h4>
          <v-btn color="primary" @click="reloadApp" class="mt-3">Rafraichir</v-btn>
        </v-card>
      </v-dialog>

      <v-dialog
          v-model="showPremiumDialog"
          width="500"
          style="z-index: 1010"
      >
        <v-card>
          <v-card-title class="text-h5 primary">
            Outtrip Premium !
          </v-card-title>
          <v-card-text>
            Pour vous offrir la meilleure expérience qu'il soit, Outtrip restera entièrement gratuit pendant les prochains mois.<br/>
            Connecte-toi pour profiter de toute l'application !
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="showPremiumDialogClick()"
            >
              OK !
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <router-view></router-view>
      <ButtScrollTop/>
    </v-main>
    <div style="position: fixed; bottom: 0; left: 50%; transform: translate(-50%, -50%); z-index:1001;">
      <v-speed-dial v-model="fab">
        <template v-slot:activator>
          <v-btn v-model="fab" color="primary" fab>
            <v-icon v-if="fab">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        <v-btn small color="primary" to="/creer-un-evenement">
          Créer un évènement
        </v-btn>
        <v-btn small color="primary" to="/creer-une-activite">
          Créer une activité
        </v-btn>
      </v-speed-dial>
    </div>
    <v-bottom-navigation :value="value" color="primary" fixed grow style="z-index: 1000;">
      <v-btn exact-path :to="{ name: 'Home'}">
        <span>Outtrip</span>
        <v-icon>mdi-earth</v-icon>
      </v-btn>
      <v-btn exact-path :to="{ name: 'MyFavoris'}">
        <span>Favoris</span>
        <v-icon>mdi-star</v-icon>
      </v-btn>
      <div class="v-btn"></div>
      <v-btn exact-path :to="{ name: 'Messaging'}">
        <span>Messagerie</span>
        <v-icon>mdi-chat</v-icon>
      </v-btn>
      <v-btn icon rounded :to="{ name: !user ? 'Login' : 'Profil'}">
        <span>Compte</span>
        <div v-if="!user">
          <v-icon>mdi-account-lock</v-icon>
        </div>
        <OuttriperPhoto :outtriper="user" :diameter="24" color="white" text-color="primary" v-else/>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import EventBus from "./services/eventBus";
import ButtScrollTop from "@/components/ButtScrollTop";
import {refreshPageMixin} from '@/components/mixins/refreshPageMixin';
import OuttriperPhoto from "./components/Outtriper/OuttriperPhoto";
import {mapState} from "vuex";

export default {
  name: 'App',
  components: {ButtScrollTop, OuttriperPhoto},
  data: () => ({
    value: 0,
    fab: false,
    showPremiumDialog: false
  }),
  metaInfo: {
    meta: [
      {name:"viewport", content:"initial-scale=1, viewport-fit=cover, width=device-width"}
    ],
    script: [
      {src: 'https://static.cloudflareinsights.com/beacon.min.js', async: false, defer: false, 'data-cf-beacon': "{\"token\": \"7bb5f34f47cf41bc8bcb2d4549c436e2\"}"},
      {src: 'https://fsx.i-run.fr/', async: false, defer: false}
    ]
  },
  mixins: [refreshPageMixin],
  mounted() {
    this.initVersionCheck('../version.json');
    this.toolbarBack = this.$route.meta.back
    EventBus.on("logout", () => {
      this.logOut();
    })

    let showPremiumDialog = localStorage.getItem("showPremiumDialogConfirm");
    console.log(showPremiumDialog);
    if (showPremiumDialog !== '1') {
      this.showPremiumDialog = true;
    }
  },
  watch: {
    '$route'(to) {
      this.toolbarBack = to.meta.back || false
    }
  },
  methods: {
    backHistory() {
      this.$router.go(-1)
    },
    logOut() {
      this.$store.dispatch('auth/logout')
      this.$router.push('/')
    },
    showPremiumDialogClick() {
      localStorage.setItem("showPremiumDialogConfirm", '1');
      this.showPremiumDialog = false;
    },
  },
  beforeDestroy() {
    EventBus.remove("logout")
  },
  computed: {
    ...mapState("auth", ["user"])
  }
};
</script>

<style lang="scss">
@font-face {
  font-family: 'jost';
  src: url('./fonts/jost/light.woff2') format('woff2'),
  url('./fonts/jost/light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'jost';
  src: url('./fonts/jost/book.woff2') format('woff2'),
  url('./fonts/jost/book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'jost';
  src: url('./fonts/jost/bold.woff2') format('woff2'),
  url('./fonts/jost/bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@import "~@/sass/app.scss";
</style>
