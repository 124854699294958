import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CreateEvent from "../views/CreateEvent";
import CreateActivity from "../views/CreateActivity";
import Messaging from "../views/Messaging";
import MyFavoris from "../views/MyFavoris";
import Notification from "../views/Notification";
import Outtrip from "../views/Outtrip";
import Outtrippeur from "../views/Outtrippeur";
import Login from "../views/Login";
import Profil from "../views/Profil";
import Logout from "../views/Logout";
import OuttripEdit from "../views/OuttripEdit";
import Event from "../views/Event";
import OnBoarding from "../views/OnBoarding";
import OuttripersArround from "../views/OuttripersArround";
import RemoveAccount from "../views/RemoveAccount";
import MyInformations from "../views/MyInformations";
import MyOuttrips from "../views/MyOuttrips";
import MyActivities from "../views/MyActivities";
import Register from "../views/Register";
import RegisterSuccess from "../views/RegisterSuccess";
import Premium from "../views/Premium";
import PremiumSuccess from "../views/PremiumSuccess";
import PremiumCancel from "../views/PremiumCancel";
import UserVerificationRequest from "../views/UserVerificationRequest";
import ResetPassword from "../views/ResetPassword";
import PasswordForgotten from "../views/PasswordForgotten";
import EventEdit from "@/views/EventEdit";
import MyActivitiesParticipants from "@/views/MyActivitiesParticipants";
import MyActivitiesParticipantsUser from "@/views/MyActivitiesParticipantsUser";
import NewActivities from "@/views/NewActivities";
import NewEvents from "@/views/NewEvents";
import MessagingChat from "@/views/MessagingChat";
import PointOfInterest from "@/views/PointOfInterest";
import Group from "@/views/Group";
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/creer-un-evenement',
        name: 'CreateEvent',
        component: CreateEvent,
        meta: {
            back: true
        }
    },
    {
        path: '/creer-une-activite',
        name: 'CreateActivity',
        component: CreateActivity,
        meta: {
            back: true
        }
    },
    {
        path: '/messagerie',
        name: 'Messaging',
        component: Messaging,
        meta: {
            back: true
        }
    },
    {
        path: '/messagerie-chat',
        name: 'MessagingChat',
        component: MessagingChat,
        meta: {
            back: true
        }
    },
    {
        path: '/mes-favoris',
        name: 'MyFavoris',
        component: MyFavoris,
        meta: {
            back: true
        }
    },
    {
        path: '/outtrip/:slug',
        name: 'Outtrip',
        component: Outtrip,
        meta: {
            back: true
        }
    },
    {
        path: '/point-of-interest/:id',
        name: 'PointOfInterest',
        component: PointOfInterest,
        meta: {
            back: true
        }
    },
    {
        path: '/group/:id',
        name: 'Group',
        component: Group,
        meta: {
            back: true
        }
    },
    {
        path: '/outtripeurs/:user',
        name: 'Outtrippeur',
        component: Outtrippeur,
        meta: {
            back: true,
        }
    },
    {
        path: '/se-connecter',
        name: 'Login',
        component: Login,
        meta: {
            back: true
        }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        meta: {
            back: true
        }
    },
    {
        path: '/mon-compte',
        name: 'Profil',
        component: Profil
    },
    {
        path: '/mon-compte/mes-outtrips/:id/editer',
        name: 'OuttripEdit',
        component: OuttripEdit,
        meta: {
            back: true
        }
    },
    {
        path: '/mon-compte/mes-evenements/:id/editer',
        name: 'EventEdit',
        component: EventEdit,
        meta: {
            back: true
        }
    },
    {
        path: '/notification',
        name: 'Notification',
        component: Notification,
        meta: {
            back: true
        }
    },
    {
        path: '/event/:slug',
        name: 'Event',
        component: Event,
        meta: {
            back: true
        }
    },
    {
        path: '/on-boarding',
        name: 'OnBoarding',
        component: OnBoarding,
    },
    {
        path: '/outtripeurs_arround/:slug',
        name: 'OuttrippeurArround',
        component: OuttripersArround,
        meta: {
            back: true,
        }
    },
    {
        path: '/remove-account',
        name: 'RemoveAccount',
        component: RemoveAccount,
        meta: {
            back: true,
        }
    },
    {
        path: '/my-informations',
        name: 'MyInformations',
        component: MyInformations,
        meta: {
            back: true,
        }
    },
    {
        path: '/my-outtrips',
        name: 'MyOuttrips',
        component: MyOuttrips,
        meta: {
            back: true,
        }
    },
    {
        path: '/my-activities',
        name: 'MyActivities',
        component: MyActivities,
        meta: {
            back: true,
        }
    },
    {
        path: '/my-activities/:id/participants',
        name: 'MyActivitiesParticipants',
        component: MyActivitiesParticipants,
        meta: {
            back: true,
        }
    },
    {
        path: '/my-activities/:eventId/participants/:username',
        name: 'MyActivitiesParticipantsUser',
        component: MyActivitiesParticipantsUser,
        meta: {
            back: true,
        }
    },
    {
        path: '/s-inscrire',
        name: 'Register',
        component: Register,
        meta: {
            back: true,
        }

    },
    {
        path: '/s-inscrire/success',
        name: 'RegisterSuccess',
        component: RegisterSuccess
    },
    {
        path: '/premium',
        name: 'Premium',
        component: Premium,
        meta: {
            back: true,
        }
    },
    {
        path: '/premium/success',
        name: 'PremiumSuccess',
        component: PremiumSuccess,
    },
    {
        path: '/premium/cancel',
        name: 'PremiumCancel',
        component: PremiumCancel,
    },
    {
        path: '/user-verification-request',
        name: 'UserVerificationRequest',
        component: UserVerificationRequest,
        meta: {
            back: true,
        }
    },
    {
        path: '/changer-mon-mot-de-passe/:id',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            back: true,
        }
    },
    {
        path: '/mot-de-passe-oublie',
        name: 'PasswordForgotten',
        component: PasswordForgotten,
    },
    {
        path: '/new-activities',
        name: 'NewActivities',
        component: NewActivities,
    },
    {
        path: '/new-events',
        name: 'NewEvents',
        component: NewEvents,
    }
]

const router = new VueRouter({
    routes
})

export default router
