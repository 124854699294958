<template>
  <div>
    <ul class="sports" :style="paddingLeft && paddingLeft === 0 ? 'padding-left: 0 !important;' : `padding-left: ${paddingLeft}px !important;`">
      <li :class="small ? 'small': ''" v-for="sport in sports.slice(0,max)" :key="sport.slug">#{{ sport.title }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SportList",
  props: {
    sports: {
      required: true
    },
    max: {
      type: Number
    },
    paddingLeft: {
      type: Number,
      default: 24
    },
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
