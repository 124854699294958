<template>
  <div>
  <AdBanner/>
  <div v-if="user.length <= 0" class="text-center">
    <v-skeleton-loader class="mx-6" type="image"></v-skeleton-loader>
    <br>
    <v-skeleton-loader class="mx-6" type="card, article"></v-skeleton-loader>
  </div>
    <div v-else style="margin-bottom: 94px">
      <div class="avatar my-2">
        <OuttriperPhoto :outtriper="user" :diameter="150"/>
        <div class="username">{{ user.name }}</div>
      </div>
      <v-card color="primary" class="ma-3 pa-2 white--text">
        <ul class="listUserInfoInline text-center">
          <li>Code parrain : TRIPPEUR-{{ user.id }}</li>
          <li v-if="user.age">{{ user.age }} ans</li>
          <li v-if="user.userLocation.parts.city">{{ user.userLocation.parts.city }}</li>
        </ul>

        <ul class="listInline text-center mt-2">
          <li v-for="sport in user.sports" :key="sport.slug">
            <v-icon color="white" small>{{ sport.materialIcon }}</v-icon>
            {{ sport.title }}
          </li>
        </ul>
      </v-card>

      <v-tabs v-model="tab" centered>
        <v-tab>A propos</v-tab>
        <v-tab @change="loadOuttrips">Outtrips</v-tab>
        <v-tab @change="loadPhotos">Photos</v-tab>
        <v-tab @change="loadArticles">Articles</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="tabs pa-6">
        <v-tab-item>
            <span>{{ user.userAbout }}</span>
            <h5 class="mt-4">Ses Infos</h5>

            <ul class="infosPersos">
              <li v-if="user.userLocation && user.userLocation.parts.city">
                <span>{{ user.userLocation.parts.city }}, {{ user.userLocation.parts.county }}</span>
                <em>Adresse</em>
                <div class="separator"></div>
              </li>
              <li v-if="user.relationshipStatus">
                <span>{{ user.relationshipStatus }}</span>
                <em>Situation amoureuse</em>
                <div class="separator"></div>
              </li>
              <li v-if="user.canHostSomeone > 0">
                <span>{{ user.howManyHostMaximum }} personne(s)</span>
                <em>Possibilité d'hébergement</em>
                <div class="separator"></div>
              </li>
              <li v-if="user.equipmentRental.length > 0">
                <span>{{ user.equipmentRental.join(', ') }} en contrepartie de {{ user.equipmentRentalPrice }}</span>
                <em>Je peux louer</em>
                <div class="separator"></div>
              </li>
              <li v-if="user.userWebsite">
                <span><a :href="user.userWebsite" target="_blank">{{ user.userWebsite }}</a></span>
                <em>Site internet</em>
                <div class="separator"></div>
              </li>
              <li v-if="user.userPhoneNumber">
                <span><a :href="user.userPhoneNumber">{{ user.userPhoneNumber }}</a></span>
                <em>Numéro de téléphone</em>
                <div class="separator"></div>
              </li>
            </ul>

            <h5 class="mt-4">Ses réseaux sociaux</h5>

            <ul class="infosPersosReseaux">
              <li v-if="user.facebook">
                <v-icon color="primary" large>mdi-facebook</v-icon>
                <span><a :href="`https://www.facebook.com/${user.facebook}`">{{ user.facebook }}</a></span>
                <em>Facebook</em>
                <div class="separator"></div>
              </li>
              <li v-if="user.instagram">
                <v-icon color="primary" large>mdi-instagram</v-icon>
                <span><a :href="`https://www.instagram.com/${user.instagram}`">{{ user.instagram }}</a></span>
                <em>Instagram</em>
                <div class="separator"></div>
              </li>
              <li v-if="user.twitter">
                <v-icon color="primary" large>mdi-twitter</v-icon>
                <span><a :href="`https://www.twitter.com/${user.twitter}`">{{ user.twitter }}</a></span>
                <em>Twitter</em>
                <div class="separator"></div>
              </li>
              <li v-if="user.linkedin">
                <v-icon color="primary" large>mdi-linkedin</v-icon>
                <span><a :href="`https://www.linkedin.com/${user.linkedin}`">{{ user.linkedin }}</a></span>
                <em>Linkedin</em>
                <div class="separator"></div>
              </li>
            </ul>
        </v-tab-item>
        <v-tab-item>
          <v-skeleton-loader
              type="list-item-avatar-two-line@3"
              v-if="outtrips === null"
          ></v-skeleton-loader>
            <OuttripeurOuttripList v-else :outtrips="outtrips"/>
        </v-tab-item>
        <v-tab-item>
          <v-row v-if="photos === null" class="text-center">
            <v-col col="4">
              <v-skeleton-loader
                  type="text"
              ></v-skeleton-loader>
            </v-col>
            <v-col col="4">
              <v-skeleton-loader
                  type="text"
              ></v-skeleton-loader>
            </v-col>
            <v-col col="4">
              <v-skeleton-loader
                  type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-alert type="info" outlined v-else-if="photos.assets.length <= 0">
            Cet utilisateur n'a aucune photo.
          </v-alert>
          <PhotosGallery v-else :photos="photos.assets" />
        </v-tab-item>
        <v-tab-item>
          <v-skeleton-loader
              type="list-item-avatar-two-line@3"
              v-if="articles === null"
          ></v-skeleton-loader>
          <v-alert type="info" outlined v-else-if="articles.length <= 0">
            Cet utilisateur n'a aucun article.
          </v-alert>
          <OuttripeurArticleList v-else :articles="articles"/>
        </v-tab-item>

      </v-tabs-items>

      <div class="d-flex justify-center my-6" style="z-index: 1000; position: fixed; bottom: 56px; margin-left:1rem;">
        <v-speed-dial v-model="fab" direction="right" style="z-index: 1000;">
          <template v-slot:activator>
            <v-btn v-model="fab" color="primary" fab>
              <v-icon v-if="fab">
                mdi-close
              </v-icon>
              <v-icon v-else>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="secondary" small fab v-bind="attrs" @click="goToMessagerie">
                <v-icon color="white">mdi-chat-processing</v-icon>
              </v-btn>
            </template>
            <span>Messagerie</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="warning" small fab v-bind="attrs" v-on="on" @click="dialogSignalement = true">
                <v-icon color="white">mdi-alert-circle</v-icon>
              </v-btn>
            </template>
            <span>Signaler l'utilisateur</span>
          </v-tooltip>
        </v-speed-dial>
      </div>

      <v-dialog v-model="dialogSignalement" max-width="500">
        <v-card>
          <v-card-title class="text-h5">
            Signalement de l'utilisateur
          </v-card-title>

          <div class="px-6">
            <p>Décrivez votre problème avec cet utilisateur</p>
            <v-textarea outlined rounded v-model="message"/>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="dialogSignalement = false">
              Annuler
            </v-btn>

            <v-btn color="green darken-1" text @click="saveReport(user.id)">
              Envoyer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <PremiumModal ref="premiumModal"/>

      <v-snackbar v-model="snackbarSuccess" timeout="2000" color="success" left style="z-index: 1001">
        {{ snackbarText }}
      </v-snackbar>
      <v-snackbar v-model="snackbarError" timeout="2000" color="error" style="z-index: 1001" left>
        {{ snackbarText }}
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import OuttripeurArticleList from "../components/Outtriper/OuttripeurArticleList";
import OuttriperPhoto from "../components/Outtriper/OuttriperPhoto";
import OuttripeurOuttripList from "../components/Outtriper/OuttripeurOuttripList";
import PremiumModal from "../components/PremiumModal";
import PhotosGallery from '../components/PhotosGallery'
import AdBanner from "@/views/AdBanner";

export default {
  name: "Outtrippeur",
  components: {PremiumModal, OuttripeurOuttripList, OuttriperPhoto, OuttripeurArticleList, PhotosGallery, AdBanner},
  data() {
    return {
      user: [],
      tab: null,
      fab: true,
      snackbarSuccess: false,
      snackbarError: false,
      snackbarText: null,
      dialogSignalement: false,
      message: null,
      photos: null,
      articles: null,
      outtrips: null,
    }
  },
  mounted() {
    this.$axios.get(`/user/${this.$route.params.user}`)
        .then(response => response.data)
        .then(response => this.user = response.user)
  },
  methods:{
    loadPhotos () {
      if (this.photos === null) {
        this.$axios.get('/user/' + this.user.id + '/medias/4')
            .then(response => this.photos = response.data);
      }
    },
    loadArticles () {
      if (this.articles === null) {
        this.$axios.get(`/user/${this.user.id}/articles`).then(response => response.data)
            .then(response => this.articles = response.entries)
      }
    },
    loadOuttrips () {
      if (this.outtrips === null) {
        this.$axios.get('/outtrips-details', {params: {userId: this.user.id}}).then(response => response.data)
            .then(response => this.outtrips = response)
      }
    },
    goToMessagerie(){
      this.isLoading = true;
      this.$axios.get(`/messaging/ask-authorization`, {
        params: {
          q: 'direct/'+this.user.username,
        }
      })
          .then(response => {
            const data = response.data;
            if (!data.authorized) {
              if (data.reason === 'not_premium' || data.reason === 'not_login') {
                this.$refs.premiumModal.open()
                return;
              }

            }
            this.$router.push({name: 'MessagingChat', query: {q: 'direct/'+this.user.username+'?layout=embedded'}})
          })
          .then(() => this.isLoading = false)
    },
    saveReport(subjectId) {
      this.$axios.post('/reporting/save',{
        "subjectId": parseInt(subjectId),
        "subject": "user",
        "message": this.message
      }).then(response => {
        this.dialogSignalement = false
        this.snackbarSuccess = true
        this.snackbarText = response.data.message
      }).catch(error => {
        this.snackbarError = true
        this.snackbarText = error.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.avatar {
  text-align: center;

  .username {
    margin-left: 1rem;
    font-size: 36px;
    line-height: 52px;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: bold;
  }
}
</style>
