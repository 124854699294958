export default {
  namespaced: true,
  state: () => ({
    zoom: 12,
    center: {
      lat: null,
      lng: null
    },
  }),
  mutations: {
    zoom (state, value) {
      state.zoom = value;
    },
    center (state, value) {
      state.center = value;
    },
  }
}
