<template>
  <v-row class="text-center">
    <v-col cols="4" v-for="(photo, index) in photos" :key="photo.id" @click="() => showImg(index)">
      <cld-image :public-id="photo.url" loading="lazy" type="fetch">
        <cld-transformation height="100" width="100" quality="auto:eco" crop="pad"></cld-transformation>
      </cld-image>
    </v-col>
    <vue-easy-lightbox
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
    ></vue-easy-lightbox>
  </v-row>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: {
    VueEasyLightbox
  },
  computed: {
    imgs: function () {
      return this.photos.map((row) => {
        return {src: row.url}
      })
    }
  },
  data: () => ({
    visible: false,
    index: 0
  }),
  methods: {
    showImg(index) {
      this.index = index
      this.visible = true
    },
    handleHide() {
      this.visible = false
    }
  },
  props: {
    photos: {
      type: Array,
      required: true
    }
  }
}
</script>