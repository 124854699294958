<template>
    <div>
        <carousel :autoplay="true" :loop="true" :items="2" :margin="20" class="mx-3" :nav="false" :dots="false">
            <div v-for="(o, index) in photos" :key="index" @click="() => showImg(index)">
                <cld-image :public-id="o.url" loading="lazy" type="fetch">
                    <cld-transformation height="250" width="250" quality="auto:eco" crop="pad"></cld-transformation>
                </cld-image>
            </div>
        </carousel>
        <vue-easy-lightbox
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
        ></vue-easy-lightbox>
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel2'
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: {
    carousel,
    VueEasyLightbox
  },
  computed: {
    imgs: function () {
      return this.photos.map((row) => {
        return {src: row.url}
      })
    }
  },
  data: () => ({
    visible: false,
    index: 0
  }),
  methods: {
    showImg(index) {
      this.index = index
      this.visible = true
    },
    handleHide() {
      this.visible = false
    }
  },
  props: {
    photos: {
      type: Array,
      required: true
    }
  }
}

</script>
