<template>
  <div v-if="user && entry.entry.gpxTrack && entry.entry.gpxTrack[0]">
    <l-map style="height: 350px; z-index: 0" :zoom="gpxTrack.zoom" :center="center" v-if="gpxTrack" :options="mapOptions">
      <l-tile-layer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"></l-tile-layer>
      <l-polyline :lat-lngs="polyline.latlngs" :color="polyline.color"></l-polyline>
      <l-marker :lat-lng="start">
        <l-icon icon-url="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png"/>
      </l-marker>
      <l-marker :lat-lng="end">
        <l-icon icon-url="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png"/>
      </l-marker>
    </l-map>

    <div class="text-center my-2">
      <DownloadGPX :outtrip="entry.entry"/>
    </div>
  </div>
  <div v-else>
    <v-alert dense outlined icon="mdi-lock-outline" color="primary" prominent>
      Tu dois être connecté pour pouvoir visualiser et télécharger la carte. <v-btn @click="$emit('needLogin')" text class="font-weight-bold">se connecter</v-btn>
    </v-alert>
  </div>
</template>

<script>
import {LMap, LPolyline, LTileLayer, LMarker, LIcon} from "vue2-leaflet";
import DownloadGPX from "@/components/DownloadGPX";
import {mapState} from "vuex";

export default {
  name: "OuttripMapGPX",
  props: {
    entry: {
      required: true
    }
  },
  data() {
    return {
      gpxTrack: null,
      polyline: null,
      center: null,
      mapOptions: {
        zoomControl: false,
      },
      points: [],
      start: null,
      end: null
    }
  },
  components: {
    LMap,
    LTileLayer,
    LPolyline,
    LMarker,
    LIcon,
    DownloadGPX
  },
  computed: {
    ...mapState("auth", ['user'])
  },
  mounted() {
    if (this.user) {
      this.getGpx()
    }
  },
  methods: {
    getGpx() {
      if (this.entry.entry.gpxTrack)
        this.$axios.get('/gps-track', {
          params: {filepath: this.entry.entry.gpxTrack[0].path}
        }).then(response => {
          this.gpxTrack = response.data
          this.center = response.data.center.reverse()
          response.data.points.map(point => {
            this.points.push(point.reverse())
          })
          this.start = response.data.points[0]
          this.end = response.data.points[response.data.points.length -1]
          this.polyline = {
            latlngs: this.points,
            color: 'red'
          }
        })
    }
  },
  watch: {
    user () {
      if (this.user) {
        this.getGpx()
      }
    }
  }
}
</script>

<style scoped>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>
