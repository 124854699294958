<template>
  <div>
    <v-card flat>
      <v-img class="white--text align-center" height="300" src="https://www.publicdomainpictures.net/pictures/280000/nahled/gradient-colors-blur-background-1542457654NlA.jpg">
        <div style="display: flex; justify-content: space-between;">
          <h1 class="ml-3">Mon compte</h1>
<!--          <v-chip class="ma-2" color="deep-orange accent-4" outlined style="background: rgba(255, 255, 255, 0.7) !important; border: none !important;">-->
<!--            <v-icon left>-->
<!--              mdi-crown-circle-->
<!--            </v-icon>-->
<!--            <span>{{ user.my_cred_default }}</span>-->
<!--          </v-chip>-->
        </div>

        <div class="my-4">
          <div style="position: relative; width: 150px; margin: 0 auto">
            <UserPictureProfil :user="user" @success="handleSuccess"/>
          </div>
          <h2 class="my-3 text-center">{{ user.username }}</h2>
        </div>
      </v-img>
      <v-btn color="secondary" link :to="{name: 'Premium'}" rounded dark large class="btnDiscoverPremium premium--text" v-if="!user.isPremium">
        <v-icon left color="premium">mdi-leaf-circle-outline</v-icon>
        Devient premium
      </v-btn>
      <v-btn color="secondary" link :to="{name: 'UserVerificationRequest'}" rounded dark large class="btnDiscoverPremium premium--text" v-if="user.isPremium && !user.verifiedProfile">
        <v-icon left color="premium">mdi-leaf-circle-outline</v-icon>
        Verifie ton compte
      </v-btn>
      <v-btn color="secondary" rounded dark large class="btnDiscoverPremium premium--text" v-if="user.isPremium && user.verifiedProfile">
        <v-icon left color="premium">mdi-leaf-circle-outline</v-icon>
        Premium verifié
      </v-btn>

      <v-card-text class="text--primary">
        <v-list>
          <template v-for="(action, index) in actions">

            <v-divider v-if="index !== 0" :key="index" :inset="action.inset"/>

            <v-list-item :key="action.route">
              <v-list-item-icon>
                <v-icon :color="action.color">{{ action.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content @click="redirectPage(action.route)" style="cursor: pointer">
                <v-list-item-title>
                  <span class="text-h5">{{ action.title }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ action.description }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import UserPictureProfil from "@/components/Form/UserPictureProfil";

export default {
  name: "Profil",
  components: {UserPictureProfil},
  data() {
    return {
      actions: [
        {
          title: "Mes informations",
          description: "Modifies tes informations personnelles",
          route: "MyInformations",
          icon: "mdi-account-edit",
          color: "blue",
          inset: true
        },
        {
          title: "Mes activités",
          description: "Visualises toutes tes activités",
          route: "MyOuttrips",
          icon: "mdi-earth",
          color: "orange",
          inset: true
        },
        {
          title: "Mes évènements",
          description: "Visualises tous tes évènements",
          route: "MyActivities",
          icon: "mdi-calendar",
          color: "primary",
          inset: true
        },
        {
          title: "Mes notifications",
          description: "Inscris aux notifications par département !",
          route: "Notification",
          icon: "mdi-bell-outline",
          color: "primary",
          inset: true
        },
        {
          title: "Votre profil",
          description: "Voir ton profil public",
          route: "profil-public",
          icon: "mdi-account",
          color: "pink lighten-2",
          inset: false
        },
        {
          title: "Déconnexion",
          description: "Se déconnecter",
          route: "Logout",
          icon: "mdi-account-arrow-left",
          color: "red",
          inset: true
        }
      ]
    }
  },
  computed: {
    ...mapState("auth", ['user'])
  },
  methods: {
    handleSuccess() {
      this.$axios.get('/user/me').then((meResponse) => {
        this.$store.commit('auth/user', meResponse.data)
      })
    },
    redirectPage(name) {
      if (name === "profil-public")
        this.$router.push({name: 'Outtrippeur', params: {user: this.user.username}})
      else
        this.$router.push({name: name})
    }
  }
}
</script>

<style lang="scss" scoped>
.btnEditPhoto {
  position: absolute;
  bottom: -10px;
  right: 10px;
}

.btnDiscoverPremium {
  position: absolute;
  top: 299px;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
