<template>
  <div>
    <span v-if="entry.outtripAnimalsAccepted" class="mr-6 no-wrap">
      <v-icon color="primary">mdi-dog</v-icon>
      <span class="ml-2">Animaux acceptés</span>
    </span>
    <span v-if="entry.outtripAccessibleToPeopleWithReducedMobility" class="mr-6 no-wrap">
      <v-icon color="primary">mdi-wheelchair-accessibility</v-icon>
      <span class="ml-2">Accessible aux personnes à mobilité réduite</span>
    </span>
    <span v-if="entry.outtripAccessibleToStrollers" class="mr-6 no-wrap">
      <v-icon color="primary">mdi-baby-carriage</v-icon>
      <span class="ml-2">Accessible aux poussettes</span>
    </span>
    <span v-if="entry.outtripPointOfView" class="mr-6 no-wrap">
      <v-icon color="primary">mdi-eye</v-icon>
      <span class="ml-2">Point de vue</span>
    </span>
  </div>
</template>

<script>
export default {
  name: "OuttripFeatures",
  props: {
    entry: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
