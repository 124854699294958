<template>
  <div>
    <h3 v-if="entry.outtripAccessByBus || entry.outtripAccessByCar || entry.outtripAccessByTrain || entry.outtripWalkingAccess">
      Accessibilité :</h3>
    <span class="mr-6 no-wrap" v-if="entry.outtripAccessByBus">
        <v-icon color="primary">mdi-bus</v-icon>
        <span class="ml-2">Bus</span>
      </span>
    <span class="mr-6 no-wrap" v-if="entry.outtripAccessByCar">
        <v-icon color="primary">mdi-car</v-icon>
        <span class="ml-2">Voiture</span>
      </span>
    <span class="mr-6 no-wrap" v-if="entry.outtripAccessByTrain">
        <v-icon color="primary">mdi-train</v-icon>
        <span class="ml-2">Train</span>
      </span>
    <span class="mr-6 no-wrap" v-if="entry.outtripWalkingAccess">
        <v-icon color="primary">mdi-walk</v-icon>
        <span class="ml-2">A pied</span>
      </span>
  </div>
</template>

<script>
export default {
  name: "OuttripAccess",
  props: {
    entry: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
