<template>
  <div v-if="users.length <= 0" class="text-center">
    <v-skeleton-loader type="list-item-avatar-two-line@8"></v-skeleton-loader>
  </div>
  <div v-else>
    <OuttriperList :users="users" name="arround" @click="redirectToUser"/>
  </div>
</template>

<script>
import OuttriperList from "@/components/Outtriper/OuttriperList";

export default {
  name: "OuttripersArround",
  components: {OuttriperList},
  data() {
    return {
      users: []
    }
  },
  mounted() {
    this.$axios.get(`/outtrippers_arround/${this.$route.params.slug}`).then(response => response.data)
        .then(response => {
          response.map(r => this.users.push(r.craftCmsData))
        })
  },
  methods: {
    redirectToUser(user) {
      this.$router.push({name: "Outtrippeur", params: {user: user}})
    }
  }
}
</script>

<style scoped>

</style>
