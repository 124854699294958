<template>
  <v-container fluid>
      <h1>Oups...</h1>
      <p>Une erreur s'est produite. N'hésite pas à réessayer plus tard !</p>
    </v-container>
</template>

<script>
export default {

}
</script>