<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
  >
    <v-card>
      <v-card-title class="text-h5">
        Oops !
      </v-card-title>
      <v-card-text>Le groupe de messagerie est réservé aux participant de l'évènement.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary darken-1"
            text
            @click="dialog = false"
        >
          OK !
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NotPermitModal",
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    }
  },
}
</script>