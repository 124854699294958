<template>
  <v-dialog v-model="isOpen" max-width="500">
    <v-card>
      <LoginForm @success="$emit('success')"/>
    </v-card>
  </v-dialog>
</template>

<script>
import LoginForm from "../components/LoginForm";

export default {
  name: "LoginModal",
  components: {LoginForm},
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    openLoginModal() {
      this.isOpen = true
    },
    closeLoginModal() {
      this.isOpen = false
    }
  },
}
</script>

<style scoped>

</style>
