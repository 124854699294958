<template>
  <v-container fluid>
    <h1>Supprimer mon compte</h1>
    <p>Cette action est irréverssible. Es-tu sûr de vouloir supprimer ton compte ?</p>
    <v-btn block color="error" type="submit" @click="submit()" :loading="loading">Oui supprimer mon compte</v-btn>
  </v-container>
</template>

<script>
export default {
  name: "RemoveAccount",
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    submit() {
      this.loading = true;
      this.$axios.delete('/user/deletion').then(() => {
        this.loading = false;
        this.$store.dispatch('auth/logout')
        this.$router.push('/')
      })
    },
  }
}
</script>

<style scoped>

</style>
