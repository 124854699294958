<template>
  <v-card>
    <v-img height="190px" class="white--text" :src="outtrip.url">
      <div class="d-flex flex-column" style="height: 100%">
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-spacer></v-spacer>
          <v-btn color="white" icon @click="$emit('clickOnClose')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-title class="mt-8 bg-title" v-html="outtrip.title"></v-card-title>
      </div>
    </v-img>

    <v-card-text>
      <span class="mr-2 no-wrap" v-if="outtrip.name">
        <v-icon color="primary">{{ outtrip.materialIcon }}</v-icon>
        <span class="ml-2">{{ outtrip.name }}</span>
      </span>

      <span class="mr-2 no-wrap" v-if="outtrip.city">
         <v-icon color="primary">mdi-map-marker-outline</v-icon>
        <span class="ml-2">{{ outtrip.city }}</span>
        </span>

      <span class="mr-2 no-wrap" v-if="outtrip.averageDuration">
        <v-icon color="primary">mdi-clock-outline</v-icon>
        <span class="ml-2">{{ outtrip.averageDuration }}h</span>
      </span>

      <span class="mr-2 no-wrap" v-if="outtrip.distance">
        <v-icon color="primary">mdi-map-marker-distance</v-icon>
        <span class="ml-2">{{ outtrip.distance }}km</span>
      </span>
      <span class="mr-2 no-wrap" v-if="outtrip.positiveElevation">
        <v-icon color="primary">mdi-arrow-top-right</v-icon>
        <span class="ml-2">{{ outtrip.positiveElevation }}m</span>
      </span>
      <span class="mr-2 no-wrap" v-if="outtrip.altitude">
        <v-icon color="primary">mdi-image-filter-hdr</v-icon>
        <span class="ml-2">{{ outtrip.altitude }}m</span>
      </span>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text right link :to="{name: 'Outtrip', params:{slug: outtrip.slug}}">
        Explorer
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'OuttripCard',
  props: {
    outtrip: {
      required: true
    }
  }
}
</script>

<style scoped>
.v-card__title {
  word-break: keep-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.bg-title{
  background-color: rgba(0, 0, 0, 0.4);
}
</style>
