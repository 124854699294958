<template>
  <div style="width: 95%; margin-left: 40px ">
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-top-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
            class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--single-line v-text-field--solo v-text-field--is-booted v-text-field--enclosed v-text-field--rounded"
            style="width: 100%;" v-on="on" v-bind="attrs">
          <div class="v-input__control">
            <div class="v-input__slot">
              <div class="v-text-field__slot" style="display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;word-break: none;overflow: hidden;" v-html="searchText">

              </div>
              <v-icon
                  slot="append"
                  color="primary"
              >
                mdi-magnify
              </v-icon>
              <div class="v-input__append-inner" v-show="showClearButton">
                <div class="v-input__icon v-input__icon--clear">
                  <button type="button" aria-label="clear icon"
                          class="v-icon notranslate v-icon--link mdi mdi-close theme--light" @click.stop="clearSearchBar"></button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Filtres</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="submitDialog">
              Appliquer
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid>
          <v-select v-model="filters.sports" :items="sportItems" item-text="key" item-value="value" :menu-props="{ maxHeight: '400' }" label="Sports"
                    multiple persistent-hint></v-select>
          <PlaceField ref="placeField" v-model="filters.place" @change="onPlaceChange" :error-messages="[]"/>
          <v-select v-model="filters.pointOfInterestType" :items="typeItems" item-text="key" item-value="value" :menu-props="{ maxHeight: '400' }" label="Point d'intérêt"
                    multiple persistent-hint></v-select>
          <v-select v-model="filters.group" :items="groupItems" item-text="key" item-value="value" :menu-props="{ maxHeight: '400' }" label="Groupe"
                    multiple persistent-hint></v-select>
        </v-container>
      </v-card>
    </v-dialog>
    <div class="text-center">
      <v-btn-toggle v-model="filters.type" @change="onTypeChanged" color="primary" rounded fixed top mx-auto class="btn-group-justified">
        <v-btn value="tracks" x-small>Activités</v-btn>
        <v-btn value="events" x-small>&Eacute;vènements</v-btn>
        <v-btn value="community" x-small>Communauté</v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>

<script>
import PlaceField from "@/components/Form/PlaceField";
export default {
  components: {PlaceField},
  computed: {
    showClearButton: function () {
      return this.filters.sports.length > 0 || this.filters.place != null;
    }
  },
  props: {
    filters: {
      required: true,
      default: {
        sports: [],
        pointOfInterestType: [],
        group: [],
        place: null,
        type: 'tracks'
      }
    }
  },
  data: () => ({
    dialog: false,
    placeHasChanged: false,
    searchText: null,
    sportItems: [
      {key: 'Course à pied', value: 'running'},
      {key: 'Gravel', value: 'gravel'},
      {key: 'Kayak - Paddle', value: 'kayak-paddle'},
      {key: 'Parapente', value: 'parapente'},
      {key: 'Rando - Trail', value: 'rando-trail'},
      {key: 'Raquette', value: 'raquette'},
      {key: 'Ski de fond', value: 'ski-de-fond'},
      {key: 'Ski de rando', value: 'ski-de-rando'},
      {key: 'Surf', value: 'surf'},
      {key: 'Vélo de route', value: 'road-bike'},
      {key: 'Vtt', value: 'vtt'},
      {key: 'Via ferrata', value: 'via-ferrata'},
    ],
    typeItems: [
      {key: 'Refuge gardé', value: 'refuge gardé'},
      {key: 'Cabane non gardée', value: 'cabane non gardée'},
      {key: 'Gîte d\'étape', value: 'gîte d\'étape'},
      {key: 'Point d\'eau', value: 'point d\'eau'},
      {key: 'Sommet', value: 'sommet'},
      {key: 'Passage délicat', value: 'passage délicat'},
      {key: 'Lac', value: 'lac'},
    ],
    groupItems: [
      {key: 'SkiTour', value: 2}
    ]
  }),
  methods: {
    onPlaceChange: function () {
      this.placeHasChanged = true;
    },
    setSearchText: function () {
      let text = 'Que fait-on aujourd\'hui ?';
      switch (this.filters.type) {
        case 'tracks':
          text = 'Toutes les activités'
          break;
        case 'events':
          text = 'Tous les évènements'
          break;
        case 'community':
          text = 'Toute la communauté'
          break;
      }

      if (this.filters.sports.length > 0) {
        text += ' de '
        if (this.filters.sports.length > 2) {
          text += this.filters.sports.slice(0, 2).join(', ')
          text += ', ...'
        } else {
          text += this.filters.sports.join(' et ')
        }
      }

      if (this.filters.place) {
        text += ' autour de '
        text += this.filters.place.fullText
      }

      this.searchText = text;
    },
    closeDialog: function () {
      this.dialog = false;
    },
    submitDialog: function () {
      this.dialog = false;

      this.$store.commit('filter/sports', this.filters.sports);
      this.$store.commit('filter/place', this.filters.place);
      this.$store.commit('filter/group', this.filters.group);

      this.setSearchText();
      this.$emit('change', {filters: this.filters, placeHasChanged: this.placeHasChanged});
    },
    onTypeChanged: function () {
      this.$store.commit('filter/type', this.filters.type);
      this.$emit('typeChanged', this.filters.type);
      this.setSearchText();
    },
    clearSearchBar: function () {
      this.filters.sports = [];
      this.filters.place = null;
      if (this.filters.type !== 'community') {
        this.$refs.placeField.clear();
      }
      this.$store.commit('filter/clear');
      this.setSearchText();
      this.$emit('change', {filters: this.filters, placeHasChanged: this.placeHasChanged});
    }
  },
  mounted() {
    this.setSearchText();
  }
}
</script>
