<template>
  <v-text-field :value="place.text" ref="autocomplete" :error-messages="errorMessages" :label="label" outlined rounded required :loading="isLoading"></v-text-field>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';

export default {
  name: 'PlaceField',
  data: () => ({
    text: null,
    isLoading: true,
    autocomplete: null,
    location: null,
    place: {
      text: null,
      lat: null,
      lng: null,
      country: null,
      state: null,
      county: null,
      town: null,
      postcode: null,
    },
  }),
  methods: {
    init() {
      const loader = new Loader({
        apiKey: "AIzaSyCzjt-fmf0hMmSF8aIQDKtwP5UxmfqaAgQ",
        version: "weekly",
        libraries: ["places"]
      });

      loader
        .load()
        .then((google) => {
          this.autocomplete = new google.maps.places.Autocomplete(this.$refs.autocomplete.$el.querySelector('input'));

          this.autocomplete.addListener('place_changed', () => {
            let place = this.autocomplete.getPlace();
            let fullText = [];

            this.place.text = place.name;
            this.place.lat = place.geometry.location.lat();
            this.place.lng = place.geometry.location.lng();

            for (let i = 0; i < place.address_components.length; i++) {
              fullText.push(place.address_components[i].long_name);

              if (place.address_components[i].types.includes('country')) {
                this.place.country = place.address_components[i].long_name;
                continue;
              }
              if (place.address_components[i].types.includes("locality")) {
                this.place.town = place.address_components[i].long_name;
                continue;
              }
              if (place.address_components[i].types.includes("administrative_area_level_2")) {
                this.place.county = place.address_components[i].long_name;
                continue;
              }
              if (place.address_components[i].types.includes("administrative_area_level_1")) {
                this.place.state = place.address_components[i].long_name;
                continue;
              }
              if (place.address_components[i].types.includes("postal_code")) {
                this.place.postcode = place.address_components[i].long_name;
                continue;
              }
            }

            this.place.fullText = fullText.join(', ');

            this.$emit('input', this.place);
            this.$emit('change', this.place);
          });

          this.isLoading = false;
        })
    },
    clear: function () {
      this.place = {
        text: null,
        lat: null,
        lng: null,
        country: null,
        state: null,
        county: null,
        town: null,
        postcode: null
      };
    }
  },
  mounted() {
    this.init();
    if (this.value !== null) {
      this.text = this.value.text;
      this.place.text = this.value.text;
      this.place.lat = this.value.lat;
      this.place.lng = this.value.lng;
      this.place.country = this.value.country;
      this.place.state = this.value.state;
      this.place.county = this.value.county;
      this.place.town = this.value.town;
      this.place.postcode = this.value.postcode;
    }
  },
  props: {
    value: {
      required: true
    },
    label:{
      default: 'Localisation'
    },
    errorMessages: {
      required: true
    }
  }
}
</script>

<style>
.pac-container {
  z-index: 1003;
}
</style>