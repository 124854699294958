<template>
  <v-container fluid>
    <div v-if="!success">
      <h1>Mot de passe oublié ?</h1>
      <p>Entre ton email pour réinitialiser ton mot de passe.</p>
      <form @submit.prevent="submit">
        <v-text-field :error="errorMessage" v-model="email" type="text" outlined rounded label="Email" required></v-text-field>
        <v-btn type="submit" color="primary" rounded :loading="loading" :disabled="loading">Rechercher</v-btn>
      </form>
    </div>
    <div v-else>
      <h1>Consulte ta boite de reception</h1>

      <p>Un email de récupération vient de t'être envoyé.</p>
      <p>Il te suffit de cliquer sur le bouton pour accèder à la page de réinitialisation de mot de passe</p>
      <p>Tu n'as pas encore reçu ton mot de passe ? Regarde dans tes spams, il y sera peut-être (mais je ne pense pas).</p>

      <v-btn v-on:click="success=false">Je n'ai pas reçu l'email</v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    email: null,
    loading: false,
    success: false,
    errorMessage: null
  }),
  methods: {
    submit() {
      if (!this.loading) {
        this.loading = true;

        const FormData = require('form-data');

        const form = new FormData();
        form.append('password_forgotten[email]', this.email);
        form.append('password_forgotten[from]', 'pwa');

        this.$axios.post('/user/password-forgotten', form, { headers: {'content-type': 'application/x-www-form-urlencoded'} })
          .catch((error) => {
            this.errorMessage = error.response.data.form.errors.email[0]
          })
          .then(() => {
            this.loading = true;
          });
      }
    }
  }
}
</script>