<template>
  <v-container fluid>
    <h3>Déconnexion en cours ...</h3>
  </v-container>
</template>

<script>
import EventBus from "../services/eventBus"

export default {
  name: "Logout",
  mounted() {
    EventBus.dispatch("logout")
    this.$router.push({name: 'Home'})
  }
}
</script>

<style scoped>

</style>
