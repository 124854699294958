<template>
  <v-container fluid>
    <h1>Créer un itinéraire</h1>
    <div v-if="!user">
      <v-alert border="left" colored-border type="warning" elevation="2">
        Connecte toi pour accéder à la page.
        <span class="primary--text text-decoration-underline" style="cursor: pointer" @click.stop="openLoginModal">Se connecter</span>
      </v-alert>
      <LoginModal ref="loginModal" @success="closeLoginModal"/>
      <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
    </div>
    <div v-else>
      <OuttripForm/>
    </div>
  </v-container>
</template>

<script>
import OuttripForm from "../components/Outtrip/OuttripForm";
import LoginModal from "@/components/LoginModal";
import {mapState} from "vuex";

export default {
  components: {
    OuttripForm,
    LoginModal
  },
  computed: {
    ...mapState("auth", ['user'])
  },
  name: "CreateActivity",
  methods: {
    openLoginModal() {
     this.$refs.loginModal.openLoginModal()
    },
    closeLoginModal() {
      this.$refs.loginModal.closeLoginModal()
    }
  },
  mounted() {
    this.$axios.get('/user/me').then((meResponse) => {
      this.$store.commit('auth/user', meResponse.data)
    })

    if (!this.user) {
      this.openLoginModal()
    }
  }
}
</script>
