<template>
  <div>
    <file-pond ref="pond" name="gpxTrack" class="filepond" label-idle="Trace GPX" :allow-multiple="true" max-files="1" :server="server" v-on:init="handleInit"/>
  </div>
</template>

<script>
import vueFilePond from "vue-filepond"
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import {mapState} from "vuex";

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
)

export default {
  name: "GPXTrackWidget",
  components: {
    FilePond
  },
  data() {
    return {
      currentFiles: []
    }
  },
  computed: {
    ...mapState("auth", ['tokens']),
    headers() {
      return {
        Authorization: 'Bearer ' + this.tokens.token,
      };
    },
    server() {
      return {
        url: process.env.VUE_APP_API_URL + '/asset-gpx',
        process: {
          headers: this.headers,
          onload: (response) => {
            this.currentFiles.push(response)
            this.$emit('input', this.currentFiles)
            return response
          }
        },
        revert: {
          headers: this.headers,
          onload: (response) => {
            this.currentFiles.splice(this.currentFiles.indexOf(response), 1)
            this.$emit('input', this.currentFiles)
          }
        },
        load: {
          headers: this.headers,
        },
        // eslint-disable-next-line no-unused-vars
        remove: (source, load, error) => {

          let currentFiles = this.currentFiles

          this.$axios.delete('/asset', {
            headers: this.headers,
            data: source
          })
              // eslint-disable-next-line no-unused-vars
              .then((response) => {
                currentFiles.splice(currentFiles.indexOf(source), 1)
                this.$emit('input', currentFiles)
                load()
              })
              .catch(error => error('Oups, une erreur est survenue'))
        }

      }
    }
  },
  methods: {
    handleInit() {
      if (this.value) {
        this.value.forEach((assetId) => this.$refs.pond.addFile(assetId, {type: 'local'}))
      }
    }
  },
  mounted() {
    this.currentFiles = this.value
  },
  props: ["value"]
}
</script>

<style scoped>

</style>
