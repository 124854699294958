<template>
  <div class="text-center">
    <div style="cursor: pointer" @click="goToProfil">
      <OuttriperPhoto :outtriper="entry" :diameter="95"/>
      <div>
        <strong>{{ entry.username }}</strong>
      </div>

      <SportList :sports="entry.sports"/>

    </div>
    <div class="mt-2" v-if="showContactButton">
      <MessagingGoToButton :q="'direct/'+entry.username" label="Contacter l'outtrippeur"/>
    </div>
  </div>
</template>

<script>
import OuttriperPhoto from "@/components/Outtriper/OuttriperPhoto";
import MessagingGoToButton from "@/components/Messaging/MessagingGoToButton";
import SportList from "@/components/Sport/SportList";

export default {
  name: "Author",
  components: {SportList, OuttriperPhoto, MessagingGoToButton},
  props: {
    entry: {
      required: true
    },
    showContactButton: {
      default: true
    }
  },
  methods: {
    goToProfil() {
      this.$router.push({name: 'Outtrippeur', params: {user: this.entry.username}})
    }
  }
}
</script>

<style scoped>

</style>
