<template>
  <v-autocomplete multiple outlined rounded v-model="model" :items="items" :loading="isLoading" :search-input.sync="search" item-value="id" item-text="title" return-object :label="label" @change="onChange">
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title v-html="emptyMessage">
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'OuttripField',
  computed: {
    fields() {
      if (!this.model) return []

      return Object.keys(this.model).map(key => {
        return {
          key,
          value: this.model[key] || 'n/a',
        }
      })
    },
    items() {

      let items = [];

      if (this.model.length > 0) {
        items = items.concat(this.model);
      }

      const suggestions = this.entries.map(entry => {
        const text = entry.title && entry.title.length > this.titleLimit
            ? entry.title.slice(0, this.titleLimit) + '...'
            : entry.title

        return Object.assign({}, entry, {text})
      });

      return items.concat(suggestions);
    }
  },
  data: () => ({
    entries: [],
    isLoading: false,
    search: null,
    model: [],
    titleLimit: 50
  }),
  methods: {
    onChange: function (event) {
      this.$emit('change', event);
    },
    clear: function () {
      this.search = null;
    }
  },
  props: {
    value: {
      type: Array,
      default: () => {
        return []
      },
      required: false
    },
    label:{
      default: 'Activité'
    },
    emptyMessage: {
      default: 'Aucun lieu trouvé.'
    }
  },
  watch: {
    search(val) {
      // Items have already been loaded
      // if (this.items.length > 0) return

      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      this.$axios.get(`/outtrips-details`, {
        params: {
          search: val,
        }
      })
          .then(response => this.entries = response.data)
          .finally(() => (this.isLoading = false))
    },
    model(val) {
      this.$emit('input', val);
    },
    value() {
      if (null !== this.value) {
        this.model = this.value;
        this.entries = [...this.value];
      }
    },
  },
}
</script>
