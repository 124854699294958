<template>
  <div>
    <file-pond
        ref="pond"
        name="userPicture"
        class="filepond"
        style="height:150px; width:150px; border-radius:50%;margin: auto;"
        label-idle="Ajouter une photo"
        accepted-file-types="image/jpeg, image/png"
        image-preview-height="150"
        image-crop-aspect-ratio="1:1"
        image-resize-target-width="150"
        image-resize-target-height="150"
        style-panel-layout="compact circle"
        style-load-indicator-position="center bottom"
        style-progress-indicator-position="left bottom"
        style-button-remove-item-position="center bottom"
        style-button-process-item-position="right bottom"
        :server="server"
        v-on:init="handleInit"
        v-on:processfilestart="onProcessFileStart"
        v-on:processfileabort="onProcessFileAbort"
        v-on:processfilerevert="onProcessFileRevert"
        v-on:processfile="onProcessFile"/>
  </div>
</template>

<script>
import vueFilePond from "vue-filepond"
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import {mapState} from 'vuex';

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
)

export default {
  name: "UserPictureProfil",
  components: {
    FilePond
  },
  data() {
    return {
      currentFiles: []
    }
  },
  computed: {
    ...mapState("auth", ['tokens']),
    headers() {
      return {
        Authorization: 'Bearer ' + this.tokens.token,
      };
    },
    server() {
      return {
        url: process.env.VUE_APP_API_URL + '/user/profil-picture',
        process: {
          headers: this.headers,
          onload: () => {
            this.$emit('success')
          }
        },
        revert: {
          headers: this.headers,
        },
        load: {
          headers: this.headers,
        }
      }
    },
  },
  methods: {
    handleInit() {
      if (this.user.photo) {
        this.$refs.pond.addFile(this.user.id, {type: 'local'})
      }
    },
    onProcessFileStart() {
      this.$emit('processFileStart')
    },
    onProcessFileAbort() {
      this.$emit('processFileAbort')
    },
    onProcessFileRevert() {
      this.$emit('processFileRevert')
    },
    onProcessFile() {
      this.$emit('processFile')
    }
  },
  props: ["user"]
}
</script>

<style scoped>

</style>
