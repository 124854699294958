<template>
  <OuttripList :outtrips="outtrips" @lastShow="loadMore"/>
</template>

<script>
import OuttripList from "@/components/Outtrip/OuttripList.vue";
import OuttripsGql from "@/components/Group/OuttripList/outtrips.gql";

export default {
  components: { OuttripList },
  props: {
    group: {
      type: Object,
      required: true,
    }
  },
  data(){
    return {
      loading: false,
      offset: 1,
      outtrips: [],
    }
  },
  methods: {
    init() {
      this.outtrips = [];
      this.loadMore();
    },
    loadMore() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$cms.post('', {
        query: OuttripsGql.loc.source.body,
        variables: {
          groupId: this.group.id,
          offset: this.offset,
        }
      }).then((response) => {
        this.outtrips = this.outtrips.concat(response.data.data.entries);
        this.offset += response.data.data.entries.length
        this.loading = false
      })
    }
  }
}
</script>
