<template>
    <div class="pa-6" style="margin-bottom: 94px">
      <h1>Mes activités</h1>
      <v-skeleton-loader type="list-item-avatar-two-line@8" v-if="outtrips === null"></v-skeleton-loader>
      <OuttripeurOuttripList :outtrips="outtrips"/>
    </div>
</template>

<script>
import OuttripeurOuttripList from "@/components/Outtriper/OuttripeurOuttripList";
import {mapState} from "vuex";

export default {
  name: "MyOuttrips",
  components: {OuttripeurOuttripList},
  data: () => ({
    outtrips: null,
  }),
  computed: {
    ...mapState("auth", ['user'])
  },
  mounted() {
    this.$axios.get('/outtrips-details', {params: {userId: this.user.id}}).then(response => response.data)
        .then(response => this.outtrips = response)
  }
}
</script>

<style scoped>

</style>
