<template>
  <div class="pa-6">
    <h1>Participants</h1>

    <v-skeleton-loader
        type="list-item-avatar-two-line@8"
        v-if="users === null"
    ></v-skeleton-loader>
    <div v-else-if="users.length <= 0">
      <v-alert type="info" outlined>
        Aucun participant inscrit pour le moment.
      </v-alert>
    </div>
    <OuttriperList v-else name="participant" :users="users" @click="redirectToUser">
      <template v-slot:beforeTitle="{ user }">
        <v-icon size="small" color="success" v-if="user.status === 'accepted'">mdi-checkbox-blank-circle</v-icon>
        <v-icon size="small" color="orange" v-else-if="user.status === 'awaiting_for_acceptance'">En mdi-checkbox-blank-circle</v-icon>
        <v-icon size="small" color="error" v-else-if="user.status === 'refused'">mdi-checkbox-blank-circle</v-icon>
        <v-icon size="small" color="grey" v-else-if="user.status === 'cancel'">mdi-checkbox-blank-circle</v-icon>
      </template>
    </OuttriperList>
  </div>
</template>

<script>
import {mapState} from "vuex";
import OuttriperList from "@/components/Outtriper/OuttriperList";

export default {
  name: "MyActivitiesParticipants",
  components: {OuttriperList},
  data() {
    return {
      users: null,
      event: null
    }
  },
  mounted() {
    const id = this.$route.params.id;
    this.$axios.get(`/event/${id}/participed/list`).then(response => {
      this.users = [];
      response.data.map(r => {
        r.user.status = r.status
        this.users.push(r.user)
      })
    })
  },
  computed: {
    ...mapState("auth", ['user'])
  },
  methods: {
    redirectToUser(user) {
      this.$router.push({name: 'MyActivitiesParticipantsUser', params: {eventId: this.$route.params.id, username: user.username}})
    },
  }
}
</script>

<style lang="scss" scoped>
.listUser {
  display: flex;
  align-items: center;
  padding: 7px 0;

  .listUserName {
    margin-left: 10px;
  }
}
</style>
