import axios from 'axios';
import store from "@/store/index";

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
  (config) => {
      const token = store.getters['auth/token'];
      if (token) {
          config.headers["Authorization"] = 'Bearer ' + token
      }
      return config;
  },
  (error) => {
    console.log(error);
      return Promise.reject(error)
  }
);

instance.interceptors.response.use(
  (res) => {
      return res
  },
  async (err) => {
      const originalConfig = err.config

      if (originalConfig.url !== "/login_check" && err.response) {
          // Access Token was expired
          if (err.response.status === 401 && !originalConfig._retry) {
              originalConfig._retry = true

              try {
                  const rs = await instance.post("/token/refresh", {
                      refresh_token: store.getters['auth/refreshToken'],
                  });

                  const { token } = rs.data;

                  store.dispatch('auth/refreshToken', token)

                  return instance(originalConfig)
              } catch (_error) {
                  return Promise.reject(_error)
              }
          }
      }

      return Promise.reject(err)
  }
);

export default {
    install: function(Vue) {
        Object.defineProperty(Vue.prototype, '$axios', { value: instance });
    }
}
