<template>
  <div>
    <p>Périodes recommandées</p>
    <div v-for="month in months" :key="month.value" class="periodes">
      <v-checkbox v-model="formData.periodes" :label="month.text" :value="month.value" color="primary"></v-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  name: "Periodes",
  props: {
    formData: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    months: [
      {text: "Janvier", value: "janvier"},
      {text: "Février", value: "février"},
      {text: "Mars", value: "mars"},
      {text: "Avril", value: "avril"},
      {text: "Mai", value: "mai"},
      {text: "Juin", value: "juin"},
      {text: "Juillet", value: "juillet"},
      {text: "Août", value: "aout"},
      {text: "Septembre", value: "septembre"},
      {text: "Octobre", value: "octobre"},
      {text: "Novembre", value: "novembre"},
      {text: "Décembre", value: "décembre"},
    ]
  })
}
</script>

<style scoped>
.periodes{
  display: inline-block;
  margin: 3px 7px;
}
</style>
