<template>
  <div>
    <v-skeleton-loader type="card-heading@12" v-if="initialization === true"></v-skeleton-loader>
    <v-form v-else>
      <v-select v-model="formData.sport" :items="sportItems" label="Sport" outlined rounded required @change="sportChanged"></v-select>
      <v-text-field v-model="v$.formData.title.$model" label="Titre" outlined rounded required :error-messages="titleErrors"></v-text-field>
      <v-textarea outlined rounded required append-icon="mdi-help-circle" @click:append="openHelpToKnowWhatWriteDialog(formData.sport)" v-model="v$.formData.description.$model" label="Description" :error-messages="descriptionErrors" v-if="['167937', '169559', '169587', '6', '202'].includes(formData.sport)"></v-textarea>
      <v-textarea outlined rounded required v-model="v$.formData.description.$model" label="Description" :error-messages="descriptionErrors" v-else></v-textarea>

      <v-switch v-model="formData.animaux" label="Animaux acceptés" v-if="['6', '169588', '202'].includes(formData.sport)"></v-switch>
      <v-switch v-model="formData.poussettes" label="Accessibles aux poussettes" v-if="formData.sport === '6'"></v-switch>
      <v-switch v-model="formData.mobiliteReduite" label="Accessible aux personnes à mobilité réduite" v-if="formData.sport === '6'"></v-switch>
      <v-switch v-model="formData.pointDeVue" label="Point de Vue" v-if="['6', '169588', '195944'].includes(formData.sport)"></v-switch>
      <v-switch v-model="formData.free" label="Gratuit" v-if="['195944'].includes(formData.sport)"></v-switch>

      <h2>Itinéraire</h2>
      <v-select v-model="formData.tourType" :items="tourTypes" label="Choisir un type de circuit" outlined rounded required v-if="['187310', '6', '169588', '169589', '37', '169591'].includes(formData.sport)"></v-select>
      <v-select v-model="formData.tourType" :items="tourTypesClimbing" label="Type" outlined rounded required v-if="['248338'].includes(formData.sport)"></v-select>
      <GPXTrackWidget v-model="formData.gpxTrack" v-if="['167937', '187310', '6', '169588', '37', '169591'].includes(formData.sport)"/>
      <v-select v-model="formData.break" :items="breakItems" label="Type de break" rounded outlined required v-if="['169590'].includes(formData.sport)"></v-select>
      <PlaceField v-model="v$.formData.depart.$model" :error-messages="departErrors" :label="departLabel"/>
      <v-textarea v-model="formData.complement" rounded outlined label="Informations complémentaires"></v-textarea>
      <PlaceField v-model="v$.formData.arrive.$model" label="Arrivée" :error-messages="arriveErrors" v-if="['187310', '169587', '6', '169588', '169589', '37', '169591', '195944'].includes(formData.sport)"/>
      <div v-if="formData.sport === '195944'">
        <p>Cotation <v-icon @click:append="openHelpToKnowWhatWriteDialog(formData.sport)">mdi-help-circle</v-icon></p>
        <v-row>
          <v-col cols="2" v-for="c in cotation" :key="c">
            <v-checkbox v-model="formData.cotation" :label="c.text" :value="c.value" :error="cotationErrors.length > 0"></v-checkbox>
          </v-col>
        </v-row>
        <div class="error--text" style="font-size: 12px;" v-if="cotationErrors.length > 0">{{ cotationErrors[0] }}</div>
      </div>
      <v-text-field v-model="v$.formData.distance.$model" label="Distance" outlined rounded required :error-messages="distanceErrors" v-if="['187310', '6', '169588', '169589', '37', '169591', '195944'].includes(formData.sport)"><template v-slot:append>km</template></v-text-field>
      <v-text-field v-model="v$.formData.duree.$model" label="Durée" outlined rounded required :error-messages="dureeErrors" v-if="['187310', '6', '169588', '169589', '37', '195944'].includes(formData.sport)"><template v-slot:append>h</template></v-text-field>
      <v-text-field v-model="v$.formData.outtripPositiveElevation.$model" label="Dénivelé positif" outlined rounded required :error-messages="outtripPositiveElevationErrors" v-if="['187310', '6', '169588', '169589', '37', '169591', '195944'].includes(formData.sport)"><template v-slot:append>m</template></v-text-field>
      <v-text-field v-model="v$.formData.negativeDe.$model" label="Dénivelé négatif" outlined rounded required :error-messages="negativeDeErrors" v-if="['187310', '6', '169588', '169589', '37', '169591', '195944'].includes(formData.sport)"><template v-slot:append>m</template></v-text-field>
      <v-text-field v-model="v$.formData.numberOfRoutes.$model" label="Nombre de voie" outlined rounded required :error-messages="numberOfRoutesErrors" v-if="['248338'].includes(formData.sport)"><template v-slot:append>m</template></v-text-field>
      <v-text-field v-model="v$.formData.altitude.$model" label="Altitude" outlined rounded required :error-messages="altitudeErrors" v-if="['187310', '169587', '6', '169588', '169589', '37', '195944'].includes(formData.sport)"><template v-slot:append>m</template></v-text-field>
      <v-text-field v-model="v$.formData.altitude.$model" label="Altitude maximum" outlined rounded required :error-messages="altitudeErrors" v-if="['248338'].includes(formData.sport)"><template v-slot:append>m</template></v-text-field>
      <v-text-field v-model="v$.formData.altitudeMinimum.$model" label="Altitude minimum" outlined rounded required :error-messages="altitudeMinimumErrors" v-if="['248338'].includes(formData.sport)"><template v-slot:append>m</template></v-text-field>
      <v-text-field v-model="v$.formData.cotationFrom.$model" label="Cotation de" outlined rounded required :error-messages="cotationFromErrors" v-if="['248338'].includes(formData.sport)"><template v-slot:append>m</template></v-text-field>
      <v-text-field v-model="v$.formData.cotationTo.$model" label="Cotation à" outlined rounded required :error-messages="cotationToErrors" v-if="['248338'].includes(formData.sport)"><template v-slot:append>m</template></v-text-field>
      <v-select v-model="formData.slope" :items="difficulty" label="Difficulté de la piste" rounded outlined required v-if="['169591'].includes(formData.sport)"></v-select>

      <h2 v-if="formData.sport === '202'">Les pistes de ski de fond</h2>
      <v-text-field v-model="v$.formData.pisteVertes.$model" rounded outlined label="Nombre de pistes vertes" required :error-messages="pistesVertesErrors" v-if="formData.sport === '202'"></v-text-field>
      <v-text-field v-model="v$.formData.pisteBleues.$model" rounded outlined label="Nombre de pistes bleues" required :error-messages="pistesBleuesErrors" v-if="formData.sport === '202'"></v-text-field>
      <v-text-field v-model="v$.formData.pisteRouges.$model" rounded outlined label="Nombre de pistes rouges" required :error-messages="pistesRougesErrors" v-if="formData.sport === '202'"></v-text-field>
      <v-text-field v-model="v$.formData.pisteNoires.$model" rounded outlined label="Nombre de pistes noires" required :error-messages="pistesNoiresErrors" v-if="formData.sport === '202'"></v-text-field>
      <v-text-field v-model="v$.formData.distance.$model" rounded outlined label="Distance totale des pistes (km)" required :error-messages="distanceErrors" v-if="formData.sport === '202'"></v-text-field>
      <v-textarea v-model="v$.formData.forfaits.$model" rounded outlined label="Les forfaits" required :error-messages="forfaitsError" v-if="formData.sport === '202'"></v-textarea>
      <v-text-field v-model="v$.formData.website.$model" prepend-inner-icon="mdi-web" rounded outlined label="Site internet" required :error-messages="websiteError" v-if="formData.sport === '202'"></v-text-field>
      <v-textarea v-model="v$.formData.contactNordique.$model" rounded outlined label="Contact du centre nordique" required :error-messages="contactNordiqueError" v-if="formData.sport === '202'"></v-textarea>
      <v-textarea v-model="v$.formData.choixStation.$model" rounded outlined label="Pourquoi choisir cette station ?" required :error-messages="choixStationError" v-if="formData.sport === '202'"></v-textarea>

      <v-textarea v-model="formData.difficulty" rounded outlined label="Difficulté" v-if="['195944', '248338'].includes(formData.sport)"></v-textarea>
      <v-textarea v-model="formData.approche" rounded outlined label="Approche" v-if="['195944', '248338'].includes(formData.sport)"></v-textarea>

      <v-text-field v-model="v$.formData.passerelles.$model" label="Nombre de Passerelles" rounded outlined required :error-messages="passerellesErrors" v-if="formData.sport === '195944'"></v-text-field>
      <v-text-field v-model="v$.formData.pontDeSinge.$model" label="Nombre de ponts de singe" rounded outlined required :error-messages="pontDeSingeErrors" v-if="formData.sport === '195944'"></v-text-field>
      <v-text-field v-model="v$.formData.tyroliennes.$model" label="Nombre de Tyroliennes" rounded outlined required :error-messages="tyroliennesErrors" v-if="formData.sport === '195944'"></v-text-field>
      <v-text-field v-model="v$.formData.echelles.$model" label="Nombre de Echelles" rounded outlined required :error-messages="echellesErrors" v-if="formData.sport === '195944'"></v-text-field>

      <Periodes :form-data="formData" v-if="['187310', '169559', '6', '169588', '169589', '169590', '37', '169591', '195944'].includes(formData.sport)"/>
      <FormTransportSwitch :form-data="formData"/>

      <h2 v-if="['187310', '169559', '169587', '6'].includes(formData.sport)">Équipement</h2>
      <v-text-field v-model="formData.velo" label="Vélo" rounded outlined v-if="['187310', '37', '169591'].includes(formData.sport)"></v-text-field>
      <v-text-field v-model="formData.nutrition" label="Nutrition" rounded outlined v-if="['187310', '37'].includes(formData.sport)"></v-text-field>
      <v-text-field v-model="formData.planche" label="Planche" rounded outlined v-if="['169559', '169590'].includes(formData.sport)"></v-text-field>
      <v-text-field v-model="formData.ski" label="Ski" rounded outlined v-if="['169589'].includes(formData.sport)"></v-text-field>
      <v-text-field v-model="formData.sac" append-icon="mdi-help-circle" @click:append="openHelpToKnowWhatWriteDialog('sac')" label="Sac" rounded outlined v-if="['169559', '6', '169588', '169589', '169591'].includes(formData.sport)"></v-text-field>
      <v-text-field v-model="formData.raquettes" append-icon="mdi-help-circle" @click:append="openHelpToKnowWhatWriteDialog('raquettes')" label="Raquettes" rounded outlined v-if="['169588'].includes(formData.sport)"></v-text-field>
      <v-text-field v-model="formData.parapente" label="Parapente" rounded outlined v-if="['169587'].includes(formData.sport)"></v-text-field>
      <v-text-field v-model="formData.chaussures" append-icon="mdi-help-circle" @click:append="openHelpToKnowWhatWriteDialog('chaussures')" label="Chaussures" rounded outlined v-if="['6'].includes(formData.sport)"></v-text-field>
      <v-text-field v-model="formData.baton" append-icon="mdi-help-circle" @click:append="openHelpToKnowWhatWriteDialog('baton')" label="Bâtons" rounded outlined v-if="['6', '169588'].includes(formData.sport)"></v-text-field>
      <v-textarea v-model="formData.commentaire" rounded outlined label="Commentaires"></v-textarea>
      <GalleryWidget v-model="v$.formData.photos.$model" :error-messages="photosErrors" :folderId="10"/>

      <v-btn block color="primary" @click="submitEdit" :loading="loading" v-if="isEdit">Mettre à jour</v-btn>
      <v-btn block color="primary" @click="submit" :loading="loading" v-else>Publier</v-btn>
      <v-btn block color="secondary" @click="draft" :loading="loading" v-if="!entry">Créer un brouillon</v-btn>
      <v-btn block color="error" @click="deleteDraft" :loading="loading" v-if="entry && entry.isDraft">Supprimer un brouillon</v-btn>
      <v-btn block color="success" @click="publishDraft" :loading="loading" v-if="entry && entry.isDraft">Publier un brouillon</v-btn>

      <HelpToKnowWhatWrite ref="helpToKnowWhatWrite" :sport-value="htkwwValue"/>
    </v-form>

    <v-dialog v-model="successDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 font-weight-black primary" style="color: white">
          Merci !
        </v-card-title>

        <v-card-text>
          Ton outtrip est en cours de modération. Il sera publié une fois validé.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$router.push({name: 'Home'})">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbarSuccess" timeout="5000" color="success" left style="z-index: 1001">
      {{ snackbarText }}
    </v-snackbar>
    <v-snackbar v-model="snackbarError" timeout="2000" color="error" style="z-index: 1001" left>
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required, minLength, maxLength, requiredIf, decimal} from "vuelidate/lib/validators";
import HelpToKnowWhatWrite from "@/components/HelpToKnowWhatWrite";
import GPXTrackWidget from "@/components/Form/GPXTrackWidget";
import PlaceField from "@/components/Form/PlaceField";
import FormTransportSwitch from "../FormTransportSwitch";
import GalleryWidget from "@/components/Form/GalleryWidget";
import Periodes from "../Periodes";

export default {
  components: {
    GPXTrackWidget,
    HelpToKnowWhatWrite,
    PlaceField,
    FormTransportSwitch,
    GalleryWidget,
    Periodes
  },
  name: "OuttripForm",
  props: {
    isEdit: {
      default: false,
      type: Boolean
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      initialization: true,
      loading: false,
      dialog: false,
      snackbarSuccess: false,
      snackbarError: false,
      snackbarText: null,
      successDialog: false,
      htkwwValue: null,
      cotation: [
        {text: "F", value: "0"},
        {text: "PD", value: "0.16"},
        {text: "AD", value: "0.32"},
        {text: "D", value: "0.58"},
        {text: "TD", value: "0.74"},
        {text: "ED", value: "1"}
      ],
      difficulty: [
        {text: "Verte", value: "verte"},
        {text: "Bleue", value: "bleue"},
        {text: "Rouge", value: "rouge"},
        {text: "Noire", value: "noire"},
      ],
      breakItems: [
        {text: "Reef-Break", value: "Reef-Break"},
        {text: "Beach-Break", value: "Beach-Break"},
      ],
      tourTypes: [
        {text: "Boucle", value: "loop"},
        {text: "Aller-retour", value: "round-trip"},
        {text: "Itinérance", value: "roaming"}
      ],
      tourTypesClimbing: [
        {text: "Voie", value: "voie"},
        {text: "Block", value: "block"},
        {text: "Libre", value: "libre"}
      ],
      sportItems: [
        {text: "Course à pied", value: "167937"},
        {text: "Escalade", value: "248338"},
        {text: "Gravel", value: "187310"},
        {text: "Kayak - Paddle", value: "169559"},
        {text: "Parapente", value: "169587"},
        {text: "Rando-Trail", value: "6"},
        {text: "Raquette", value: "169588"},
        {text: "Ski de fond", value: "202"},
        {text: "Ski de rando", value: "169589"},
        {text: "Surf", value: "169590"},
        {text: "Vélo de route", value: "37"},
        {text: "Vtt", value: "169591"},
        {text: "Via ferrata", value: "195944"}
      ],
      formData: {
        sport: "167937",
        title: null,
        description: null,
        complement: null,
        commentaire: null,
        bus: false,
        train: false,
        voiture: false,
        marche: false,
        photos: [],
        tourType: "loop",
        depart: null,
        arrive: null,
        distance: null,
        duree: null,
        outtripPositiveElevation: null,
        negativeDe: null,
        altitude: null,
        altitudeMinimum: null,
        cotationFrom: null,
        cotationTo: null,
        numberOfRoutes: null,
        periodes: [],
        velo: null,
        nutrition: null,
        planche: null,
        sac: null,
        parapente: null,
        chaussures: null,
        baton: null,
        animaux: false,
        poussettes: false,
        mobiliteReduite: false,
        pointDeVue: false,
        raquettes: null,
        pisteVertes: null,
        pisteBleues: null,
        pisteRouges: null,
        pisteNoires: null,
        slope: null,
        forfaits: null,
        website: null,
        contactNordique: null,
        choixStation: null,
        ski: null,
        break: "Reef-Break",
        difficulty: null,
        free: true,
        cotation: [],
        echappatoire: false,
        approche: null,
        passerelles: null,
        pontDeSinge: null,
        tyroliennes: null,
        echelles: null,
        gpxTrack: []
      },
      entry: null
    }
  },
  validations() {
    return {
      formData: {
        title: {
          required,
          $autoDirty: true
        },
        description: {
          required,
          $autoDirty: true
        },
        photos: {
          required,
          minLenght: minLength(1),
          maxLenght: maxLength(5)
        },
        tourType: {
          requiredIf: requiredIf(() => ['6', '187310', '169589'].includes(this.formData.sport))
        },
        depart: {
          required
        },
        arrive: {
          requiredIf: requiredIf(() => ['6', '187310', '169587', '169588', '169589', '37', '169591', '195944'].includes(this.formData.sport))
        },
        distance: {
          requiredIf: requiredIf(() => ['6', '187310', '169588', '202', '169589', '37', '169591', '195944'].includes(this.formData.sport)),
          decimal,
          $autoDirty: true
        },
        duree: {
          requiredIf: requiredIf(() => ['6', '187310', '169588', '169589', '37', '195944'].includes(this.formData.sport)),
          decimal,
          $autoDirty: true
        },
        outtripPositiveElevation: {
          requiredIf: requiredIf(() => ['6', '187310', '169588', '169589', '37', '169591', '195944'].includes(this.formData.sport)),
          decimal,
          $autoDirty: true
        },
        negativeDe: {
          requiredIf: requiredIf(() => ['6', '187310', '169588', '169589', '37', '169591', '195944'].includes(this.formData.sport)),
          decimal,
          $autoDirty: true
        },
        altitude: {
          requiredIf: requiredIf(() => ['6', '187310', '169587', '169588', '169589', '37', '195944', '248338'].includes(this.formData.sport)),
          decimal,
          $autoDirty: true
        },
        altitudeMinimum: {
          requiredIf: requiredIf(() => ['248338'].includes(this.formData.sport)),
          decimal,
          $autoDirty: true
        },
        cotationFrom: {
          requiredIf: requiredIf(() => ['248338'].includes(this.formData.sport)),
          decimal,
          $autoDirty: true
        },
        cotationTo: {
          requiredIf: requiredIf(() => ['248338'].includes(this.formData.sport)),
          decimal,
          $autoDirty: true
        },
        numberOfRoutes: {
          requiredIf: requiredIf(() => ['248338'].includes(this.formData.sport)),
          decimal,
          $autoDirty: true
        },
        pisteVertes: {
          requiredIf: requiredIf(() => ['202'].includes(this.formData.sport)),
          decimal,
          $autoDirty: true
        },
        pisteBleues: {
          requiredIf: requiredIf(() => ['202'].includes(this.formData.sport)),
          decimal,
          $autoDirty: true
        },
        pisteRouges: {
          requiredIf: requiredIf(() => ['202'].includes(this.formData.sport)),
          decimal,
          $autoDirty: true
        },
        pisteNoires: {
          requiredIf: requiredIf(() => ['202'].includes(this.formData.sport)),
          decimal,
          $autoDirty: true
        },
        forfaits: {
          requiredIf: requiredIf(() => ['202'].includes(this.formData.sport)),
          $autoDirty: true
        },
        website: {
          requiredIf: requiredIf(() => ['202'].includes(this.formData.sport)),
          $autoDirty: true
        },
        contactNordique: {
          requiredIf: requiredIf(() => ['202'].includes(this.formData.sport)),
          $autoDirty: true
        },
        choixStation: {
          requiredIf: requiredIf(() => ['202'].includes(this.formData.sport)),
          $autoDirty: true
        },
        break: {
          requiredIf: requiredIf(() => ['169590'].includes(this.formData.sport)),
          $autoDirty: true
        },
        difficulty: {
          requiredIf: requiredIf(() => ['195944'].includes(this.formData.sport)),
          $autoDirty: true
        },
        cotation: {
          requiredIf: requiredIf(() => ['195944'].includes(this.formData.sport)),
          $autoDirty: true
        },
        passerelles: {
          requiredIf: requiredIf(() => ['195944'].includes(this.formData.sport)),
          decimal,
          $autoDirty: true
        },
        pontDeSinge: {
          requiredIf: requiredIf(() => ['195944'].includes(this.formData.sport)),
          decimal,
          $autoDirty: true
        },
        tyroliennes: {
          requiredIf: requiredIf(() => ['195944'].includes(this.formData.sport)),
          decimal,
          $autoDirty: true
        },
        echelles: {
          requiredIf: requiredIf(() => ['195944'].includes(this.formData.sport)),
          decimal,
          $autoDirty: true
        },
      }
    }
  },
  mounted() {
    if (this.isEdit) {
      this.$axios.get(`/my-informations/outtrip/${this.$route.params.id}`).then(response => {
        this.entry = response.data.entry

        if (this.entry) {
          this.formData.sport = this.entry.sport[0].id
          this.formData.title = this.entry.title
          this.formData.description = this.entry.outtripDescription
          this.formData.animaux = this.entry.outtripAnimalsAccepted
          this.formData.poussettes = this.entry.outtripAccessibleToStrollers
          this.formData.mobiliteReduite = this.entry.outtripAccessibleToPeopleWithReducedMobility
          this.formData.pointDeVue = this.entry.outtripPointOfView
          this.formData.tourType = this.entry.tourType
          this.formData.complement = this.entry.outtripAdditionalInformations
          this.formData.distance = this.entry.outtripDistance
          this.formData.duree = this.entry.outtripDuration
          this.formData.outtripPositiveElevation = this.entry.outtripPositiveElevation
          this.formData.negativeDe = this.entry.negativeElevation
          this.formData.altitude = this.entry.outtripAltitude
          this.formData.altitudeMinimum = this.entry.outtripAltitudeMinimum
          this.formData.cotationFrom = this.entry.outtripCotationFrom
          this.formData.cotationTo = this.entry.outtripCotationTo
          this.formData.numberOfRoutes = this.entry.outtripNumberOfRoutes
          this.formData.periodes = this.entry.months
          this.formData.bus = this.entry.outtripAccessByBus
          this.formData.voiture = this.entry.outtripAccessByCar
          this.formData.train = this.entry.outtripAccessByTrain
          this.formData.marche = this.entry.outtripWalkingAccess
          this.formData.chaussures = this.entry.outtripShoesEquipment
          this.formData.baton = this.entry.outtripSticksEquipment
          this.formData.sac = this.entry.outtripBagEquipment
          this.formData.commentaire = this.entry.outtripComments
          this.formData.velo = this.entry.outtripBikeEquipment
          this.formData.nutrition = this.entry.outtripNutritionEquipment
          this.formData.planche = this.entry.outtripBoardEquipment
          this.formData.parapente = this.entry.outtripParagliderEquipment
          this.formData.raquettes = this.entry.outtripSnowShoesEquipment
          this.formData.pisteVertes = this.entry.outtripNumberOfGreenTrack
          this.formData.pisteBleues = this.entry.outtripNumberOfBlueTrack
          this.formData.pisteRouges = this.entry.outtripNumberOfRedTrack
          this.formData.pisteNoires = this.entry.outtripNumberOfBlackTrack
          this.formData.forfaits = this.entry.outtripPass
          this.formData.website = this.entry.outtripWebsite
          this.formData.contactNordique = this.entry.outtripContact
          this.formData.choixStation = this.entry.outtripWhyChooseThisStation
          this.formData.difficulty = this.entry.outtripDifficulte
          this.formData.slope = this.entry.outtripSlopeLevel
          this.formData.approche = this.entry.outtripApproche
          this.formData.echelles = this.entry.outtripNombreDeEchelles
          this.formData.passerelles = this.entry.outtripNombreDePasserelles
          this.formData.pontDeSinge = this.entry.outtripNombreDePontsDeSinge
          this.formData.tyroliennes = this.entry.outtripNombreDeTyroliennes
          this.formData.free = this.entry.outtripFree
          if (this.entry.outtripCotation) {
            this.formData.cotation = this.entry.outtripCotation.map(cotation => cotation.substr(0, 2));
          }
          this.formData.depart = {
            text: this.entry.outtripDeparture.address,
            lat: this.entry.outtripDeparture.lat,
            lng: this.entry.outtripDeparture.lng,
            city: this.entry.outtripDeparture.parts.city,
            country: this.entry.outtripDeparture.parts.country,
            county: this.entry.outtripDeparture.parts.county,
            postcode: this.entry.outtripDeparture.parts.postcode,
            state: this.entry.outtripDeparture.parts.state
          }
          if (this.entry.outtripArrival) {
            this.formData.arrive = {
              text: this.entry.outtripArrival.address,
              lat: this.entry.outtripArrival.lat,
              lng: this.entry.outtripArrival.lng,
              city: this.entry.outtripArrival.parts.city,
              country: this.entry.outtripArrival.parts.country,
              county: this.entry.outtripArrival.parts.county,
              postcode: this.entry.outtripArrival.parts.postcode,
              state: this.entry.outtripArrival.parts.state
            }
          }

          this.entry.outtripGallery.forEach((gallery) => this.formData.photos.push(gallery.id));
          if (this.entry.gpxTrack) {
            this.entry.gpxTrack.forEach((track) => this.formData.gpxTrack.push(track.id));
          }

          this.initialization = false;
        }
      })
    } else {
      this.initialization = false;
    }
  },
  computed: {
    departLabel() {
      if (['167937', '169559', '202', '169590'].includes(this.formData.sport)) {
        return 'Spot';
      }

      return 'Départ';
    },
    titleErrors() {
      const errors = []
      if (!this.v$.formData.title.$dirty) return errors
      this.v$.formData.title.required.$invalid && errors.push('Ce champs est obligatoire.')
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.v$.formData.description.$dirty) return errors
      this.v$.formData.description.required.$invalid && errors.push('Ce champs est obligatoire.')
      return errors
    },
    departErrors() {
      const errors = []
      if (!this.v$.formData.depart.$dirty) return errors
      this.v$.formData.depart.required.$invalid && errors.push('Ce champs est obligatoire.')
      return errors
    },
    photosErrors() {
      const errors = []
      if (!this.v$.formData.photos.$dirty) return errors
      this.v$.formData.photos.required.$invalid && errors.push('Ajoute 1 à 5 photos.')
      this.v$.formData.photos.minLenght.$invalid && errors.push('Ajoute au moins une photos.')
      this.v$.formData.photos.maxLenght.$invalid && errors.push('5 photos maximum.')
      return errors
    },
    arriveErrors() {
      const errors = []
      if (!this.v$.formData.arrive.$dirty) return errors
      this.v$.formData.arrive.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      return errors
    },
    distanceErrors() {
      const errors = []
      if (!this.v$.formData.distance.$dirty) return errors
      this.v$.formData.distance.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.formData.distance.decimal.$invalid && errors.push('Ce champs doit être un nombre.')
      return errors
    },
    dureeErrors() {
      const errors = []
      if (!this.v$.formData.duree.$dirty) return errors
      this.v$.formData.duree.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.formData.duree.decimal.$invalid && errors.push('Ce champs doit être un nombre.')
      return errors
    },
    outtripPositiveElevationErrors() {
      const errors = []
      if (!this.v$.formData.outtripPositiveElevation.$dirty) return errors
      this.v$.formData.outtripPositiveElevation.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.formData.outtripPositiveElevation.decimal.$invalid && errors.push('Ce champs doit être un nombre.')
      return errors
    },
    negativeDeErrors() {
      const errors = []
      if (!this.v$.formData.negativeDe.$dirty) return errors
      this.v$.formData.negativeDe.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.formData.negativeDe.decimal.$invalid && errors.push('Ce champs doit être un nombre.')
      return errors
    },
    altitudeErrors() {
      const errors = []
      if (!this.v$.formData.altitude.$dirty) return errors
      this.v$.formData.altitude.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.formData.altitude.decimal.$invalid && errors.push('Ce champs doit être un nombre.')
      return errors
    },
    altitudeMinimumErrors() {
      const errors = []
      if (!this.v$.formData.altitudeMinimum.$dirty) return errors
      this.v$.formData.altitudeMinimum.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.formData.altitudeMinimum.decimal.$invalid && errors.push('Ce champs doit être un nombre.')
      return errors
    },
    cotationToErrors() {
      const errors = []
      if (!this.v$.formData.cotationTo.$dirty) return errors
      this.v$.formData.cotationTo.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.formData.cotationTo.decimal.$invalid && errors.push('Ce champs doit être un nombre.')
      return errors
    },
    cotationFromErrors() {
      const errors = []
      if (!this.v$.formData.cotationFrom.$dirty) return errors
      this.v$.formData.cotationFrom.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.formData.cotationFrom.decimal.$invalid && errors.push('Ce champs doit être un nombre.')
      return errors
    },
    numberOfRoutesErrors() {
      const errors = []
      if (!this.v$.formData.numberOfRoutes.$dirty) return errors
      this.v$.formData.numberOfRoutes.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.formData.numberOfRoutes.decimal.$invalid && errors.push('Ce champs doit être un nombre.')
      return errors
    },
    pistesVertesErrors() {
      const errors = []
      if (!this.v$.formData.pisteVertes.$dirty) return errors
      this.v$.formData.pisteVertes.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.formData.pisteVertes.decimal.$invalid && errors.push('Ce champs doit être un nombre.')
      return errors
    },
    pistesBleuesErrors() {
      const errors = []
      if (!this.v$.formData.pisteBleues.$dirty) return errors
      this.v$.formData.pisteBleues.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.formData.pisteBleues.decimal.$invalid && errors.push('Ce champs doit être un nombre.')
      return errors
    },
    pistesRougesErrors() {
      const errors = []
      if (!this.v$.formData.pisteRouges.$dirty) return errors
      this.v$.formData.pisteRouges.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.formData.pisteRouges.decimal.$invalid && errors.push('Ce champs doit être un nombre.')
      return errors
    },
    pistesNoiresErrors() {
      const errors = []
      if (!this.v$.formData.pisteNoires.$dirty) return errors
      this.v$.formData.pisteNoires.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.formData.pisteNoires.decimal.$invalid && errors.push('Ce champs doit être un nombre.')
      return errors
    },
    forfaitsError() {
      const errors = []
      if (!this.v$.formData.forfaits.$dirty) return errors
      this.v$.formData.forfaits.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      return errors
    },
    websiteError() {
      const errors = []
      if (!this.v$.formData.website.$dirty) return errors
      this.v$.formData.website.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      return errors
    },
    contactNordiqueError() {
      const errors = []
      if (!this.v$.formData.contactNordique.$dirty) return errors
      this.v$.formData.contactNordique.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      return errors
    },
    choixStationError() {
      const errors = []
      if (!this.v$.formData.choixStation.$dirty) return errors
      this.v$.formData.choixStation.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      return errors
    },
    cotationErrors() {
      const errors = []
      if (!this.v$.formData.cotation.$dirty) return errors
      this.v$.formData.cotation.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      return errors
    },
    passerellesErrors() {
      const errors = []
      if (!this.v$.formData.passerelles.$dirty) return errors
      this.v$.formData.passerelles.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.formData.passerelles.decimal.$invalid && errors.push('Ce champs doit être un nombre.')
      return errors
    },
    pontDeSingeErrors() {
      const errors = []
      if (!this.v$.formData.pontDeSinge.$dirty) return errors
      this.v$.formData.pontDeSinge.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.formData.pontDeSinge.decimal.$invalid && errors.push('Ce champs doit être un nombre.')
      return errors
    },
    tyroliennesErrors() {
      const errors = []
      if (!this.v$.formData.tyroliennes.$dirty) return errors
      this.v$.formData.tyroliennes.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.formData.tyroliennes.decimal.$invalid && errors.push('Ce champs doit être un nombre.')
      return errors
    },
    echellesErrors() {
      const errors = []
      if (!this.v$.formData.echelles.$dirty) return errors
      this.v$.formData.echelles.requiredIf.$invalid && errors.push('Ce champs est obligatoire.')
      this.v$.formData.echelles.decimal.$invalid && errors.push('Ce champs doit être un nombre.')
      return errors
    }
  },
  methods: {
    sportChanged() {
      this.v$.$reset();
    },
    openHelpToKnowWhatWriteDialog(value) {
      this.htkwwValue = value;
      this.$refs.helpToKnowWhatWrite.openDialog()
    },
    async submit() {
      this.loading = true

      const isFormCorrect = await this.v$.$validate()

      if (!isFormCorrect) {
        this.snackbarError = true
        this.snackbarText = 'Le formulaire contient des erreurs.';
        this.loading = false
        return;
      }
      // /#/mon-compte/mes-outtrips/279095/editer
      const form = this.getFormData();

      this.$axios.post('/outtrip', form)
          .then(() => {
            this.successDialog = true;
          })
          .catch(error => {
            this.snackbarError = true
            this.snackbarText = error.message
          })
          .then(() => this.loading = false)
    },
    draft() {
      this.loading = true

      const form = this.getFormData();

      this.$axios.post('/outtrip/save-draft', form)
          .then(response => {
            this.snackbarSuccess = true
            this.snackbarText = "Ton brouillon a bien été créé et sera accessible dans les 5 prochaines minutes"
            console.log(response);
            this.$router.push({name: 'OuttripEdit', params: {id: response.data.sourceId}})
          })
          .catch(error => {
            this.snackbarError = true
            this.snackbarText = error.message
          })
          .then(() => this.loading = false)
    },
    async submitEdit() {
      this.loading = true

      const isFormCorrect = await this.v$.$validate()

      if (!isFormCorrect) {
        this.snackbarError = true
        this.snackbarText = 'Le formulaire contient des erreurs.';
        this.loading = false
        return;
      }

      const form = this.getFormData();

      this.$axios.post('/mes-informations/outtrip/' + this.entry.id + '/edit', form)
        .then(() => {
          this.snackbarSuccess = true
          this.snackbarText = "Ton outtrip sera mis à jours dans les 5 prochaines minutes"
        })
        .catch(error => {
          this.snackbarError = true
          this.snackbarText = error.message
        })
        .then(() => this.loading = false)
    },
    async publishDraft() {
      this.loading = true

      const isFormCorrect = await this.v$.$validate()

      if (!isFormCorrect) {
        this.snackbarError = true
        this.snackbarText = 'Le formulaire contient des erreurs.';
        this.loading = false
        return;
      }

      const form = this.getFormData();

      this.$axios.post('/outtrip/publish-draft', form)
          .then(() => {
            this.snackbarSuccess = true
            this.snackbarText = "Ton brouillon a bien été publié :), il est en cours de modération."
          })
          .catch(error => {
            this.snackbarError = true
            this.snackbarText = error.message
          })
          .then(() => this.loading = false)
    },
    deleteDraft() {
      this.loading = true

      this.$axios.delete('/outtrip/delete-draft/'+this.entry.draftId)
          .then(() => {
            this.snackbarSuccess = true
            this.snackbarText = "Ton brouillon a bien été supprimé :)"

            this.$router.push({name: 'MyOuttrips'})
          })
          .catch(error => {
            this.snackbarError = true
            this.snackbarText = error.message
          })
          .then(() => this.loading = false)
    },
    getFormData() {
      const form = new FormData();

      if (this.formData.periodes) {
        for (let i = 0; i < this.formData.periodes.length; i++) {
          form.append('outtrip[fields][months][]', this.formData.periodes[i]);
        }
      }

      if (this.formData.cotation) {
        for (let i = 0; i < this.formData.cotation.length; i++) {
          form.append('outtrip[fields][outtripCotation][]', this.formData.cotation[i]);
        }
      }
      if (this.formData.difficulty) {
        form.append('outtrip[fields][outtripDifficulte]', this.formData.difficulty);
      }
      if (this.formData.approche) {
        form.append('outtrip[fields][outtripApproche]', this.formData.approche);
      }
      if (this.formData.echappatoire) {
        form.append('outtrip[fields][outtripEchappatoire]', '1');
      }
      if (this.formData.passerelles) {
        form.append('outtrip[fields][outtripNombreDePasserelles]', this.formData.passerelles);
      }
      if (this.formData.pontDeSinge) {
        form.append('outtrip[fields][outtripNombreDePontsDeSinge]', this.formData.pontDeSinge);
      }
      if (this.formData.tyroliennes) {
        form.append('outtrip[fields][outtripNombreDeTyroliennes]', this.formData.tyroliennes);
      }
      if (this.formData.echelles) {
        form.append('outtrip[fields][outtripNombreDeEchelles]', this.formData.echelles);
      }
      if (this.formData.title) {
        form.append('outtrip[title]', this.formData.title);
      }
      if (this.formData.negativeDe) {
        form.append('outtrip[fields][negativeElevation]', this.formData.negativeDe);
      }
      if (this.formData.sport) {
        form.append('outtrip[fields][sport][]', this.formData.sport);
      }
      if (this.formData.tourType) {
        form.append('outtrip[fields][tourType]', this.formData.tourType);
      }
      if (this.formData.complement) {
        form.append('outtrip[fields][outtripAdditionalInformations]', this.formData.complement);
      }
      if (this.formData.altitude) {
        form.append('outtrip[fields][outtripAltitude]', this.formData.altitude);
      }
      if (this.formData.altitudeMinimum) {
        form.append('outtrip[fields][outtripAltitudeMinimum]', this.formData.altitudeMinimum);
      }
      if (this.formData.cotationFrom) {
        form.append('outtrip[fields][outtripCotationFrom]', this.formData.cotationFrom);
      }
      if (this.formData.cotationTo) {
        form.append('outtrip[fields][outtripCotationTo]', this.formData.cotationTo);
      }
      if (this.formData.numberOfRoutes) {
        form.append('outtrip[fields][outtripNumberOfRoutes]', this.formData.numberOfRoutes);
      }
      if (this.formData.arrive) {
        if (this.formData.arrive.text) {
          form.append('outtrip[fields][outtripArrival][address]', this.formData.arrive.text);
        }
        if (this.formData.arrive.lat) {
          form.append('outtrip[fields][outtripArrival][lat]', this.formData.arrive.lat);
        }
        if (this.formData.arrive.lng) {
          form.append('outtrip[fields][outtripArrival][lng]', this.formData.arrive.lng);
        }
        if (this.formData.arrive.street) {
          form.append('outtrip[fields][outtripArrival][parts][address]', this.formData.arrive.street);
        }
        if (this.formData.arrive.town) {
          form.append('outtrip[fields][outtripArrival][parts][city]', this.formData.arrive.town);
        }
        if (this.formData.arrive.country) {
          form.append('outtrip[fields][outtripArrival][parts][country]', this.formData.arrive.country);
        }
        if (this.formData.arrive.county) {
          form.append('outtrip[fields][outtripArrival][parts][county]', this.formData.arrive.county);
        }
        if (this.formData.arrive.postcode) {
          form.append('outtrip[fields][outtripArrival][parts][postcode]', this.formData.arrive.postcode);
        }
        if (this.formData.arrive.state) {
          form.append('outtrip[fields][outtripArrival][parts][state]', this.formData.arrive.state);
        }
      }
      if (this.formData.break) {
        form.append('outtrip[fields][outtripBreakType]', this.formData.break);
      }
      if (this.formData.commentaire) {
        form.append('outtrip[fields][outtripComments]', this.formData.commentaire);
      }
      if (this.formData.contactNordique) {
        form.append('outtrip[fields][outtripContact]', this.formData.contactNordique);
      }

      if (this.formData.depart) {
        if (this.formData.depart.text) {
          form.append('outtrip[fields][outtripDeparture][address]', this.formData.depart.text);
        }
        if (this.formData.depart.lat) {
          form.append('outtrip[fields][outtripDeparture][lat]', this.formData.depart.lat);
        }
        if (this.formData.depart.lng) {
          form.append('outtrip[fields][outtripDeparture][lng]', this.formData.depart.lng);
        }
        if (this.formData.depart.street) {
          form.append('outtrip[fields][outtripDeparture][parts][address]', this.formData.depart.street);
        }
        if (this.formData.depart.town) {
          form.append('outtrip[fields][outtripDeparture][parts][city]', this.formData.depart.town);
        }
        if (this.formData.depart.country) {
          form.append('outtrip[fields][outtripDeparture][parts][country]', this.formData.depart.country);
        }
        if (this.formData.depart.county) {
          form.append('outtrip[fields][outtripDeparture][parts][county]', this.formData.depart.county);
        }
        if (this.formData.depart.postcode) {
          form.append('outtrip[fields][outtripDeparture][parts][postcode]', this.formData.depart.postcode);
        }
        if (this.formData.depart.state) {
          form.append('outtrip[fields][outtripDeparture][parts][state]', this.formData.depart.state);
        }
      }
      if (this.formData.description) {
        form.append('outtrip[fields][outtripDescription]', this.formData.description);
      }
      if (this.formData.distance) {
        form.append('outtrip[fields][outtripDistance]', this.formData.distance);
      }
      if (this.formData.duree) {
        form.append('outtrip[fields][outtripDuration]', this.formData.duree);
      }
      if (this.formData.sac) {
        form.append('outtrip[fields][outtripBagEquipment]', this.formData.sac);
      }
      if (this.formData.planche) {
        form.append('outtrip[fields][outtripBoardEquipment]', this.formData.planche);
      }
      if (this.formData.pisteNoires) {
        form.append('outtrip[fields][outtripNumberOfBlackTrack]', this.formData.pisteNoires);
      }
      if (this.formData.pisteBleues) {
        form.append('outtrip[fields][outtripNumberOfBlueTrack]', this.formData.pisteBleues);
      }
      if (this.formData.pisteVertes) {
        form.append('outtrip[fields][outtripNumberOfGreenTrack]', this.formData.pisteVertes);
      }
      if (this.formData.pisteRouges) {
        form.append('outtrip[fields][outtripNumberOfRedTrack]', this.formData.pisteRouges);
      }
      if (this.formData.forfaits) {
        form.append('outtrip[fields][outtripPass]', this.formData.forfaits);
      }
      if (this.formData.outtripPositiveElevation) {
        form.append('outtrip[fields][outtripPositiveElevation]', this.formData.outtripPositiveElevation);
      }
      if (this.formData.chaussures) {
        form.append('outtrip[fields][outtripShoesEquipment]', this.formData.chaussures);
      }
      if (this.formData.ski) {
        form.append('outtrip[fields][outtripSkiEquipment]', this.formData.ski);
      }
      if (this.formData.slope) {
        form.append('outtrip[fields][outtripSlopeLevel]', this.formData.slope);
      }
      if (this.formData.raquettes) {
        form.append('outtrip[fields][outtripSnowShoesEquipment]', this.formData.raquettes);
      }
      if (this.formData.baton) {
        form.append('outtrip[fields][outtripSticksEquipment]', this.formData.baton);
      }
      if (this.formData.parapente) {
        form.append('outtrip[fields][outtripParagliderEquipment]', this.formData.parapente);
      }
      if (this.formData.choixStation) {
        form.append('outtrip[fields][outtripWhyChooseThisStation]', this.formData.choixStation);
      }
      if (this.formData.website) {
        form.append('outtrip[fields][outtripWebsite]', this.formData.website);
      }
      if (this.formData.velo) {
        form.append('outtrip[fields][outtripBikeEquipment]', this.formData.velo);
      }
      if (this.formData.nutrition) {
        form.append('outtrip[fields][outtripNutritionEquipment]', this.formData.nutrition);
      }
      if (this.formData.animaux) {
        form.append('outtrip[fields][outtripAnimalsAccepted]', '1');
      }
      if (this.formData.free) {
        form.append('outtrip[fields][outtripFree]', '1');
      }
      if (this.formData.poussettes) {
        form.append('outtrip[fields][outtripAccessibleToStrollers]', '1');
      }
      if (this.formData.mobiliteReduite) {
        form.append('outtrip[fields][outtripAccessibleToPeopleWithReducedMobility]', '1');
      }
      if (this.formData.pointDeVue) {
        form.append('outtrip[fields][outtripPointOfView]', '1');
      }
      if (this.formData.bus) {
        form.append('outtrip[fields][outtripAccessByBus]', '1');
      }
      if (this.formData.train) {
        form.append('outtrip[fields][outtripAccessByTrain]', '1');
      }
      if (this.formData.voiture) {
        form.append('outtrip[fields][outtripAccessByCar]', '1');
      }
      if (this.formData.marche) {
        form.append('outtrip[fields][outtripWalkingAccess]', '1');
      }

      for (let i = 0; i < this.formData.photos.length; i++) {
        form.append('outtrip[fields][outtripGallery][]', this.formData.photos[i]);
      }

      for (let i = 0; i < this.formData.gpxTrack.length; i++) {
        form.append('outtrip[fields][gpxTrack][]', this.formData.gpxTrack[i]);
      }

      return form;
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn {
  margin: 7px 0;
}

.HelpToKnowWhatWrite {
  color: #48bfaf;
  text-decoration: underline;
  font-size: 0.7rem;
}
</style>
