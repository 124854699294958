import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://cms.outtrip.fr/api',
    headers: {
      "Content-Type": "application/json",
    },
});

export default {
    install: function(Vue) {
        Object.defineProperty(Vue.prototype, '$cms', { value: instance });
    }
}
