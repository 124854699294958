<template>
  <v-container fluid>
    <h1>Verifier mon compte</h1>
    <p>Afin de verifier ton compte, ajoute une photo de profil et envoie nous ta carte d'identité (une fois ton identité confirmée, ta carte d'identité sera supprimé de nos serveur)</p>

    <h2>Photos de profile</h2>
    <UserPictureProfil :user="user"/>

    <h2>Carte d'identité</h2>
    <GalleryWidget label-idle="Recto + Verso (max 2 fichier < 10Mo)" :folder-id="8" />
    <p><small>Une fois ton identité confirmée, ta carte d'identité sera supprimée de nos serveurs</small></p>

    <v-btn type="button" @click="send" color="primary" rounded :loading="loading" :disabled="loading">Envoyé</v-btn>
    <v-snackbar v-model="snackbar" absolute bottom left color="success">Ta demande est en cours de verification.</v-snackbar>
  </v-container>
</template>

<script>

import UserPictureProfil from "../components/Form/UserPictureProfil";
import GalleryWidget from "../components/Form/GalleryWidget";
import {mapState} from "vuex";

export default {
  components: {UserPictureProfil, GalleryWidget},
  name: "UserVerificationRequest",
  data: () => ({
    loading: false,
    snackbar: false,
  }),
  computed: {
    ...mapState("auth", ['user'])
  },
  methods: {
    send() {
      this.$axios.post('/user/indentityVerificationRequest')
      .then(() => {
        this.snackbar = true;
      })
    }
  }
}
</script>
