<template>
  <v-container fluid>
    <AdBanner/>
    <h1>Mes favoris</h1>
    <v-tabs v-model="tab" align-with-title>
      <v-tab @change="loadActivites">Activités</v-tab>
      <v-tab @change="loadEvents">&Eacute;vènements</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat>
          <div>
            <v-skeleton-loader type="list-item-avatar-two-line@4" v-if="outtrips === null"></v-skeleton-loader>
            <v-list v-else-if="outtrips.length === 0">
              <v-alert type="info" outlined>
                Vous n'avez pas de favoris
              </v-alert>
            </v-list>
            <OuttripList :outtrips="outtrips" v-else/>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <h3>Participe à</h3>
          <v-skeleton-loader type="list-item-avatar-two-line@2" v-if="participed === null"></v-skeleton-loader>
          <EventList v-else-if="participed.length > 0" :events="participed"/>
          <div v-else>
            <v-alert type="info" outlined>
              Tu ne participes à aucun évènement pour le moment.
            </v-alert>
          </div>
          <h3>Intéressé par</h3>
          <v-skeleton-loader type="list-item-avatar-two-line@2" v-if="interested === null"></v-skeleton-loader>
          <EventList v-else-if="interested.length > 0" :events="interested"/>
          <div v-else>
            <v-alert type="info" outlined>
              Tu t'intéresses à aucun évènement pour le moment.
            </v-alert>
          </div>

        </v-card>
      </v-tab-item>

    </v-tabs-items>

    <LoginModal ref="loginModal" @success="closeLoginModal" style="z-index: 1005"/>
  </v-container>
</template>

<script>
import OuttripList from "@/components/Outtrip/OuttripList";
import EventList from "@/components/Event/EventList";
import AdBanner from "@/views/AdBanner";
import LoginModal from "@/components/LoginModal";
import {mapState} from "vuex";

export default {
  components: {EventList, OuttripList, AdBanner, LoginModal},
  data: () => ({
    tab: null,
    outtrips: null,
    interested: null,
    participed: null
  }),
  computed: {
    ...mapState("auth", ['user'])
  },
  mounted() {
    if (!this.user) {
      this.openLoginModal();
    } else {
      this.loadActivites();
    }

  },
  methods: {
    redirect(route) {
      this.$router.push({name: 'Outtrip', params: {slug: route}})
    },
    loadActivites() {
      this.$axios.get('/outtrips/favs/list').then(response => {
        this.outtrips = response.data
      })
    },
    openLoginModal() {
      this.$refs.loginModal.openLoginModal()
    },
    closeLoginModal() {
      this.loadActivites();
      this.$refs.loginModal.closeLoginModal()
    },
    loadEvents() {
      this.$axios.get('/event/my-interested/list').then(response => {
        this.interested = [];
        response.data.map(r=> this.interested.push(r.event[0]))
      })

      this.$axios.get('/event/my-participed/list').then(response => {
        this.participed = [];
        response.data.map((r)=> {
          if(r.event[0]) {
            this.participed.push(r.event[0])
          }
        })
      })
    }
  }
}
</script>
