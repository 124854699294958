import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.VUE_APP_NEW_API_URL,
    headers: {
      "Content-Type": "application/json",
    },
});

export default {
    install: function(Vue) {
        Object.defineProperty(Vue.prototype, '$api', { value: instance });
    }
}
