<template>
  <v-container>
    <AdBanner/>
    <div v-if="null === event" class="text-center">
      <v-skeleton-loader type="heading"></v-skeleton-loader>
    </div>
    <div v-else style="margin-bottom: 94px">
      <h1 v-html="event.title"></h1>
      <CarouselPhotoGallery :photos="event.eventGallery"/>
      <v-card color="primary" class="ma-3 pa-2 white--text">
        <ul class="listUserInfoInline text-center">
          <li>
            <v-icon color="white">mdi-map-marker-outline</v-icon>
            <span v-html="event.location.parts.city"></span> (<span v-html="event.location.parts.postcode"></span>)
          </li>
          <li>
            <v-icon color="white">mdi-calendar</v-icon>
            <span v-html="event.dateFormatted"></span></li>
        </ul>

        <ul class="listInline text-center mt-2">
          <li v-for="sport in event.sports" :key="sport.slug">
            <v-icon color="white" small>{{ sport.materialIcon }}</v-icon>
            {{ sport.title }}
          </li>
        </ul>
      </v-card>
      <div>
        <v-tabs v-model="tab" centered>
          <v-tab>L'évènement</v-tab>
          <v-tab @change="loadParticipants">Participants</v-tab>
          <v-tab @change="loadIntersted">Intéressés</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="tabs">
          <v-tab-item v-html="event.description"></v-tab-item>
          <v-tab-item>
            <v-skeleton-loader
                type="list-item-avatar-two-line@3"
                v-if="participants === null"
            ></v-skeleton-loader>
            <div v-else-if="participants.length <= 0">
              <v-alert type="info" outlined>
                Aucun participant inscrit pour le moment. Sois le premier !
              </v-alert>
              <v-btn class="mx-auto d-block" color="primary" @click="participated" rounded :loading="loadingButton">Participer !</v-btn>
            </div>
            <OuttriperList v-else name="participant" :users="participants" @click="redirectToUser"/>
          </v-tab-item>
          <v-tab-item>
            <v-skeleton-loader
                type="list-item-avatar-two-line@3"
                v-if="interestedList === null"
            ></v-skeleton-loader>
            <div v-else-if="interestedList.length <= 0">
              <v-alert type="info" outlined>
                Aucun intéressé pour le moment. Sois le premier !
              </v-alert>
              <v-btn class="mx-auto d-block" color="primary" @click="interesting" rounded :loading="loadingButton">Participer !</v-btn>
            </div>
            <OuttriperList v-else name="interested" :users="interestedList" @click="redirectToUser"/>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <h2>Organisé par :</h2>
      <Author :entry="event.author"/>
      <div class="d-flex justify-center my-6" style="z-index: 1000; position: fixed; bottom: 56px; margin-left:1rem;">
        <v-speed-dial v-model="fab" direction="right" style="z-index: 1000;">
          <template v-slot:activator>
            <v-btn v-model="fab" color="primary" fab>
              <v-icon v-if="fab">
                mdi-close
              </v-icon>
              <v-icon v-else>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <v-tooltip bottom v-if="!stateEvent">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" small fab v-bind="attrs" v-on="on" @click.stop="participated" :loading="loadingButton">
                <v-icon color="white">mdi-account-plus</v-icon>
              </v-btn>
            </template>
            <span>Participer</span>
          </v-tooltip>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" small fab v-bind="attrs" v-on="on" @click.stop="dialog = true" :loading="loadingButton">
                <v-icon color="white">mdi-account-check</v-icon>
              </v-btn>
            </template>
            <span>Ne plus participer</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" small fab v-bind="attrs" v-on="on" @click.stop="interesting" :loading="loadingButton">
                <v-icon color="white" v-if="!interet">mdi-star-outline</v-icon>
                <v-icon color="white" v-else>mdi-star</v-icon>
              </v-btn>
            </template>
            <span v-if="!interet">Intéressé</span>
            <span v-else>Plus intéressé</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="secondary" small fab v-bind="attrs" v-on="on" :loading="loadingButton" @click.stop="goToMessagerie">
                <v-icon color="white">mdi-chat-processing</v-icon>
              </v-btn>
            </template>
            <span>Messagerie</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="warning" small fab v-bind="attrs" v-on="on" @click.stop="dialogSignalement = true" :loading="loadingButton">
                <v-icon color="white">mdi-alert-circle</v-icon>
              </v-btn>
            </template>
            <span>Signaler l'activité</span>
          </v-tooltip>
        </v-speed-dial>
      </div>

      <LoginModal ref="loginModal" @success="closeLoginModal"/>
      <NotPermitModal ref="notPermitModal" />
      <v-dialog v-model="dialog" max-width="500">
        <v-card>
          <v-card-title class="text-h5">
            Ne plus participer ?
          </v-card-title>

          <v-card-text>
            Etes vous vraiment sur de vouloir ne plus participer à l'activité ?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" text @click="dialog = false">
              Annuler
            </v-btn>

            <v-btn color="error" @click="participated">
              <v-icon>mdi-delete</v-icon>
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-dialog v-model="dialogSignalement" max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          Signalement de l'activité
        </v-card-title>

        <div class="px-6">
          <p>Décrivez votre problème avec cette activité</p>
          <v-textarea outlined rounded v-model="message"/>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialogSignalement = false">
            Annuler
          </v-btn>

          <v-btn color="green darken-1" text @click="saveReport(event.id)">
            Envoyer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbarSuccess" timeout="2000" color="success" left style="z-index: 1001">
      {{ snackbarText }}
    </v-snackbar>
    <v-snackbar v-model="snackbarError" timeout="2000" color="error" style="z-index: 1001" left>
      {{ snackbarText }}
    </v-snackbar>

    <v-dialog v-model="autoCheck" max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          En cours de modération
        </v-card-title>

        <v-card-text>
          Ta demande de participation à bien été envoyé, elle est en cours de modération
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="autoCheck = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AdBanner from "@/views/AdBanner";
import Author from "@/components/Author";
import CarouselPhotoGallery from "@/components/CarouselPhotoGallery";
import LoginModal from "@/components/LoginModal";
import OuttriperList from "@/components/Outtriper/OuttriperList";
import NotPermitModal from "@/components/Event/NotPermitModal";
import {mapState} from "vuex";

export default {
  name: "Event",
  components: {NotPermitModal, OuttriperList, LoginModal, Author, CarouselPhotoGallery, AdBanner},
  computed: {
    ...mapState("auth", ['user'])
  },
  data: () => ({
    tab: null,
    isLoading: false,
    event: null,
    fab: true,
    snackbarSuccess: false,
    snackbarError: false,
    snackbarText: null,
    stateEvent: null,
    dialog: false,
    participants: null,
    interet: null,
    interestedList: null,
    autoCheck: null,
    dialogSignalement: false,
    message: null,
    loadingButton: false
  }),
  mounted() {
    const slug = this.$route.params.slug

    this.isLoading = true;
    this.loadingButton = true
    this.$axios.get(`/event/${slug}`).then(response => response.data)
        .then(response => {
          this.event = response.entry
          this.isLoading = false;
          this.getStateEvent()
          this.getStateInteret()
        })
  },
  methods: {
    redirectToUser(user) {
      this.$router.push({name: "Outtrippeur", params: {user: user}})
    },
    openLoginModal() {
      this.$refs.loginModal.openLoginModal()
    },
    closeLoginModal() {
      this.$refs.loginModal.closeLoginModal()
    },
    getStateEvent() {
      if (this.user)
        this.$axios.post(`/event/${this.event.id}/participed/state`).then(response => {
          this.stateEvent = response.data
          this.loadingButton = false
        })
      else {
        this.stateEvent = false
        this.loadingButton = false
      }
    },
    getStateInteret() {
      if (this.user)
        this.$axios.post(`/event/${this.event.id}/interested/state`).then(response => {
          this.interet = response.data
          this.loadingButton = false
        })
      else {
        this.loadingButton = false
        this.interet = false
      }
    },
    getListParticipant() {
      this.$axios.get(`/event/${this.event.id}/participed/list/accepted`).then(response => {
        this.participants = [];
        response.data.map(r => this.participants.push(r.user))
      })
    },
    getListInteret() {
      this.$axios.post(`/event/${this.event.id}/interested/list`).then(response => {
        this.interestedList = [];
        response.data.map(r => this.interestedList.push(r.user))
      })
    },
    loadParticipants() {
      if (this.participants === null) {
        this.getListParticipant()
      }
    },
    loadIntersted() {
      if (this.interestedList === null) {
        this.getListInteret()
      }
    },
    participated() {
      this.loadingButton = true
      if (this.user) {
        if (this.event)
          this.$axios.post(`/event/${this.event.id}/participed`).then(response => {
            this.fab = true
            this.snackbarSuccess = true
            this.snackbarText = response.data.message
            this.getStateEvent()
            this.getListParticipant()
            this.$axios.get(`/event/${this.event.id}/participed/check_auto`).then(response => {
              if (response.data === false) {
                this.autoCheck = true
              }
            })
          }).catch(error => {
            this.snackbarError = true
            this.snackbarText = error.message
            this.dialog = false
            this.getStateEvent()
            this.getListParticipant()
          })
      } else {
        this.openLoginModal()
      }
    },
    interesting() {
      this.loadingButton = true
      if (this.user) {
        this.$axios.post(`/event/${this.event.id}/interested`).then(response => {
          this.fab = true
          this.snackbarSuccess = true
          this.snackbarText = response.data.message
          this.getStateInteret()
          this.getListInteret()
        }).catch(error => {
          this.snackbarError = true
          this.snackbarText = error.message
          this.getStateInteret()
          this.getListInteret()
        })
      } else {
        this.openLoginModal()
      }
    },
    saveReport(subjectId) {
      this.$axios.post('/reporting/save', {
        "subjectId": parseInt(subjectId),
        "subject": "event",
        "message": this.message
      }).then(response => {
        this.dialogSignalement = false
        this.snackbarSuccess = true
        this.snackbarText = response.data.message
      }).catch(error => {
        this.snackbarError = true
        this.snackbarText = error.data
      })
    },
    goToMessagerie(){
      this.loadingButton = true;
      this.$axios.get(`/messaging/ask-authorization`, {
        params: {
          q: 'group/'+this.event.id,
        }
      })
          .then(response => {
            const data = response.data;
            if (!data.authorized) {
              if (data.reason === 'not_login') {
                this.$refs.loginModal.open()
                return;
              } else if (data.reason === 'not_permit') {
                this.$refs.notPermitModal.open()
                return;
              }
            }
            this.$router.push({name: 'MessagingChat', query: {q: 'group/'+this.event.rocketchatChannel+'?layout=embedded'}})
          })
          .then(() => this.loadingButton = false)
    },
  }
}
</script>

<style scoped>

</style>
