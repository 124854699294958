import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import compositionApi from './plugins/composition-api'
import vuetifyDateTimePicker from './plugins/vuetify-datetime-picker'
import axios from './plugins/axios'
import api from './plugins/axios-new-api'
import cms from './plugins/axios-cms'


import router from './router'

import 'leaflet/dist/leaflet.css'
import './registerServiceWorker'
import './plugins/cloudinary.js'
import './plugins/vuex.js'
import 'dayjs/locale/fr'
import store from './store'
import dayjs from "dayjs";
import VueMeta from 'vue-meta'

import firebaseMessaging from './plugins/firebase'
Vue.prototype.$messaging = firebaseMessaging

Vue.use(axios)
Vue.use(api)
Vue.use(cms)

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

dayjs.locale('fr');

Vue.config.productionTip = false

import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

new Vue({
  vuetify,
  compositionApi,
  vuetifyDateTimePicker,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
