<template>
  <v-container fill-height fluid pa-0>
    <div :style="`z-index: 1000; position: ${homeSearchBarPosition}; top: 0; left:0; width:100%`"
         class="d-flex flex-column align-center justify-center my-6 px-5">
      <HomeSearchBar :filters="filters" @change="refreshMarkers" @typeChanged="onTypeChanged"/>
      <v-btn style="margin-left: 40px; margin-top: 5px" rounded v-if="lastBoundsFetched && !lastBoundsFetched.contains(currentMapBounds) && zoom >= 10" @click="fetchMarkersInZone()" color="primary">Rechercher dans cette zone</v-btn>
    </div>
    <div v-if="filters.type !== 'community'" style="width: 100%; height: 100%">
      <l-map ref="map" :center="[
            center.lat || userLocation.lat || defaultLocation.lat,
            center.lng || userLocation.lng || defaultLocation.lng
          ]" :options="mapOptions" :zoom="zoom"
             @locationfound="onMapLocationFound"
             @move="onMapMove"
             @moveend="onMapMovedEnd"
             @ready="onMapReady"
             @click.native="closeCurrentMarker"
             @update:zoom="zoomUpdated"
      >
        <l-tile-layer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"></l-tile-layer>
        <l-marker v-if="north" :lat-lng="north"></l-marker>
        <l-marker v-if="south" :lat-lng="south"></l-marker>
        <v-marker-cluster>
          <div v-for="refuge in refuges" :key="refuge.id">
            <l-marker v-if="filters.type === 'tracks'" :lat-lng="[refuge.lat, refuge.lng]"
                      @click="fetchPointOfInteretMarker(refuge)">
              <l-icon>
                <MapMarker :color-marker="colorMarker(refuge.type)">
                  <v-icon>{{ iconMarker(refuge.type) }}</v-icon>
                </MapMarker>
              </l-icon>
            </l-marker>
          </div>
          <div v-for="marker in markers" :key="marker.id">
            <l-marker v-if="filters.type === 'tracks'" :lat-lng="[marker.departureLat, marker.departureLng]"
                      @click="fetchOuttripMarker(marker)">
              <l-icon>
                <OuttripMarker :outtrip="marker"/>
              </l-icon>
            </l-marker>
            <l-marker v-if="filters.type === 'events'" :lat-lng="[marker.lat, marker.lng]"
                      @click="fetchEventMarker(marker)">
              <l-icon>
                <EventMarker :event="marker"/>
              </l-icon>
            </l-marker>
          </div>
        </v-marker-cluster>
      </l-map>
      <div style="position: absolute; z-index:1002; bottom: 0; left: 0; width:100%">
        <OuttripCard v-if="currentMarker !== null && filters.type === 'tracks' && currentMarkerType === 'outtrip'" :outtrip="currentMarker"
                     style="width: 90%; margin: auto; margin-bottom: 1rem;"
                     @clickOnClose="closeCurrentMarker"/>
        <EventCard v-if="currentMarker !== null && filters.type === 'events' && currentMarkerType === 'event'" :event="currentMarker"
                   style="width: 90%; margin: auto; margin-bottom: 1rem;"
                   @clickOnClose="closeCurrentMarker"/>
        <PointOfInterestCard v-if="entry !== null" :refuge="entry"
                    style="width: 90%; margin: auto; margin-bottom: 1rem;"
                    @clickOnClose="closeCurrentMarker"/>
      </div>
      <v-btn fab my-6 secondary small style="z-index: 1001; position: absolute; bottom: 32px; right: 20px;"
             @click="flyToUser">
        <v-icon>
          mdi-target-account
        </v-icon>
      </v-btn>
    </div>
    <OuttriperList v-else ref="outtripperList" :users="outtriperList" name="users" style="width: 100%; height: 100%"
                   @click="redirectToUser"></OuttriperList>

    <v-snackbar v-model="snackbar" style="z-index: 1001" timeout="5000">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { LMap, LTileLayer, LIcon, LMarker} from 'vue2-leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import OuttripMarker from '../components/Outtrip/OuttripMarker';
import OuttriperList from '../components/Outtriper/OuttriperList';
import EventMarker from '../components/Event/EventMarker';
import OuttripCard from '../components/Outtrip/OuttripCard';
import EventCard from '../components/Event/EventCard';
import HomeSearchBar from '../components/Home/HomeSearchBar';
import {mapState} from "vuex";
import MapMarker from "@/components/MapMarker";
import PointOfInterestCard from "@/components/PointOfInterestCard";
import PointOfInterestGql from "@/gql/point-of-interest.gql"

export default {
  components: {
    MapMarker,
    LMap,
    LTileLayer,
    LIcon,
    LMarker,
    OuttripMarker,
    OuttriperList,
    EventMarker,
    OuttripCard,
    HomeSearchBar,
    PointOfInterestCard,
    EventCard,
    'v-marker-cluster': Vue2LeafletMarkerCluster
  },
  name: 'Home',
  data: () => ({
    snackbar: false,
    snackbarText: null,
    typeChoice: 'tracks',
    fab: false,
    skipEntry: true,
    defaultLocation: {
      lat: 45.8992,
      lng: 6.1294
    },
    userLocation: {},
    mapOptions: {
      zoomControl: true,
    },
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    currentMapBounds: null,
    markers: [],
    pointOfIntersetClicked: null,
    currentMarker: null,
    currentMarkerType: null,
    outtriperList: [],
    outtriperListOffset: 0,
    homeSearchBarPosition: 'absolute',
    filters: {
      sports: [],
      group: [],
      place: null,
      type: 'tracks'
    },
    refuges: [],
    lastBoundsFetched: null,
    isMapMoving: false,
    entry: null,
    north: null,
    south: null,
    shouldLoadData: true,
  }),
  mounted() {
    this.userLocation = this.$store.getters['userLocation'];

    this.filters.sports = this.$store.getters['filter/sports'];
    this.filters.place = this.$store.getters['filter/place'];
    this.filters.type = this.$store.getters['filter/type'];
  },
  methods: {
    fetchPointsOfInterest(bounds) {
      if (this.isMapMoving === true || this.lastBoundsFetched === bounds) {
        return;
      }
      this.lastBoundsFetched = bounds;

      this.$api.get('point_of_interests', {
        params: {marker: true, pagination: false, isActive: 1, inBounds: bounds.toBBoxString(), type: this.filters.pointOfInterestType},
        headers: {
          'Accept': 'application/json'
        }
      })
          .then(response => {
            this.refuges = response.data;
          })
          .catch(() => {
            this.snackbar = true;
            this.snackbarText = 'Une erreur est survenue lors de la récupération des refuges';
          });
    },
    redirectToUser(user) {
      this.$router.push({name: "Outtrippeur", params: {user: user.username}})
    },
    closeCurrentMarker: function () {
      this.currentMarker = null;
      this.pointOfIntersetClicked = null;
      this.entry = null;
    },
    onClearSearchBar: function () {
      this.typeChoice = 'tracks';
    },
    onMapReady: function (mapObject) {
      mapObject.locate();
      this.currentMapBounds = this.$refs.map.mapObject.getBounds();
      this.fetchMarkers()
    },
    onMapLocationFound: function (location) {
      this.userLocation = location.latlng;
      this.currentMapBounds = this.$refs.map.mapObject.getBounds();
      this.$store.commit('userLocation', this.userLocation);
      this.shouldLoadData = true;
    },
    onMapMove: function () {
      this.closeCurrentMarker();
    },
    onMapMovedEnd: function (event) {
      this.currentMapBounds = event.target.getBounds()
      this.$store.commit('map/center', event.target.getCenter())
      this.$store.commit('map/zoom', event.target.getZoom())
      if (this.shouldLoadData) {
        this.fetchMarkers();
      }
    },
    zoomUpdated: function (zoom) {
      this.$store.commit('map/zoom', zoom)
    },
    onTypeChanged: function (value) {
      this.filters.type = value;
      this.shouldLoadData = true
      this.fetchMarkers();
    },
    refreshMarkers: function (value) {
      this.filters = value.filters;
      this.outtriperList = [];
      this.shouldLoadData = true

      if (value.placeHasChanged && this.filters.place && this.filters.type !== 'community') {
        this.refreshPlace(this.filters.place);
      }

      this.fetchMarkers();
    },
    fetchPointOfInteretMarker: function (marker) {
      this.pointOfIntersetClicked = marker;
      this.currentMarkerType = 'point-of-interest';
      this.skipEntry = false;

      this.$cms.post('', {
        query: PointOfInterestGql.loc.source.body,
        variables: {
          id: marker.frontendId
        }
      }).then((response) => {
        this.entry = response.data.data.entry;
      })
    },
    fetchMarkersInZone: function () {
      this.shouldLoadData = true;
      this.fetchMarkers();
    },
    fetchMarkers: function () {
      this.markers = [];
      switch (this.filters.type) {
        case 'tracks':
          this.fetchOuttripMarkers();
          this.outtriperList = [];
          this.outtriperListOffset = 0;
          this.homeSearchBarPosition = 'absolute';
          break;
        case 'events':
          this.fetchEventMarkers();
          this.outtriperList = [];
          this.outtriperListOffset = 0;
          this.homeSearchBarPosition = 'absolute';
          break;
        case 'community':
          this.fetchOuttriperList();
          this.outtriperListOffset = 0;
          addEventListener('scroll', () => {
            const boudingClientRect = (this.$refs.outtripperList.$el.getBoundingClientRect());

            if (boudingClientRect.bottom - 300 < window.innerHeight && !this.isLoading) {
              this.outtriperListOffset += 20;
              this.fetchOuttriperList();
            }

          });
          break;
      }

      this.fetchPointsOfInterest(this.currentMapBounds);

      this.lastBoundsFetched = this.currentMapBounds;
      this.shouldLoadData = false
    },
    fetchOuttripMarkers: function () {
      this.$api.get("outtrips", {
        params: {
          marker: 1,
          pagination: false,
          inBounds: this.currentMapBounds.toBBoxString(),
          sport: this.filters.sports,
          group: this.filters.group,
          status: 'live'
        },
        headers: {
          'Accept': 'application/json'
        }
      })
          .then((response) => {
            this.markers = response.data;
          })
    },
    fetchEventMarkers: function () {
      this.$axios.get("/events", {
        params: {
          showPassed: 0,
          sports: this.filters.sports,
        },
      })
          .then((response) => {
            this.markers = response.data;
            if (this.markers.length <= 0) {
              this.snackbarText = 'Aucun évènement trouvé.';
              this.snackbar = true;
            }
          })
    },
    fetchOuttripMarker: function (marker) {
      this.currentMarker = null;

      this.$axios.get(`/outtrip/${marker.id}/card-details`)
          .then((response) => {
            this.currentMarker = response.data
            this.currentMarkerType = 'outtrip';
          })
    },
    fetchEventMarker: function (marker) {
      this.currentMarker = null;

      this.$axios.get(`/event/${marker.id}/event-details`)
          .then((response) => {
            this.currentMarker = response.data
            this.currentMarkerType = 'event';
          })
    },
    fetchOuttriperList: function () {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      this.$axios.get("/outtrippers", {
        params: {
          sports: this.filters.sports,
          location: this.filters.place?.text,
          offset: this.outtriperListOffset,
          limit: 20
        },
      })
          .then((response) => {
            this.outtriperList = this.outtriperList.concat(response.data);
            this.homeSearchBarPosition = 'relative';
            this.isLoading = false;
          })
    },
    flyToUser: function () {
      this.$refs.map.mapObject.locate();
      this.$refs.map.mapObject.flyTo(this.userLocation);
      this.shouldLoadData = true;
    },
    refreshPlace(place) {
      this.shouldLoadData = true;
      this.$refs.map.mapObject.flyTo({
        lat: place.lat,
        lng: place.lng,
      })
    },
    colorMarker(type) {
      switch (type) {
        case 'passage délicat':
          return 'red';
        case 'point d\'eau':
        case 'lac':
          return 'blue';
        case 'cabane non gardée':
        case 'refuge gardé':
        case 'cabane':
        case 'gîte d\'étape':
          return 'brown';
        case 'sommet':
          return 'orange';
        default:
          return 'brown';
      }
    },
    iconMarker(type) {
      switch (type) {
        case 'passage délicat':
          return 'mdi-road-variant';
        case 'point d\'eau':
          return 'mdi-water'
        case 'lac':
          return 'mdi-waves';
        case 'cabane non gardée':
        case 'refuge gardé':
        case 'cabane':
        case 'gîte d\'étape':
          return 'mdi-home-account';
        case 'sommet':
          return 'mdi-image-filter-hdr';
        default:
          return 'mdi-home-account';
      }
    }
  },
  computed: {
    ...mapState("map", ['center', 'zoom'])
  }
}
</script>

<style scoped>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>
