<template>
  <MapMarker>
    <v-icon v-html="event.materialIcon"></v-icon>
  </MapMarker>
</template>

<script>
import MapMarker from "@/components/MapMarker";
export default {
  components: {MapMarker},
  props: {
    event: {
      required: true
    }
  }
}
</script>
