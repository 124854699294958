<template>
  <v-container>
    <div v-if="loading" class="text-center">
      <v-skeleton-loader class="mx-6" type="image"></v-skeleton-loader>
      <br>
      <v-skeleton-loader class="mx-6" type="article"></v-skeleton-loader>
    </div>
    <div v-else style="margin-bottom: 94px">
      <h1>{{ entry.title }}</h1>
      <GroupSection :group="entry.group[0]" :source-url="entry.sourceUrl" v-if="entry.group.length > 0"></GroupSection>

      <div style="width: 100%; height: 10rem">
        <l-map ref="map" :center="[
            entry.location.lat,
            entry.location.lng
          ]" :options="{zoomControl: false}" :zoom="13">
          <l-tile-layer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"></l-tile-layer>
          <l-marker :lat-lng="[entry.location.lat, entry.location.lng]">
            <l-icon>
              <MapMarker :color-marker="colorMarker(entry.pointOfInterestType)">
                <v-icon>{{ iconMarker(entry.pointOfInterestType) }}</v-icon>
              </MapMarker>
            </l-icon>
          </l-marker>
        </l-map>
      </div>

      <div>
        <v-tabs v-model="tab" centered>
          <v-tab>Description</v-tab>
          <v-tab>A proximité</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="tabs">
          <v-tab-item>
            <div v-html="entry.description"></div>
          </v-tab-item>
          <v-tab-item>
            <v-list>
              <v-subheader>LES ACTIVITÉS ({{entry.outtrips.length}})</v-subheader>
              <v-list three-line>
                <template v-for="(outtrip, index) in entry.outtrips">
                  <v-divider v-if="index !== 0" :key="index" inset/>
                  <v-list-item :key="outtrip.slug" :to="{name: 'Outtrip', params: {slug: outtrip.slug}}" link>
                    <v-list-item-avatar rounded>
                      <v-img v-if="outtrip.outtripGallery.length > 0" :src="outtrip.outtripGallery[0].url"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title class="text-wrap" style="cursor: pointer">
                        {{ outtrip.title }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <div class="spacing">
                <span class="icons no-wrap">
                 <v-icon color="primary">{{ outtrip.sport[0].materialIcon }}</v-icon>
                 <span>{{ outtrip.sport[0].title }}</span>
                </span>
                          <span v-if="outtrip.outtripDeparture.parts.city" class="icons no-wrap">
                 <v-icon color="primary">mdi-map-marker</v-icon>
                 <span>{{ outtrip.outtripDeparture.parts.city }}</span>
                </span>
                          <span v-if="outtrip.outtripDistance" class="icons no-wrap">
                 <v-icon color="primary">mdi-map-marker-distance</v-icon>
                 <span>{{ outtrip.outtripDistance }} km</span>
                </span>
                          <span v-if="outtrip.outtripAltitude" class="icons no-wrap">
                 <v-icon color="primary">mdi-image-filter-hdr</v-icon>
                 <span>{{ outtrip.outtripAltitude }}km</span>
                </span>
                          <span v-if="outtrip.outtripDuration" class="icons no-wrap">
                 <v-icon color="primary">mdi-clock-outline</v-icon>
                 <span>{{ outtrip.outtripDuration }}h</span>
                </span>
                          <span v-if="outtrip.outtripPositiveElevation" class="icons no-wrap">
                 <v-icon color="primary">mdi-arrow-top-right</v-icon>
                 <span>{{ outtrip.outtripPositiveElevation }}m</span>
                </span>
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
              <v-subheader>LES AUTRES POINTS D'INTÉRÊT ({{entry.pointsOfInterest.length}})</v-subheader>
              <v-list-item-group
                  color="primary"
              >
                <v-list-item
                    v-for="(item, i) in entry.pointsOfInterest"
                    :key="i"
                    :to="{ name: 'PointOfInterest', params: { id: item.id }}"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                    <v-list-item-subtitle>{{ item.outtripAltitude }}m {{ item.mountainMassif }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </v-container>
</template>

<script>
import {LMap, LTileLayer, LMarker, LIcon} from 'vue2-leaflet';
import MapMarker from "@/components/MapMarker";
import PointOfInterestGql from "@/gql/point-of-interest.gql"
import GroupSection from "@/components/GroupSection.vue";

export default {
  name: "PointOfInterest",
  components: {
    GroupSection,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    MapMarker
  },
  data: () => ({
    tab: null,
    entry: null,
    loading: true,
  }),
  mounted() {
    this.$cms.post('', {
      query: PointOfInterestGql.loc.source.body,
      variables: {
        id: this.$route.params.id
      }
    }).then((response) => {
      this.entry = response.data.data.entry;
      this.loading = false;
    })

  },
  methods: {
    colorMarker(type) {
      switch (type) {
        case 'passage délicat':
          return 'red';
        case 'point d\'eau':
        case 'lac':
          return 'blue';
        case 'cabane non gardée':
        case 'refuge gardé':
        case 'cabane':
        case 'gîte d\'étape':
          return 'brown';
        case 'sommet':
          return 'orange';
        default:
          return 'brown';
      }
    },
    iconMarker(type) {
      switch (type) {
        case 'passage délicat':
          return 'mdi-road-variant';
        case 'point d\'eau':
          return 'mdi-water'
        case 'lac':
          return 'mdi-waves';
        case 'cabane non gardée':
        case 'refuge gardé':
        case 'cabane':
        case 'gîte d\'étape':
          return 'mdi-home-account';
        case 'sommet':
          return 'mdi-image-filter-hdr';
        default:
          return 'mdi-home-account';
      }
    }

  }
}
</script>
