<template>
  <v-container>
    <AdBanner/>
    <div v-if="outtrip.length <= 0" class="text-center">
      <v-skeleton-loader class="mx-6" type="image"></v-skeleton-loader>
      <br>
      <v-skeleton-loader class="mx-6" type="card, article"></v-skeleton-loader>
    </div>
    <div v-else style="margin-bottom: 94px">
      <h1>{{ outtrip.entry.title }}</h1>
      <div v-if="outtrip.entry.author.isExternal && outtrip.entry.group.length > 0" class="primary--text text-center">par {{ outtrip.entry.author.username|externalUsername(outtrip.entry.group[0]) }}</div>
      <GroupSection :group="outtrip.entry.group[0]" :source-url="outtrip.entry.sourceUrl" v-if="outtrip.entry.group.length > 0"></GroupSection>

      <OuttripFeatures :entry="outtrip.entry"/>

      <CarouselPhotoGallery :photos="outtrip.entry.outtripGallery"/>

      <div>
        <v-tabs v-model="tab" centered>
          <v-tab>Itinéraire</v-tab>
          <v-tab>Conseils</v-tab>
          <v-tab>Communauté</v-tab>
          <v-tab>A proximité</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="tabs">
          <v-tab-item>
            <OuttripIconsItineraires :entry="outtrip.entry" class="my-5 px-6"/>

            <div v-if="outtrip.entry.outtripPass">
              <strong>Les forfaits :</strong>
              <div v-html="outtrip.entry.outtripPass"></div>
            </div>

            <div v-if="outtrip.entry.outtripWebsite" class="px-6 my-5">
              <v-icon color="primary">mdi-web</v-icon>
              <span><a :href="outtrip.entry.outtripWebsite" target="_blank">{{
                  outtrip.entry.outtripWebsite
                }}</a></span>
            </div>
            <div v-if="outtrip.entry.outtripWhyChooseThisStation" class="px-6 my-5">
              <strong>Pourquoi choisir cette station : </strong>
              <div v-html="outtrip.entry.outtripWhyChooseThisStation"></div>
            </div>

            <div v-if="outtrip.entry.outtripFree === false || outtrip.entry.outtripFree === true" class="px-6 my-5">
              <strong>Gratuit ? </strong>
              <span>{{ outtrip.entry.outtripFree ? 'Oui' : 'Non' }}</span>
            </div>

            <div v-if="outtrip.entry.outtripCotation" class="px-6 my-5">
              <strong>Cotation : </strong>
              <span>{{ outtrip.entry.outtripCotation.join(' / ') }}</span>
            </div>

            <div v-if="outtrip.entry.outtripAltitude" class="px-6 my-5">
              <strong>Altitude maximum : </strong>
              <span>{{ outtrip.entry.outtripAltitude }}m</span>
            </div>

            <div v-if="outtrip.entry.outtripAltitudeMinimum" class="px-6 my-5">
              <strong>Altitude minimum : </strong>
              <span>{{ outtrip.entry.outtripAltitudeMinimum }}m</span>
            </div>

            <div v-if="outtrip.entry.outtripNumberOfRoutes" class="px-6 my-5">
              <strong>Nombre de voie : </strong>
              <span>{{ outtrip.entry.outtripNumberOfRoutes }}</span>
            </div>

            <div v-if="outtrip.entry.outtripDifficulte" class="px-6 my-5">
              <strong>Difficulté : </strong>
              <div v-html="outtrip.entry.outtripDifficulte"></div>
            </div>

            <div v-if="outtrip.entry.outtripNombreDeEchelles" class="px-6 my-5">
              <strong>Nombre d'échelles : </strong>
              <span>{{ outtrip.entry.outtripNombreDeEchelles }}</span>
            </div>

            <div v-if="outtrip.entry.outtripNombreDePasserelles" class="px-6 my-5">
              <strong>Nombre de passerelles : </strong>
              <span>{{ outtrip.entry.outtripNombreDePasserelles }}</span>
            </div>

            <div v-if="outtrip.entry.outtripNombreDePontsDeSinge" class="px-6 my-5">
              <strong>Nombre de ponts de singe : </strong>
              <span>{{ outtrip.entry.outtripNombreDePontsDeSinge }}</span>
            </div>

            <div v-if="outtrip.entry.outtripNombreDeTyroliennes" class="px-6 my-5">
              <strong>Nombre de tyroliennes : </strong>
              <span>{{ outtrip.entry.outtripNombreDeTyroliennes }}</span>
            </div>

            <h3 v-if="outtrip.entry.gpxTrack && outtrip.entry.gpxTrack[0]" class="my-2 px-6">Carte</h3>
            <OuttripMapGPX v-if="outtrip.entry.gpxTrack && outtrip.entry.gpxTrack[0]" :entry="outtrip"
                           @needLogin="openLoginModal"/>

            <div v-if="outtrip.entry.outtripDescription">
              <h3 class="my-2 px-6">Description</h3>
              <ButtonMoreInfo :entry-text="outtrip.entry.outtripDescription" :length="700"/>
              <ButtonMoreInfo :entry-text="outtrip.entry.outtripComments" :length="700"/>
            </div>
            <OuttripAccess :entry="outtrip.entry" class="px-6 my-3"/>
          </v-tab-item>
          <v-tab-item>
            <div v-if="outtrip.entry.outtripAdditionalInformations">
              <h3 class="mt-3 px-6">Informations complémentaires</h3>

              <ButtonMoreInfo :entry-text="outtrip.entry.outtripAdditionalInformations" :length="700"/>
            </div>
              <div v-if="outtrip.entry.outtripMarkupAdditionnalNotes">
              <h3 class="mt-3 px-6">Balisage - Notes complémentaires</h3>

              <ButtonMoreInfo :entry-text="outtrip.entry.outtripMarkupAdditionnalNotes" :length="700"/>
            </div>

            <OuttripEquipement :entry="outtrip.entry" class="px-6 text-left"/>
            <h3 class="my-2 px-6">L'outtrippeur :</h3>
            <Author :entry="outtrip.entry.author" :show-contact-button="!(outtrip.entry.group.length > 0)"/>
          </v-tab-item>
          <v-tab-item>
            <div v-if="usersArround.length > 0">
              <h3>Communauté de la région</h3>
              <OuttriperList :users="usersArround" name="arround" @click="redirectToUser"/>
              <v-btn :to="{name: 'OuttrippeurArround', params: {slug: outtrip.entry.slug}}" class="float-right"
                     color="primary">
                Voir tous
              </v-btn>
            </div>
            <div v-else>
              <v-skeleton-loader class="mx-6" type="list-item-three-line"></v-skeleton-loader>
            </div>
            <div v-if="events.length > 0" class="mt-5">
              <h3>&Eacute;vènements dans la région</h3>
              <EventList :events="events"/>
            </div>
            <div v-else>
              <v-skeleton-loader class="mx-6" type="list-item-three-line"></v-skeleton-loader>
            </div>
          </v-tab-item>
          <v-tab-item>
            <OuttripList :outtrips="outtripProximity"/>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div class="d-flex justify-center my-6" style="z-index: 1000; position: fixed; bottom: 56px; margin-left:1rem;">
        <v-speed-dial v-model="fab" direction="right" style="z-index: 1000;">
          <template v-slot:activator>
            <v-btn v-model="fab" color="primary" fab>
              <v-icon v-if="fab">
                mdi-close
              </v-icon>
              <v-icon v-else>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :loading="loadingButton" :to="'/creer-un-evenement?outtripId='+outtrip.entry.id" color="primary"
                     fab link small
                     v-bind="attrs" v-on="on">
                <v-icon color="white">mdi-calendar</v-icon>
              </v-btn>
            </template>
            <span>Créer un évènement</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :loading="loadingButton" color="primary" fab small v-bind="attrs" @click="favSave" v-on="on">
                <v-icon v-if="!favState" color="white">mdi-star-outline</v-icon>
                <v-icon v-else color="white">mdi-star</v-icon>
              </v-btn>
            </template>
            <span v-if="!favState">Mettre en favoris</span>
            <span v-else>Supprimer des favoris</span>
          </v-tooltip>
          <!--          <v-tooltip bottom>-->
          <!--            <template v-slot:activator="{ on, attrs }">-->
          <!--              <v-btn color="secondary" small fab v-bind="attrs" v-on="on" :loading="loadingButton">-->
          <!--                <v-icon color="white">mdi-chat-processing</v-icon>-->
          <!--              </v-btn>-->
          <!--            </template>-->
          <!--            <span>Ajouter un commentaire</span>-->
          <!--          </v-tooltip>-->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :loading="loadingButton" color="warning" fab small v-bind="attrs" @click="dialogSignalement = true"
                     v-on="on">
                <v-icon color="white">mdi-alert-circle</v-icon>
              </v-btn>
            </template>
            <span>Signaler l'outtrip</span>
          </v-tooltip>
        </v-speed-dial>
      </div>

      <v-dialog v-model="dialogSignalement" max-width="500">
        <v-card>
          <v-card-title class="text-h5">
            Signalement de l'outtrip
          </v-card-title>
          <div class="px-6">
            <p>Décrivez votre problème ou erreur</p>
            <v-textarea v-model="message" outlined rounded/>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialogSignalement = false">
              Annuler
            </v-btn>
            <v-btn color="green darken-1" text @click="saveReport(outtrip.entry.id)">
              Envoyer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <LoginModal ref="loginModal" style="z-index: 1005" @success="closeLoginModal"/>
    </div>

    <v-snackbar v-model="snackbarSuccess" color="success" left style="z-index: 1001" timeout="2000">
      {{ snackbarText }}
    </v-snackbar>
    <v-snackbar v-model="snackbarError" color="error" left style="z-index: 1001" timeout="2000">
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import OuttripFeatures from "../components/OuttripFeatures";
import OuttripEquipement from "../components/OuttripEquipement";
import OuttripIconsItineraires from "../components/OuttripIconsItineraires";
import ButtonMoreInfo from "../components/ButtonMoreInfo";
import OuttripAccess from "../components/OuttripAccess";
import Author from "../components/Author";
import CarouselPhotoGallery from "@/components/CarouselPhotoGallery";
import LoginModal from "@/components/LoginModal";
import OuttripMapGPX from "@/components/Outtrip/OuttripMapGPX";
import OuttripList from "@/components/Outtrip/OuttripList";
import OuttriperList from "@/components/Outtriper/OuttriperList";
import EventList from "@/components/Event/EventList";
import AdBanner from "./AdBanner";
import {mapState} from "vuex";
import GroupSection from "@/components/GroupSection.vue";

export default {
  name: "Outtrip",
  components: {
    GroupSection,
    AdBanner,
    EventList,
    OuttriperList,
    OuttripList,
    OuttripMapGPX,
    LoginModal,
    CarouselPhotoGallery,
    Author,
    OuttripAccess,
    ButtonMoreInfo,
    OuttripIconsItineraires,
    OuttripEquipement,
    OuttripFeatures
  },
  computed: {
    ...mapState("auth", ['user'])
  },
  data: () => ({
    outtrip: [],
    tab: null,
    description: null,
    btnToggleDesc: false,
    fab: true,
    favState: null,
    snackbarSuccess: false,
    snackbarError: false,
    snackbarText: null,
    dialogSignalement: false,
    message: null,
    outtripProximity: null,
    usersArround: [],
    events: [],
    loadingButton: false
  }),
  mounted() {
    const slug = this.$route.params.slug
    this.loadingButton = true
    this.$axios.get(`/outtrip/${slug}`).then(response => response.data)
        .then(response => {
          this.outtrip = response

          this.getStateFav()
          this.getArround(slug)
        })
  },
  methods: {
    redirectToUser(user) {
      this.$router.push({name: "Outtrippeur", params: {user: user}})
    },
    openLoginModal() {
      this.$refs.loginModal.openLoginModal()
    },
    closeLoginModal() {
      this.$refs.loginModal.closeLoginModal()
    },
    getArround(slug) {
      this.$axios.get(`/outtrip_arround/${slug}`).then(response => response.data)
          .then(response => {
            this.outtripProximity = response
          })

      this.$axios.get(`/outtrippers_arround/${this.$route.params.slug}?limit=3`).then(response => response.data)
          .then(response => {
            response.map(r => this.usersArround.push(r.craftCmsData))
          })

      this.$axios.get(`/events-details-arround/${this.$route.params.slug}`).then(response => response.data)
          .then(response => this.events = response)
    },
    getStateFav() {
      if (this.user)
        this.$axios.post('/outtrips/favs/state', {
          "outtrip": this.outtrip.entry.id
        }).then(response => {
          this.favState = response.data
          this.loadingButton = false
        })
      else {
        this.favState = false
        this.loadingButton = false
      }
    },
    favSave() {
      if (this.user) {
        this.$axios.post('/outtrips/favs', {
          "outtrip": this.outtrip.entry.id
        }).then(response => {
          this.fab = true
          this.snackbarSuccess = true
          this.snackbarText = response.data.message
          this.getStateFav()
        }).catch(error => {
          this.snackbarError = true
          this.snackbarText = error.data
        })
      } else {
        this.openLoginModal()
      }
    },
    saveReport(subjectId) {
      this.$axios.post('/reporting/save', {
        "subjectId": parseInt(subjectId),
        "subject": "outtrip",
        "message": this.message
      }).then(response => {
        this.dialogSignalement = false
        this.snackbarSuccess = true
        this.snackbarText = response.data.message
      }).catch(error => {
        this.snackbarError = true
        this.snackbarText = error.data
      })
    }
  },
  filters: {
    externalUsername (value, group) {
      console.log(value.indexOf('_' + group.title), group);
      return value.substr(0, value.indexOf('_' + group.title))
    }
  },
}
</script>

<style scoped>

</style>
