import * as firebase from 'firebase/app'
import { getMessaging} from "firebase/messaging"

const firebaseConfig = {
    apiKey: "AIzaSyDZ4qaRpG_ZaTr3mwbLcJZFphwjEIgF4Ow",
    authDomain: "cine-api.firebaseapp.com",
    databaseURL: "https://cine-api-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "cine-api",
    storageBucket: "cine-api.appspot.com",
    messagingSenderId: "655649068063",
    appId: "1:655649068063:web:4a8341cc057fe57c5bf12a",
    measurementId: "G-H74JSRQ8MF"
}

firebase.initializeApp(firebaseConfig)

const messaging = getMessaging();
export default messaging
