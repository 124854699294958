<template>
  <v-btn fab small :style="styleMarker">
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "MapMarker",
  props: {
    colorMarker: {
      type: String,
      default: "#48bfaf"
    }
  },
  computed: {
    styleMarker() {
      return `border: 3px solid ${this.colorMarker}; top: -50%; left: -50%;`;
    }
  }
}


</script>

<style scoped>
/*.v-btn {
  border: 3px solid var(--v-primary-base);
  top: -50%;
  left: -50%;
}*/
</style>
