<template>
  <div :id="'kwanko-banner-'+uniqueId"></div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AdBanner",
  computed: {
    ...mapState("auth", ['user'])
  },
  data() {
    return {
      uniqueId: null
    }
  },
  mounted() {
    setTimeout(() => {
      this.uniqueId = Math.random().toString(16).slice(2);
      // eslint-disable-next-line
      new KwankoSDKLoader.getSlot("S4572B57925D1465", {
        'uid': 'kwanko-banner-'+this.uniqueId
      });
    }, 2000);
  }
}
</script>
