<template>
  <div>
    <div v-if="null === outtriper">
      <v-avatar :color="color" :class="`${textColor}--text`" :size="diameter">
        <span style="font-size: 100%; font-weight:bold;">O.</span>
      </v-avatar>
    </div>
    <div v-else>
      <div v-if="outtriper.photo && outtriper.photo.url" :style="{'min-width': diameter, 'min-height': diameter}">
        <cld-image :public-id="outtriper.photo.url" secure="true" :style="imgBorderColor" type="fetch">
          <cld-transformation :height="diameter" :width="diameter" gravity="auto:subject" crop="fill" quality="auto:eco" radius="max"/>
        </cld-image>
      </div>
      <v-avatar :color="color" :class="`${textColor}--text`" :size="diameter" v-else>
        <span style="font-size: 100%; font-weight:bold;">{{ outtriper.username | firstLetter }}</span>
      </v-avatar>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    imgBorderColor() {
      let color = "#48bfaf"
      if (this.outtriper.verifiedProfile) {
        color = "#FFCC33"
      } else if (this.outtriper.isPremium) {
        color = "#171923"
      }

      return {"border-color": color, "border-radius": "50%", "border-style": "solid", "border-width": "3px"};
    }
  },
  props: {
    outtriper: {
      required: true
    },
    diameter: {
      default: 128,
      type: Number
    },
    color: {
      default: 'primary'
    },
    textColor: {
      default: 'white'
    }
  },
  filters: {
    firstLetter(name) {
      return name.charAt(0).toUpperCase()
    }
  }
}
</script>

<style scoped>
img {
  display: block;
  margin: auto;
}
</style>
